import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import api from '../../services/api';

import { Container } from './styles';

const WarningCookie = () => {
  const { id, accessToken, refreshToken } = useSelector((state) => state.User);
  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    startVerification();
  }, []);

  const startVerification = async () => {
    const cookie = getCookieBrowser('cookie-banner-consent-accepted');
    const userIsLogged = checkIfUserIsLogged();

    let userAcceptedCookie;
    if (userIsLogged) {
      userAcceptedCookie = await getUserInformationAcceptedCookie();
    }

    if (cookie === '' || userAcceptedCookie === false) {
      setShowComponent(true);
    }
  };

  const checkIfUserIsLogged = () => {
    if (accessToken === '' || refreshToken === '') {
      return false;
    }

    return true;
  };

  const handleUserAcceptCookie = () => {
    setCookieBrowser('cookie-banner-consent-accepted', 'true', 365);
    setShowComponent(false);

    const userIsLogged = checkIfUserIsLogged();

    if (userIsLogged) {
      saveUserAcceptCookie();
    }
  };

  const getUserInformationAcceptedCookie = async () => {
    const response = await api.get(`users/${id}`);
    return response.data.accepted_cookies;
  };

  const saveUserAcceptCookie = () => {
    api.patch('/users/accepted-cookies', {
      acceptedCookie: true,
    });
  };

  const setCookieBrowser = (cname, cvalue, exdays) => {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = 'expires=' + d.toGMTString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  };

  const getCookieBrowser = (cname) => {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  };

  return (
    <Container showComponent={showComponent}>
      <p>
        Usamos cookies para melhorar a sua experiência no nosso site. Ao navegar
        neste site, você concorda com o uso de cookies.
      </p>
      <button onClick={() => handleUserAcceptCookie()}>Sim, eu aceito</button>
    </Container>
  );
};

export default WarningCookie;
