import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';

import Header from '../../components/Header';
import Content from '../../components/Content';
import GiftFriend from './components/GiftFriend';
import CurrentPlan from './components/CurrentPlan';
import LoadModal from '../../components/LoadModal';
import Footer from '../../components/Footer';

import iconBack from '../../assets/plans/icon-back.png';
import gift from '../../assets/choosePlan/gift.png';

import {
  Container,
  Row,
  Description,
  GradientBackground,
  GiftForFriend,
} from './styles';

const Plans = () => {
  const history = useHistory();
  const [userPlan, setUserPlan] = useState({
    plan_type: {
      name: '',
      price: '',
    },
  });
  const [showLoadModal, setShowLoadModal] = useState(false);

  const checkIfUserHasAnActivePlan = async () => {
    setShowLoadModal(true);

    try {
      const response = await api.get(`/users/plans`);
      const userPlan = response.data;
      if (userPlan.status === 'pending') {
        history.push('/escolha-um-plano');
      }
      setUserPlan(response.data);
    } catch (error) {
      history.push('/escolha-um-plano');
    }

    setShowLoadModal(false);
  };

  useEffect(() => {
    checkIfUserHasAnActivePlan();
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Header />

      <Content>
        <Row>
          <img
            onClick={() => history.goBack()}
            src={iconBack}
            alt="Ícone de voltar para a página anterior"
          />
        </Row>

        <Row>
          <Description>
            <h1>Planos</h1>

            <p>
              Aqui você verá as informações do seu Plano, poderá altera-lo caso
              queira e também poderá presentear alguém querido com um dos
              planos.
            </p>

            <GiftForFriend onClick={() => history.push('/presenteie-um-amigo')}>
              <img src={gift} alt="Ícone de presente" />

              <div>
                Presenteie
                <br />
                <span>UM AMIGO</span>
              </div>
            </GiftForFriend>
          </Description>
          <div>
            <CurrentPlan plan={userPlan} />
          </div>
        </Row>
      </Content>

      <Footer />

      <GradientBackground />

      <LoadModal show={showLoadModal} />
    </Container>
  );
};

export default Plans;
