import { message } from 'antd';

export const watchVideoLesson = (item, history) => {
  let is_published;
  let id;
  let { type } = item;

  if (type === 'podcast' || type === 'meditation' || type === 'episode') {
    is_published = item.episode.is_published;
    id = item.episode.id;
  } else if (type === 'lesson') {
    is_published = item.lesson.is_published;
    id = item.lesson.id;
  } else if (
    type === 'series' ||
    type === 'podcast_series' ||
    type === 'meditation_series'
  ) {
    is_published = item.series.is_published;
    id = item.series.id;
  }

  if (is_published) {
    let route;
    if (type === 'podcast') {
      route = '/podcast/aula/';
    } else if (type === 'meditation') {
      route = '/meditacao/aula/';
    } else if (type === 'lesson') {
      route = '/aula/';
    } else if (type === 'podcast_series') {
      route = '/podcast/';
    } else if (type === 'meditation_series') {
      route = '/meditacao/';
    } else if (type === 'series') {
      route = '/serie/';
    }
    const link = `${route}${id}`;
    history.push(link);
  } else message.error('Aula não publicada!');
};
