import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import MessageModal from '../../../../components/MessageModal';
import {
  Card,
  Circle,
  Container,
  DateDescription,
  Description,
  Hour,
  Thumbnail,
} from './styles';

const Lessons = ({ data, showHour }) => {
  const history = useHistory();
  const [showMessageModal, setShowMessageModal] = useState(false);

  const days = [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
  ];

  const renderDescription = (item) => {
    const date = new Date(item.date);

    return (
      <Description>
        <DateDescription>
          <Circle />
          {days[date.getDay()]} {date.getDate()}, {date.getFullYear()}
        </DateDescription>
        {showHour && (
          <Hour>
            {date.getHours().toString().length === 1
              ? `0${date.getHours()}`
              : date.getHours()}
            :
            {date.getMinutes().toString().length === 1
              ? `0${date.getMinutes()}`
              : date.getMinutes()}
          </Hour>
        )}
      </Description>
    );
  };

  const handleClickThumbnail = (item) => {
    let is_published;
    let id;
    let name;

    let { type } = item.post;

    if (type === 'podcast' || type === 'meditation' || type === 'episode') {
      is_published = item.post.episode.is_published;
      id = item.post.episode.id;
      name = item.post.episode.id;
    } else if (type === 'lesson') {
      is_published = item.post.lesson.is_published;
      id = item.post.lesson.id;
      name = item.post.lesson.id;
    } else if (
      type === 'series' ||
      type === 'podcast_series' ||
      type === 'meditation_series'
    ) {
      is_published = item.post.series.is_published;
      id = item.post.series.id;
      name = item.post.series.id;
    }

    if (is_published) {
      let route;
      if (type === 'podcast') {
        route = '/podcast/aula/';
      } else if (type === 'meditation') {
        route = '/meditacao/aula/';
      } else if (type === 'lesson') {
        route = '/aula/';
      } else if (type === 'podcast_series') {
        route = '/podcast/';
      } else if (type === 'meditation_series') {
        route = '/meditacao/';
      } else if (type === 'series') {
        route = '/serie/';
      }

      history.push(`${route}${id}`);
    } else {
      setShowMessageModal(true);
      setTimeout(function () {
        setShowMessageModal(false);
      }, 2000);
    }
  };

  const renderMessageModal = () => {
    if (showMessageModal) {
      return (
        <MessageModal
          title={`A aula ainda não foi publicada`}
          description={`A aula será publicada no dia e data marcada no calendário.`}
          setVisible={() => {}}
        />
      );
    }
  };

  const renderCards = () => {
    return data.map((item) => {
      let thumbnail_url = '';

      let { type, id } = item.post;

      if (type === 'podcast' || type === 'meditation' || type === 'episode') {
        thumbnail_url = item.post.episode.thumbnail_url;
      } else if (type === 'lesson') {
        thumbnail_url = item.post.lesson.thumbnail_url;
      } else if (
        type === 'series' ||
        type === 'podcast_series' ||
        type === 'meditation_series'
      ) {
        thumbnail_url = item.post.series.thumbnail_url;
      }
      return (
        <Card key={id}>
          <Thumbnail
            onClick={() => handleClickThumbnail(item)}
            src={thumbnail_url}
            alt="Thumbnail"
          />
          {renderDescription(item)}
          {renderMessageModal()}
        </Card>
      );
    });
  };

  return <Container>{renderCards()}</Container>;
};

export default Lessons;
