import styled from 'styled-components';

export const Button = styled.button`
  border: none;
  outline: none;
  background: ${(props) => props.backgroundColor};
  border-radius: 8px;
  padding: 16px 20px;
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* text-transform: uppercase; */
  width: ${(props) => props.width};
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

export const Spinner = styled.span`
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #fff;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
`;
