import styled from 'styled-components';

export const Box = styled.div`
  margin-top: 70px;
  border-bottom: 6px solid #f5f5f5;
  padding: 50px;
`;

export const Options = styled.div`
  margin: 50px 0px;
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
`;
