import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;

  p {
    font-family: 'Aller-Light';
    font-size: 1.9rem;
    color: #5c5c5c;
  }
`;

export const Title = styled.h1`
  font-family: 'Aller-Regular';
  font-size: 2rem;
  color: var(--dark03);
`;

export const Divisor = styled.div`
  margin-bottom: auto;
`;

export const MissionCard = styled.div`
  max-width: 520px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  font-family: 'Aller-Light';
  font-size: 1.3rem;
  color: #5c5c5c;
  margin-top: 36px;

  a {
    > div {
      padding: 36px 0px 36px 58px;
    }
  }

  > div {
    padding: 36px 0px 36px 58px;
    border-bottom: 1px solid #5c5c5c5e;

    button {
      margin-left: 15%;
    }

    .ant-switch-checked {
      background-color: #95faa2;

      .ant-switch-handle::before {
        background-color: #19b441;
      }
    }
  }
`;

export const ExitButton = styled.button`
  max-width: 520px;
  margin-top: 36px;

  font-family: 'Aller-Bold';
  font-size: 1.6rem;
  color: #19b441;

  border: none;
  background-color: transparent;
`;

export const GradientBackground = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 500px;
  z-index: -1;
  background: rgb(97, 225, 113);
  background: linear-gradient(
    180deg,
    rgba(97, 225, 113, 1) 0%,
    rgba(248, 251, 246, 1) 70%,
    rgba(255, 255, 255, 1) 80%
  );
`;
