import { Tooltip } from 'antd';

import FormLogin from '../../components/FormLogin';
import bgImage from './img/openDoors.svg';
import { BoxMain, BoxWelcome } from './styles';
import { PRE_REGISTRATION_CAPTURE_LINK } from '../../settings';

const OpenDoors = () => {
  return (
    <BoxMain>
      <div>
        <BoxWelcome>
          <h3>Olá! Olá!</h3>
          <h2>Estamos com as portas abertas.</h2>

          <Tooltip title="Saiba mais">
            <a
              target="_blank"
              rel="noreferrer"
              href={PRE_REGISTRATION_CAPTURE_LINK}
            >
              <button>Saiba mais</button>
            </a>
          </Tooltip>

          <div></div>
        </BoxWelcome>
        <FormLogin openDoors={true} />
      </div>
      <div>
        <img src={bgImage} alt="Bem vindo(a) de volta ao YOGA CO" />
      </div>
    </BoxMain>
  );
};

export default OpenDoors;
