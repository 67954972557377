import React, { useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Select } from 'antd';

import Input from '../../../../components/Input';
import Button from '../../../../components/Button';

import {
  Form,
  Title,
  InputRow,
  Tooltip,
  BackButton,
} from './_bankSlipFormData';

import cpfMask from '../../../../utils/cpfMask';
import sanitizeString from '../../../../utils/sanitizeString';
import cepFormat from '../../../../utils/cepFormat';

export const BankSlipFormData = ({
  setShowFormDataSection,
  userInfo,
  setUserInfo,
  loading,
  handleDirectPayment,
}) => {
  const { Option } = Select;

  const brStates = [
    { value: 'AC', placeholder: 'Acre' },
    { value: 'AL', placeholder: 'Alagoas' },
    { value: 'AP', placeholder: 'Amapá' },
    { value: 'AM', placeholder: 'Amazonas' },
    { value: 'BA', placeholder: 'Bahia' },
    { value: 'CE', placeholder: 'Ceará' },
    { value: 'DF', placeholder: 'Distrito Federal' },
    { value: 'ES', placeholder: 'Espírito Santo' },
    { value: 'GO', placeholder: 'Goiás' },
    { value: 'MA', placeholder: 'Maranhão' },
    { value: 'MT', placeholder: 'Mato Grosso' },
    { value: 'MS', placeholder: 'Mato Grosso do Sul' },
    { value: 'MG', placeholder: 'Minas Gerais' },
    { value: 'PA', placeholder: 'Pará' },
    { value: 'PB', placeholder: 'Paraíba' },
    { value: 'PR', placeholder: 'Paraná' },
    { value: 'PE', placeholder: 'Pernambuco' },
    { value: 'PI', placeholder: 'Piauí' },
    { value: 'RJ', placeholder: 'Rio de Janeiro' },
    { value: 'RN', placeholder: 'Rio Grande do Norte' },
    { value: 'RS', placeholder: 'Rio Grande do Sul' },
    { value: 'RO', placeholder: 'Rondônia' },
    { value: 'RR', placeholder: 'Roraima' },
    { value: 'SC', placeholder: 'Santa Catarina' },
    { value: 'SP', placeholder: 'São Paulo' },
    { value: 'SE', placeholder: 'Sergipe' },
    { value: 'TO', placeholder: 'Tocantins' },
  ];

  const [errorMensage, setErrorMensage] = useState('');

  const renderErrorMensage = () => {
    if (errorMensage) {
      return <Tooltip>{errorMensage}</Tooltip>;
    }
  };

  const handleStateSelect = (value) => {
    setUserInfo({ ...userInfo, state: value });
  };

  const handlePayment = async () => {
    handleDirectPayment(undefined, undefined, true);
  };

  const formIsNotValid = () => {
    const { cpf_cnpj, street, number, district, city, state, zip_code } =
      userInfo;

    if (
      cpf_cnpj.length < 11 ||
      !street.length ||
      !number.length ||
      !district.length ||
      !city.length ||
      !state.length ||
      zip_code.length < 8
    )
      return true;

    return false;
  };

  return (
    <Form>
      <Title>Adicione seus dados para pagamento</Title>

      <Input
        placeholder="CPF"
        value={cpfMask(userInfo.cpf_cnpj)}
        onInput={(e) =>
          setUserInfo({ ...userInfo, cpf_cnpj: sanitizeString(e.target.value) })
        }
      />

      <Input
        placeholder="Rua"
        onInput={(e) => setUserInfo({ ...userInfo, street: e.target.value })}
      />

      <Input
        placeholder="Número"
        onInput={(e) => setUserInfo({ ...userInfo, number: e.target.value })}
      />

      <Input
        placeholder="Bairro"
        onInput={(e) => setUserInfo({ ...userInfo, district: e.target.value })}
      />

      <Input
        placeholder="Cidade"
        onInput={(e) => setUserInfo({ ...userInfo, city: e.target.value })}
      />

      <Select
        className="uf__select"
        placeholder="Estado"
        showSearch
        bordered={false}
        optionFilterProp={'children'}
        allowClear
        filterOption={(input, option) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={handleStateSelect}
      >
        {brStates.map(({ value, placeholder }) => (
          <Option value={value} key={value}>
            {placeholder}
          </Option>
        ))}
      </Select>

      <Input
        placeholder="CEP"
        value={cepFormat(userInfo.zip_code)}
        onInput={(e) =>
          setUserInfo({ ...userInfo, zip_code: sanitizeString(e.target.value) })
        }
      />

      {renderErrorMensage()}

      <Button
        type="button"
        onClick={() => handlePayment()}
        disabled={formIsNotValid() || loading}
      >
        {loading ? <LoadingOutlined /> : 'Finalizar Compra'}
      </Button>

      <BackButton onClick={() => setShowFormDataSection('register')}>
        Voltar
      </BackButton>
    </Form>
  );
};
