import visibility_off from './icons/visibility_off.png';
import visibility from './icons/visibility.png';

const settings = {
  text: { img: visibility, reverse: 'password' },
  password: { img: visibility_off, reverse: 'text' },
};

export const togglePasswordDisplay = (inputId) => {
  const inputElement = document.getElementById(inputId);

  if (inputElement?.parentNode?.lastChild?.nodeName === 'IMG') return;
  if (inputElement) {
    const $ = inputElement;
    const img = document.createElement('img');
    img.src = settings.password.img;
    const parent = $.parentNode;
    parent?.appendChild(img);
    img.addEventListener('click', () => {
      const type = $.getAttribute('type');
      $.setAttribute('type', settings[type].reverse);
      img.src = settings[type].img;
    });
  }
};
