import styled from 'styled-components';

export const BoxClosedDoors = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  margin-bottom: 100px;

  h1 {
    color: #030303;
    font-family: Aller-Regular;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
  p {
    color: rgba(0, 0, 0, 0.8);
    font-family: Aller-Regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.198px;
  }

  button {
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 8px;
    background: #030303;
    border: none;
    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.07);

    color: #fff;
    text-align: center;
    font-family: Aller-Regular;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
`;
