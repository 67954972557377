import styled from 'styled-components';

export const Box = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 25px;
`;

export const Main = styled.div`
  font-family: 'Aller-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: var(--dark03);

  h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 30px;
  }
  p,
  .box-pament-options {
    margin-bottom: 24px;
  }
  button {
    padding: 16px;
    margin: 5px auto 0 auto;
    gap: 10px;
    height: 56px;
    background-color: var(--dark03);
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    border: none;
    outline: none;
    color: #fff;
    :disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  label {
    cursor: pointer;
  }
  select {
    width: 100%;
    padding: 16px;
    background: #f5f5f5;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    border: none;
  }
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 16px;
  background: #f5f5f5;
  background: ${(props) => (props.selected === true ? '#80F08C' : '#f5f5f5')};
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.07);
  line-height: 21.8px;
  input {
    width: 24px;
    height: 24px;
    background: #ffffff;
    border: 2px solid rgba(0, 0, 0, 0.4);
    border-radius: 200px;
    margin-right: 20px;
  }
  label {
    margin-bottom: 0;
    cursor: pointer;
  }
`;

export const AddressForm = styled.form`
  input {
    width: 100%;
    padding: 10px;
    background: #f5f5f5;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    border: none;
    margin-bottom: 7px;
  }
  div {
    text-align: end;
  }
  button {
    padding: 10px;
    gap: 10px;
    background: var(--purpleD1);
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    font-weight: 700;
    font-size: 16px;
    border: none;
    cursor: pointer;

    :disabled {
      opacity: 0.5;
    }
  }
`;

export const CPFForm = styled.form`
  input {
    width: 100%;
    padding: 10px;
    background: #f5f5f5;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    border: none;
    margin-bottom: 7px;
  }
  small {
    color: var(--redFE);
  }
  div {
    text-align: end;
  }
  button {
    padding: 10px;
    gap: 10px;
    background: var(--purpleD1);
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    font-weight: 700;
    font-size: 16px;
    border: none;
    cursor: pointer;

    :disabled {
      opacity: 0.5;
    }
  }
`;
