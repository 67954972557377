import styled from 'styled-components';

export const Container = styled.div`
  > div {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 1260px) {
    > div {
      flex-direction: column;
      text-align: center;
    }
  }
`;

export const PlanContainer = styled.div`
  > span {
    font-family: 'Aller-Bold';
    font-size: 1.4rem;
    color: #b4b4b4;
    cursor: pointer;
    display: block;
    text-align: center;
  }

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Title = styled.div`
  font-family: 'Aller-Bold';
  font-size: 1.6rem;
  color: var(--dark03);
`;

export const Form = styled.form`
  width: 100%;
  margin-left: 100px;

  > div:nth-of-type(2) {
    width: 79%;
    background-color: #eef0f1;

    > input {
      background-color: #eef0f1;
    }
  }

  > div:nth-of-type(4) {
    width: 64%;
    background-color: #eef0f1;

    > input {
      background-color: #eef0f1;
    }
  }

  > button {
    font-family: 'Aller-Regular';
    font-size: 1.4rem;
    margin-top: 40px;
  }

  @media (max-width: 1260px) {
    width: 100%;
    margin-top: 20px;
  }

  @media (max-width: 1260px) {
    margin-left: 0%;

    > div {
      width: 100%;
    }

    > div:nth-of-type(2) {
      width: 100%;
    }

    > div:nth-of-type(4) {
      width: 100%;
    }

    > button {
      width: 100%;
    }
  }
`;

export const InputRow = styled.div`
  display: flex;
  width: 100%;

  > div:nth-of-type(1) {
    width: 54%;
    background-color: #eef0f1;
    > input {
      background-color: #eef0f1;
    }
  }

  > div:nth-of-type(2) {
    width: 20%;
    margin-left: 28px;
    background-color: #eef0f1;
    > input {
      background-color: #eef0f1;
    }
  }

  @media (max-width: 1260px) {
    flex-direction: column;

    > div {
      width: 100%;
    }

    > div:nth-of-type(1) {
      width: 100%;
    }

    > div:nth-of-type(2) {
      width: 100%;
      margin-left: 0px;
    }
  }
`;

export const SubscriptionForm = styled.div`
  &.subscription-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    max-width: 630px;
    min-height: 53vh;

    margin: 0 auto;
    padding: 1.5em 15px;

    .input {
      width: 100%;
      max-width: 90%;

      margin: 10px 0;
    }

    .subscription-form__terms-anchor {
      color: var(--green74);
      font-size: 1.2rem;

      margin: 5px auto 5px 5%;

      cursor: pointer;
    }

    .subscription-form__row {
      display: flex;
      gap: 5px;

      width: 90%;
      margin: 10px 0;

      color: var(--grey9E);
      font-size: 1.2rem;
    }

    .common-button {
      width: 90%;
      margin: 15px auto 15px;
    }
  }
`;
