import api from '../services/api';

export const checkIfUserHasAnActivePlan = async (checkStatus = false) => {
  try {
    const url = `users/plans${checkStatus ? '?checkStatus=true' : ''}`;
    const { data: currentPlan } = await api.get(url);
    const planIsActive = currentPlan?.status === 'authorized' ? true : false;
    return planIsActive;
  } catch (error) {
    if (error?.response?.status === 422) {
      return false;
    }
    return false;
  }
};
