import { message } from 'antd';
import { IUGU_TEST_MODE, REACT_APP_IUGU_ACCOUNT } from '../settings';

export function startScript() {
  const iugoScriptLoaded = document.querySelector(
    "script[src='https://js.iugu.com/v2']",
  );
  if (!iugoScriptLoaded) {
    const script = document.createElement('script');
    script.src = 'https://js.iugu.com/v2';
    script.async = true;
    document.body.appendChild(script);
  }
}

export const storeCard = async (
  informations,
  setToken = null,
  setLoading = null,
) => {
  if (setLoading) setLoading(true);
  const name = informations.name;
  const nameSplited = name.split(' ');
  const primaryName = nameSplited[0];
  let surname = '';
  for (let index = 1; index < nameSplited.length; index++) {
    surname += nameSplited[index];
    if (index < nameSplited.length - 1) {
      surname += ' ';
    }
  }

  try {
    await window.Iugu.setAccountID(REACT_APP_IUGU_ACCOUNT);
    if (IUGU_TEST_MODE === true) {
      window.Iugu.setTestMode(true);
    }

    const cc = window.Iugu.CreditCard(
      informations.number,
      informations.duedate.slice(0, 2),
      `20${informations.duedate.slice(3)}`,
      primaryName,
      surname,
      informations.cvv,
    );
    const duedate = `${informations.duedate.slice(
      0,
      2,
    )}/20${informations.duedate.slice(3)}`;

    if (!window.Iugu.utils.validateCreditCardNumber(informations.number)) {
      message.error('Preencha um número de cartão de crédito válido');
      if (setLoading) setLoading(false);
      return;
    }

    const brand = window.Iugu.utils.getBrandByCreditCardNumber(
      informations.number,
    );

    if (!brand) {
      message.error('Não suportamos essa bandeira de cartão de crédito');
      if (setLoading) setLoading(false);
      return;
    }

    if (!window.Iugu.utils.validateCVV(informations.cvv, brand)) {
      message.error('Preencha um CVV válido');
      if (setLoading) setLoading(false);
      return;
    }

    if (!window.Iugu.utils.validateExpirationString(duedate)) {
      message.error('A data de vencimento do cartão de crédito está inválida');
      if (setLoading) setLoading(false);
      return;
    }

    window.Iugu.createPaymentToken(cc, function (response) {
      if (response.id) {
        setToken(response.id);
      } else {
        const genericError = 'verifique os dados e tente novamente';

        if (response.errors) {
          if (setLoading) setLoading(false);
          return message.error(`Cartão inválido, ${genericError}`);
        }

        const errorKeys = Object.keys(response.errors);
        const displayError = errorKeys[0];

        const error = {
          verification_value: 'verifique se o CVV foi preenchido corretamente',
          number: 'verifique se o número foi preenchido corretamente',
          expiration: 'verifique se a data de expiração está correta',
          last_name: 'verifique se o sobrenome foi preenchido',
          first_name:
            'veirifique se o primeiro nome foi preenchido corretamente',
        };

        message.error(
          `Cartão inválido, ${error[displayError] || genericError}`,
        );
      }
      if (setLoading) setLoading(false);
    });
  } catch (error) {
    message.error('Erro ao cadastrar seu cartão de crédito');
    message.error(error.response?.data?.message || error.toString());
    if (setLoading) setLoading(false);
  }
};

export const creditCardInfoIsNotValid = (informations) => {
  const { name, number, cvv, duedate } = informations;
  if (
    !name.length ||
    !name.includes(' ') ||
    number.length < 16 ||
    cvv.length < 3 ||
    duedate.length < 5
  ) {
    return true;
  }
  return false;
};
