import styled from 'styled-components';

export const Main = styled.main`
  min-height: 500px;
  color: var(--dark03);
  font-family: 'Aller-Regular';
  font-style: normal;
`;

export const ChoosePaymentMethodCard = styled.div`
  display: flex;
  justify-content: space-between;
`;
