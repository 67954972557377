import { HashRouter, Switch } from 'react-router-dom';

import Home from '../pages/Home';
import MegaHome from '../pages/MegaHome';
import AboutUs from '../pages/AboutUs';
import ChangePassword from '../pages/ChangePassword';
import ChangePlan from '../pages/ChangePlan';
import ChoosePlan from '../pages/ChoosePlan';
import Class from '../pages/Class';
import Congratulations from '../pages/Congratulations';
import ContactUs from '../pages/ContactUs';
import CreateMyDiary from '../pages/CreateMyDiary';
import DataToPayWithBankSlip from '../pages/DataToPayWithBankSlip';
import EditMyDiary from '../pages/EditMyDiary';
import Favorites from '../pages/Favorites';
import FrequentlyAskedQuestions from '../pages/FrequentlyAskedQuestions';
import GiftFriend from '../pages/GiftFriend';
import GiftFriendPayment from '../pages/GiftFriendPayment';
import Login from '../pages/Login';
import MegaLogin from '../pages/MegaLogin';
import { MentorshipPayment } from '../pages/MentorshipPayment/MentorshipPayment';
import { MentorshipThankPage } from '../pages/MentorshipThankPage/MentorshipThankPage';
import MyCalendar from '../pages/MyCalendar';
import MyDiary from '../pages/MyDiary';
import MyLessons from '../pages/MyLessons';
import NotFound from '../pages/NotFound';
import Notifications from '../pages/Notifications';
import Payment from '../pages/Payment';
import Plans from '../pages/Plans';
import Profile from '../pages/Profile';
import MegaProfile from '../pages/MegaProfile';
import ProspectUsers from '../pages/ProspectUsers';
import Serie from '../pages/Serie';
import SignUp from '../pages/SignUp';
import JivoChatToApp from '../pages/JivoChatToApp';
import Sunflower from '../pages/SunflowerStudent';
import TermsOfUseAndPrivacy from '../pages/TermsAndConditions';
import UserConfiguration from '../pages/UserConfiguration';
import Route from './Route';
import MegaHeader from '../pages/MegaHome/components/MegaHeader';
import MegaFooter from '../pages/MegaHome/components/MegaFooter';
import ScrollToTop from './ScrollToTop';
import OpenDoors from '../pages/OpenDoors';
import RecoverPassword from '../pages/RecoverPassword';
import MegaSignUp from '../components/MegaSignUp';

const Routes = () => {
  // const appHistory = createBrowserHistory();

  return (
    // <Router history={history}>
    <HashRouter>
      <MegaHeader />
      <ScrollToTop>
        <Switch>
          {/* <Route component={LandingPage} path="/" exact /> */}
          {/* <Route path="/" component={NewHomePage} exact /> */}
          <Route path="/" component={OpenDoors} exact />
          <Route component={AboutUs} path="/sobre-nos" isPrivateAndPublic />
          <Route component={ContactUs} path="/contato" isPrivateAndPublic />
          <Route component={UserConfiguration} path="/configuracao" isPrivate />
          {/* <Route component={Home} path="/home" isPrivate exact /> */}
          <Route component={Home} path="/old-home-page" isPrivate exact />
          <Route component={MegaHome} path="/home" isPrivate exact />
          <Route component={MegaLogin} path="/login" />
          <Route component={ChangePassword} path="/alterar-senha" />
          <Route
            component={JivoChatToApp}
            path="/jivo-chat-app"
            isPrivateAndPublic
          />
          <Route component={RecoverPassword} path="/solicitar-nova-senha" />
          <Route component={MegaSignUp} path="/cadastro" />
          <Route component={MegaSignUp} path="/cadastro-nov" />
          <Route component={MegaSignUp} path="/cadastro-dev" />
          {/* <Route component={ProspectUsers} path="/cadastro" /> */}
          <Route component={MegaProfile} path="/perfil" isPrivate />
          <Route component={Plans} path="/meu-plano" isPrivate />
          <Route component={ChangePlan} path="/alterar-plano" isPrivate />
          <Route component={Serie} path="/serie/:id" isPrivateAndPublic />
          <Route
            component={Serie}
            path="/podcast/:id"
            isPrivateAndPublic
            exact
          />
          <Route
            component={Serie}
            path="/meditacao/:id"
            isPrivateAndPublic
            exact
          />
          <Route component={Class} path="/aula/:id" isPrivateAndPublic />
          <Route
            component={Class}
            path="/podcast/aula/:id"
            isPrivateAndPublic
          />
          <Route
            component={Class}
            path="/meditacao/aula/:id"
            isPrivateAndPublic
          />
          <Route
            component={ChoosePlan}
            path="/escolha-um-plano"
            isPrivateAndPublic
          />
          {/* <Route
          component={ProspectUsers}
          path="/escolha-um-plano"
          isPrivateAndPublic
        /> */}
          <Route component={Payment} path="/pagamento" isPrivate />
          <Route
            component={Congratulations}
            path="/parabens"
            isPrivateAndPublic
          />
          <Route component={Sunflower} path="/usuario-girassol" />
          <Route
            component={DataToPayWithBankSlip}
            path="/confirmar-endereco"
            isPrivate
          />
          <Route component={MyCalendar} path="/calendario" isPrivate />
          <Route
            component={FrequentlyAskedQuestions}
            path="/perguntas-frequentes"
            isPrivateAndPublic
          />
          <Route component={Favorites} path="/favoritos" isPrivate />
          <Route component={MyLessons} path="/minhas-aulas" isPrivate />
          {/* <Route component={GiftFriend} path="/presenteie-um-amigo" isPrivate /> */}
          {/* <Route
            component={GiftFriendPayment}
            path="/pagamento-presenteie-um-amigo"
            isPrivate
          /> */}
          <Route
            component={CreateMyDiary}
            path="/criar-meu-diario"
            isPrivateAndPublic
          />
          <Route component={MyDiary} path="/meu-diario" isPrivate />
          <Route
            component={EditMyDiary}
            path="/editar-meu-diario"
            isPrivate
            exact
          />
          <Route
            component={EditMyDiary}
            path="/editar-meu-diario/:idNote"
            isPrivate
          />
          <Route component={Notifications} path="/notificacoes" isPrivate />
          <Route
            component={TermsOfUseAndPrivacy}
            path="/termos-de-uso-e-privacidade"
            isPrivateAndPublic
          />
          <Route
            component={MentorshipPayment}
            path="/mentoria"
            isPrivateAndPublic
          />
          <Route
            component={MentorshipThankPage}
            path="/parabens-pelo-grande-passo"
            isPrivateAndPublic
          />
          {/* <Route component={SalePlanPage} path="/promocao" isPrivateAndPublic /> */}

          <Route path="*" component={NotFound} />
        </Switch>
      </ScrollToTop>
      <MegaFooter />
    </HashRouter>
    // </Router>
  );
};

export default Routes;
