import styled from 'styled-components';

export const Container = styled.nav`
  z-index: 2;
  position: absolute;
  top: 0px;
  left: ${(props) => (props.visible ? 0 : -360)}px;
  height: auto;
  width: auto;
  background-color: #fff;
  transition: all 0.5s;
  padding: 20px;
  box-shadow: 0px 0px 6px 0px lightgrey;
  border-bottom-right-radius: 10px;

  > div:nth-of-type(1) {
    margin: 20px;
  }

  ul {
    list-style: none;
    margin: 20px;

    li {
      display: flex;
      align-items: center;
      margin: 12px 10px 12px 10px;
      cursor: pointer;
      width: fit-content;

      img {
        width: 20px;
      }

      span {
        font-family: 'Aller-Regular';
        font-size: 1.4rem;
        color: var(--dark03);
        margin-left: 20px;
      }
    }
  }

  @media (max-width: 480px) {
    > div:nth-of-type(1) {
      margin: 10px;
    }

    ul {
      margin: 10px;
    }
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const MenuIcon = styled.div`
  cursor: pointer;
  > div {
    width: 40px;
    height: 6px;
    margin-bottom: 4px;
    background-color: var(--purple84);
  }
`;

export const Logo = styled.img`
  cursor: pointer;
  width: 156px;
  height: 25px;
  margin-left: 20px;
`;
