import styled from 'styled-components';

export const Container = styled.div`
  animation: rendering 1s;
  width: 100%;
  background-color: #f2f4f7;
  transition: all 1s;
  min-height: 435px;
  @media (max-width: 420px) {
    min-height: 360px;
  }
  @media (max-width: 380px) {
    min-height: 332px;
  }

  > div {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    padding-top: 40px;
    padding-bottom: 40px;

    @keyframes rendering {
      from {
        transform: scale(0);
        opacity: 0;
      }
      to {
        transform: scale(1);
        opacity: 1;
      }
    }

    > div.slick-slider {
      animation: rendering 1s ease-in;
      transition: all 1s;
      width: 100%;
    }
  }
`;

export const Spinner = styled.div`
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #61e171;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1.2s linear infinite;
  margin: 0 auto;
`;

export const Title = styled.div`
  font-family: 'Aller-Light';
  font-size: 1.6rem;
  color: #000;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin: 20px 0px;
`;
