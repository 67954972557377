import styled from 'styled-components';

export const Title = styled.div`
  font-family: 'Aller-Regular';
  font-size: 1.6rem;
  color: var(--dark03);
  width: fit-content;
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px lightgrey;
  border-radius: 40px;
`;
