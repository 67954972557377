import React, { useState } from 'react';
import {
  Editor,
  EditorState,
  RichUtils,
  Modifier,
  convertToRaw,
} from 'draft-js';
import 'draft-js/dist/Draft.css';

import textAlignLeft from '../../../../assets/myDiary/text-align-left.png';
import textAlignCenter from '../../../../assets/myDiary/text-center.png';
import textAlignRight from '../../../../assets/myDiary/text-align-right.png';
import underlined from '../../../../assets/myDiary/underlined.png';

import { Container, Options, TextArea } from './styles';

const EditorComponent = () => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );
  const [textAlign, setTextAlign] = useState('left');
  // const [boldEnabled, setBoldEnabled] = useState(false);

  // const boldText = () => {};

  const handleBold = () => {
    // setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
  };
  return (
    <Container>
      <Options>
        <div>
          {/* <img
            onClick={() => setTextAlign('left')}
            src={textAlignLeft}
            alt="Ícone de alinhar texto para esquerda"
          />
          <img
            onClick={() => setTextAlign('center')}
            src={textAlignCenter}
            alt="Ícone de alinhar texto no centro"
          />
          <img
            onClick={() => setTextAlign('right')}
            src={textAlignRight}
            alt="Ícone de alinhar texto para direita"
          /> */}
        </div>
        <div>
          {/* <span onClick={() => handleBold()}>B</span>
          <img src={underlined} alt="Ícone de sublinhar texto" /> */}
        </div>
      </Options>
      {/* <Editor
        textAlignment={textAlign}
        editorState={editorState}
        onChange={(editorState) => setEditorState(editorState)}
      /> */}
      <TextArea id="description-note" />
    </Container>
  );
};

export default EditorComponent;
