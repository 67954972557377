import styled from 'styled-components';

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  padding: 25px;
  background: #d1b8eb;
  background: var(--purpleD1);
  font-family: 'Aller-Regular';
  transition: all 0.5s ease 0s;
  z-index: 2;
  position: sticky;
  top: 0;
  img {
    object-fit: contain;
  }
  .logo {
    width: 150px;
  }

  @media (max-width: 720px) {
    margin-bottom: -15px;
    border-radius: 0 0 20px 20px;
    div:last-child {
      margin: 0 auto;
    }
  }

  @media (max-width: 281px) {
    .logo {
      width: 100px;
    }
  }
`;

export const OptionsBox = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 35px;
  img {
    width: 25px;
  }
  @media (max-width: 280px) {
    img {
      width: 20px;
    }
  }
`;
