import styled from 'styled-components';

export const Button = styled.button`
  font-family: 'Aller-Bold';
  font-size: 2rem;
  text-align: center;
  width: 500px;
  padding: 20px;
  background-color: #61e171;
  color: #fff;
  border-radius: 60px;
  border: 0;
  outline: 0;

  &:hover {
    filter: brightness(110%);
  }

  box-shadow: 0 3px 6px rgb(0 0 0 / 25%);
  @media (max-width: 540px) {
    width: 100%;
    padding: 10px;
  }

  transition: all 0.3s ease-in-out;
`;

export const LoginButton = styled(Button)`
  background-color: #a16ccd;
`;

export const LoginText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: 'Aller-Light';
  font-size: 1.4rem;
  color: #fff;
  margin-top: 20px;
  text-align: center;
  span {
    font-family: 'Aller-Bold';
    cursor: pointer;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  img {
    height: 14px;
    cursor: pointer;
  }
`;

export const BackButton = styled.div`
  font-family: 'Aller-Light';
  font-size: 1rem;
  color: #fff;
  margin-left: 8px;
  cursor: pointer;
`;
