import { PRE_REGISTRATION_CAPTURE_LINK } from '../../../../settings';
import QuestionsViaWhatsApp from '../QuestionsViaWhatsApp';
import { BoxClosedDoors } from './styles';

const ClosedDoors = () => {
  return (
    <>
      <BoxClosedDoors>
        <h1>
          Ooops. Nossas turmas estão completas e nossas portas estão fechadas.
        </h1>
        <p>
          Mas não se preocupe… queremos muito ter você aqui! Basta entrar na
          lista de espera que a gente vem correndo te avisar quando as portas se
          abrirem.
        </p>
        <p>
          Enquanto você aguarda temos um presente pra você. Só clicar aqui e se
          inscrever.
        </p>
        <a
          target="_blank"
          rel="noreferrer"
          href={PRE_REGISTRATION_CAPTURE_LINK}
        >
          <button>Quero meu presente</button>
        </a>
      </BoxClosedDoors>
      {/* <QuestionsViaWhatsApp /> */}
    </>
  );
};

export default ClosedDoors;
