import styled from 'styled-components';

export const Container = styled.div`
  > div {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 1260px) {
    > div {
      flex-direction: column;
      text-align: center;
    }
  }
`;

export const PlanContainer = styled.div`
  > span {
    font-family: 'Aller-Bold';
    font-size: 1.4rem;
    color: #b4b4b4;
    cursor: pointer;
    display: block;
    text-align: center;
  }

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const PlanDescription = styled.div`
  width: 500px;

  > div {
    font-family: 'Aller-Bold';
    font-size: 2rem;
    color: var(--dark03);
  }

  > p {
    font-family: 'Aller-Light';
    font-size: 1.6rem;
    color: #5c5c5c;
  }

  > label {
    font-family: 'Aller-Regular';
    font-size: 1.4rem;
    color: var(--dark03);
    margin-left: 10px;
  }

  @media (max-width: 1260px) {
    margin-top: 40px;
    width: 100%;
  }
`;

export const Title = styled.div`
  font-family: 'Aller-Bold';
  font-size: 1.6rem;
  color: var(--dark03);
`;

export const Form = styled.form`
  width: 100%;
  margin-left: 100px;

  > div:nth-of-type(2) {
    width: 79%;
    background-color: #eef0f1;

    > input {
      background-color: #eef0f1;
    }
  }

  > div:nth-of-type(4) {
    width: 64%;
    background-color: #eef0f1;

    > input {
      background-color: #eef0f1;
    }
  }

  > button {
    font-family: 'Aller-Regular';
    font-size: 1.4rem;
    margin-top: 40px;
  }

  @media (max-width: 1260px) {
    width: 100%;
    margin-top: 20px;
  }

  @media (max-width: 1260px) {
    margin-left: 0%;

    > div {
      width: 100%;
    }

    > div:nth-of-type(2) {
      width: 100%;
    }

    > div:nth-of-type(4) {
      width: 100%;
    }

    > button {
      width: 100%;
    }
  }
`;

export const InputRow = styled.div`
  display: flex;
  width: 100%;

  > div:nth-of-type(1) {
    width: 54%;
    background-color: #eef0f1;
    > input {
      background-color: #eef0f1;
    }
  }

  > div:nth-of-type(2) {
    width: 20%;
    margin-left: 28px;
    background-color: #eef0f1;
    > input {
      background-color: #eef0f1;
    }
  }

  @media (max-width: 1260px) {
    flex-direction: column;

    > div {
      width: 100%;
    }

    > div:nth-of-type(1) {
      width: 100%;
    }

    > div:nth-of-type(2) {
      width: 100%;
      margin-left: 0px;
    }
  }
`;
