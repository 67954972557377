import styled from 'styled-components';

export const HamburgerMenu = styled.img`
  cursor: pointer;
  width: 35px;
`;

export const Icon = styled.img``;

export const UserPhoto = styled.img`
  width: 43.73px;
  height: 43.73px;
  border-radius: 50%;
  object-fit: cover;
`;

export const ContentBox = styled.div`
  z-index: 3;
  transition: all 2s ease 3s;
  position: fixed;
  top: 0;
  left: 0;
  width: 312px;
  height: 496px;
  padding: 25px;
  color: var(--dark03);
  border-radius: 0 20px 20px 0;
  background-color: var(--lightFB);
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.25);
  font-family: 'Aller-Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
`;

export const Options = styled.aside`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  div {
    display: flex;
    gap: 15px;
    align-items: center;
    cursor: pointer;
    height: 50px;
    border-radius: 20px;
    &:hover {
      background-color: var(--greyBF);
    }
  }
`;
