import styled from 'styled-components';

export const CardBox = styled.div`
  display: flex;
  gap: 16px;
  .brand {
    width: 34px;
    height: 24px;
    flex-shrink: 0;
    border-radius: 2px;
    border: 0.5px solid #d9d9d9;
  }
  div {
    display: flex;
    gap: 8px;
    color: #030303;
    font-family: Aller-Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
`;
