import styled from 'styled-components';

export const Container = styled.div`
  animation: rendering 1s;

  > div:nth-of-type(2) {
    > img {
      width: 100%;
    }
  }

  @media (max-width: 1220px) {
    > div:nth-of-type(2) {
      display: flex;
      flex-direction: column-reverse;
    }
  }
`;

export const Informations = styled.div`
  position: absolute;
  background-color: #fff;
  padding: 20px;
  width: 600px;
  box-shadow: 0px 0px 6px 0px lightgrey;
  margin: 20px 0px 0px 20px;

  h1 {
    font-family: 'Bartolomeo-Regular';
    font-size: 4rem;
    color: var(--dark03);
  }

  p {
    font-family: 'Aller-Light';
    font-size: 1.6rem;
    color: #5c5c5c;
    margin-top: 20px;
  }

  span:nth-of-type(1) {
    display: block;
    font-family: 'Aller-Light';
    font-size: 2rem;
    color: #707070;
    margin-top: 40px;
  }

  span:nth-of-type(2) {
    display: block;
    font-family: 'Bartolomeo-Regular';
    font-size: 3rem;
    color: var(--dark03);
  }

  button {
    margin: 40px auto 20px auto;
  }

  @media (max-width: 1220px) {
    position: relative;
    width: 90%;
    margin: 20px auto;
    box-shadow: 0px 0px 0px 0px lightgrey;
  }

  @media (max-width: 520px) {
    button {
      width: 100%;
      margin-top: 20px;
    }
  }
`;
