import styled from 'styled-components';

export const Container = styled.div`
  &.list-cards {
    .active .card__informations {
      border: 3px solid var(--green6E);

      box-shadow: 0 3px 16px rgb(0 0 0/20%);
    }

    .card__informations {
      border: 3px solid transparent;

      &:hover {
        transform: scale(1.01);
      }
    }
  }
`;
