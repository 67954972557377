export function formatMoney(value) {
  if (value) {
    value = Number(value);
    const formattedValue = value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return formattedValue;
  }
  return '';
}
