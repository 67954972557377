import styled, { css } from 'styled-components';

export const Main = styled.main`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin-bottom: 50px;
  gap: 150px;
  .profile {
    font-weight: 700;
  }
  div {
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Aller-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    color: var(--dark03);
    text-transform: capitalize;
    cursor: pointer;
  }

  @media (max-width: 720px) {
    gap: 10px;
    justify-content: space-around;
    div {
      font-size: 18px;
      line-height: 19px;
    }
  }
`;

export const Option = styled.div`
  :last-child {
    border: 7px solid transparent;
    height: 0;
    box-shadow: none;
    ${(props) =>
      props.selected &&
      css`
        background-color: var(--green80);
        border: 7px solid var(--green80);
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05);
      `}
  }
`;
