import styled from 'styled-components';

export const Box = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @keyframes fade {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  h1,
  h2,
  p {
    font-family: 'Aller-Regular';
    font-style: normal;
  }

  h2,
  p {
    animation-name: fade;
    animation-duration: 2s;
    animation-direction: 'alternative';
  }

  h1 {
    position: absolute;
    width: 47px;
    height: 27px;
    left: 20px;
    top: 44px;

    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    color: #030303;
  }

  h2 {
    width: 295px;
    height: 54px;

    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    text-align: center;

    color: #030303;

    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    z-index: 1;
  }

  p {
    width: 295px;
    height: 66px;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */
    display: flex;
    align-items: center;
    text-align: center;

    color: #000000;

    /* Inside auto layout */
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
    z-index: 2;
  }
`;

export const LoadingBox = styled.div`
  padding: 0 20px;
  margin: 0 auto 20px auto;
`;

export const Arrow = styled.img`
  @keyframes pulse {
    0% {
      bottom: 100px;
    }
    100% {
      bottom: 106px;
    }
  }

  position: absolute;
  bottom: 106px;
  right: 43px;
  animation: pulse 0.5s infinite;
  animation-fill-mode: backwards;
  animation-direction: alternate;
`;
