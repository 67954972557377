import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TooltipClipboard from '../../components/TooltipClipboard';
import Skeleton from 'react-loading-skeleton';
import logoutUserAutomatically from '../../utils/logoutUserAutomatically';

import './skeleton.css';

import api from '../../services/api';

import Header from '../../components/Header';
import Content from '../../components/Content';
import CheckIfClassCompleted from '../../components/CheckIfClassCompleted';
import NextClass from './components/NextClass';
import ListCard from './components/ListCard';
import Footer from '../../components/Footer';
import LoadModal from '../../components/LoadModal';
import NoAccessModal from '../../components/NoAccessModal';
import Scheduling from '../../components/Scheduling';
import ListComments from './components/ListComments';
import FavoriteButton from '../../components/FavoriteButton';
import WarningCookie from '../../components/WarningCookie';

import share from '../../assets/serie/share.png';
import time from '../../assets/serie/time.png';
import level from '../../assets/serie/level.png';
import intention from '../../assets/serie/intention.png';
import calendar from '../../assets/class/calendar.png';

import {
  Container,
  GradientBackground,
  Video,
  Thumbnail,
  Row,
  Description,
  TitleContainer,
  Divider,
  DataAboutLesson,
  RowTitle,
  ScheduleClassButton,
  MenuLessonsAndComments,
  Item,
  ContainerAudioPlayer,
  ContainerVideoPlayer,
  MenuMobile,
  Spinner,
  ShowMoreLessons,
} from './styles';
import Slider from 'react-slick';
import { checkIfUserHasAnActivePlan } from '../../utils/checkIfUserHasAnActivePlan';

const Class = (props) => {
  const user = useSelector((state) => state.User);
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();

  const { pathname } = location;

  const dispatch = useDispatch();

  const [showLoadModal, setShowLoadModal] = useState(false);
  const [showLoadModalNextLessons, setShowLoadModalNextLessons] =
    useState(false);
  const vimeoVideoParameters = '?title=0&byline=0&portrait=0';
  const [lessonInformations, setLessonInformations] = useState({
    name: '',
    description: '',
    thumbnail: '',
    theme: '',
    post_id: '',
    filters: [],
    post: {
      id: '',
      lesson_history: [{ completed: false }],
    },
  });
  const [lessonsOfSerie, setLessonsOfSerie] = useState([]);
  const [showNotAccessModal, setShowNoAccessModal] = useState(false);
  const [showTooltipClipboard, setShowTooltipClipboard] = useState(false);
  const [showSkeletonVideo, setShowSkeletonVideo] = useState(true);
  const [showSchedulingComponent, setShowSchedulingComponent] = useState(false);
  const [chosenMenu, setChosenMenu] = useState('Lessons');
  const [videoFinish, setVideoFinish] = useState(false);
  const [firstViewVideo, setFirstViewVideo] = useState(true);
  const [nextClassAutomatically, setNextClassAutomatically] = useState(false);
  const [serieID, setSerieID] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [showLoadNextLessons, setShowLoadNextLessons] = useState(false);
  const [canAccessPaidContent, setCanAccessPaidContent] = useState(false);

  const checkIfClassIsCompleted = async () => {
    let route = '';
    if (pathname.includes('podcast') || pathname.includes('meditacao')) {
      route = '/episodes';
    } else {
      route = '/lessons';
    }

    let completed = true;
    await api.get(`${route}/${lessonInformations.id}`).then((response) => {
      completed =
        response.data.post.lesson_history.length === 0
          ? false
          : response.data.post.lesson_history[0].completed;
      return completed;
    });

    return completed;
  };

  const handleOnEndedVideoAndPodcast = async () => {
    if (user.id.trim() !== '' && user.accessToken.trim() !== '') {
      // setShowLoadModal(true);
      const completed = await checkIfClassIsCompleted();

      if (!completed) {
        let route = '';
        if (pathname.includes('podcast') || pathname.includes('meditacao')) {
          route = '/episodes';
        } else {
          route = '/lessons';
        }

        api
          .post(`${route}/${lessonInformations.post.id}/history`, {
            completed: true,
          })
          .then((response) => setVideoFinish(true));
      }
      // setShowLoadModal(false);

      if (nextClassAutomatically) {
        checkIfClassHaveNextLesson();
      }
    }
  };

  const handleOnPlayVideo = async () => {
    if (firstViewVideo) {
      if (pathname.includes('podcast') || pathname.includes('meditacao')) {
        api.post(`/episode-views/${lessonInformations.id}`);
      } else {
        api.post(`/lesson-views/${lessonInformations.id}`);
      }

      setFirstViewVideo(false);
      castSrc();
    } else {
      stopSrc();
    }
  };

  const checkIfClassHaveNextLesson = () => {
    let nextLesson = false;
    let haveNextLesson = false;

    let lessons = lessonsOfSerie;

    lessons.map((item) => {
      if (nextLesson) {
        nextLesson = false;
        haveNextLesson = true;

        let route = '';
        if (pathname.includes('podcast')) {
          route = `/podcast/aula/${item.id}`;
        } else if (pathname.includes('meditacao')) {
          route = `/meditacao/aula/${item.id}`;
        } else {
          route = `/aula/${item.id}`;
        }

        history.push(route);
      }

      if (item.id === lessonInformations.id) {
        nextLesson = true;
      }
    });

    return false;
  };

  function getEndpointLessonInformations() {
    if (pathname.includes('podcast') || pathname.includes('meditacao')) {
      return `/episodes/${id}`;
    } else {
      return `/lessons/${id}`;
    }
  }

  function getEndpointSerieLessons(serieID, addPage = false) {
    let pagination = page;
    if (addPage) {
      pagination++;
    }

    if (pathname.includes('podcast') || pathname.includes('meditacao')) {
      return `/podcast-series/${serieID}/episodes/published?limit=10000&page=${pagination}`;
    } else {
      return `/series/${serieID}/lessons/published?limit=10000&page=${pagination}`;
    }
  }

  const getAutoPlayStatusUser = async () => {
    await api
      .get(`/users/${user.id}`)
      .then((response) => setNextClassAutomatically(response.data.autoplay))
      .catch((error) => setNextClassAutomatically(false));
  };

  const loadLessonInformations = async () => {
    setShowLoadModal(true);

    await getAutoPlayStatusUser();

    let serieID;
    try {
      const endpoint = getEndpointLessonInformations();
      const response = await api.get(endpoint);
      setLessonInformations({ ...response.data });

      if (pathname.includes('podcast') || pathname.includes('meditacao')) {
        serieID = response.data.podcast_id;
      } else {
        serieID = response.data.series_id;
      }

      setSerieID(serieID);
    } catch (error) {
      history.push('/');
      return;
    }

    setShowLoadModal(false);
    setShowLoadModalNextLessons(true);

    try {
      const endpoint = getEndpointSerieLessons(serieID);
      const response = await api.get(endpoint);
      if (pathname.includes('podcast') || pathname.includes('meditacao')) {
        setLessonsOfSerie([...lessonsOfSerie, ...response.data.episodes]);
      } else {
        setLessonsOfSerie([...lessonsOfSerie, ...response.data.lessons]);
      }
      setTotal(response.data.total);
    } catch (error) {
      history.push('/');
      return;
    }

    setShowLoadModalNextLessons(false);
  };

  const showMoreLessons = async () => {
    setShowLoadNextLessons(true);
    setPage(page + 1);
    try {
      const endpoint = getEndpointSerieLessons(serieID, true);
      const response = await api.get(endpoint);

      if (pathname.includes('podcast') || pathname.includes('meditacao')) {
        setLessonsOfSerie([...lessonsOfSerie, ...response.data.episodes]);
      } else {
        setLessonsOfSerie([...lessonsOfSerie, ...response.data.lessons]);
      }
    } catch (error) {
      history.push('/');
      return;
    }

    setShowLoadNextLessons(false);
  };

  const handleClickThumbnailPodcastMeditation = () => {
    const myAudio = document.getElementById('podcast-audio');
    if (myAudio.paused) {
      myAudio.play();
    } else {
      myAudio.pause();
    }
  };

  function renderVideo() {
    if (!canAccessPaidContent) return null;
    if (
      lessonInformations.url === null ||
      lessonInformations.url === '' ||
      lessonInformations.url === undefined
    ) {
      return (
        <>
          <Thumbnail
            onLoad={() => setShowSkeletonVideo(false)}
            showSkeletonVideo={showSkeletonVideo}
            src={lessonInformations.thumbnail_url}
          />
        </>
      );
    }

    if (pathname.includes('podcast') || pathname.includes('meditacao')) {
      const startIndex = lessonInformations.url.lastIndexOf('/') + 1;
      const finishIndex = lessonInformations.url.length;
      const episodeKey = lessonInformations.url.substring(
        startIndex,
        finishIndex,
      );

      if (lessonInformations.url.indexOf('spreaker') >= 0) {
        const link = `https://widget.spreaker.com/player?episode_key=${episodeKey}&theme=light&playlist=false&playlist-continuous=false&autoplay=false&live-autoplay=false&chapters-image=true&episode_image_position=right&hide-logo=false&hide-likes=false&hide-comments=false&hide-sharing=false&hide-download=true`;

        return (
          <>
            <Video
              onlyAudio={true}
              showSkeletonVideo={showSkeletonVideo}
              src={link}
              frameborder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
              onLoad={() => setShowSkeletonVideo(false)}
            />
          </>
        );
      } else {
        return (
          <ContainerAudioPlayer>
            <Thumbnail
              onLoad={() => setShowSkeletonVideo(false)}
              showSkeletonVideo={showSkeletonVideo}
              onClick={() => handleClickThumbnailPodcastMeditation()}
              src={lessonInformations.thumbnail_url}
            />
            <audio
              id="podcast-audio"
              onEnded={handleOnEndedVideoAndPodcast}
              controls
              controlsList="nodownload"
              onPlay={() => handleOnPlayVideo()}
            >
              <source src={lessonInformations.audio_url} type="audio/mpeg" />
            </audio>
          </ContainerAudioPlayer>
        );
      }
    }

    if (lessonInformations.url.indexOf('external') >= 0) {
      return (
        <ContainerVideoPlayer showSkeletonVideo={showSkeletonVideo}>
          <video
            onEnded={handleOnEndedVideoAndPodcast}
            onPlay={handleOnPlayVideo}
            onPause={stopSrc}
            controls
            controlsList="nodownload"
          >
            <source src={lessonInformations.url} type="video/mp4" />
          </video>
        </ContainerVideoPlayer>
      );
    }

    return (
      <>
        <Video
          onlyAudio={false}
          showSkeletonVideo={showSkeletonVideo}
          src={`${lessonInformations.url}${vimeoVideoParameters}`}
          frameborder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          onLoad={() => setShowSkeletonVideo(false)}
        />
      </>
    );
  }

  function renderNoAccessModal() {
    if (setShowNoAccessModal) {
      return (
        <NoAccessModal
          show={showNotAccessModal}
          setShow={setShowNoAccessModal}
        />
      );
    }
  }

  function renderFilters() {
    if (lessonInformations.filters.length > 0) {
      let valid = false;
      for (let filter of lessonInformations.filters) {
        if (
          filter.filter_category.name === 'Nível' ||
          filter.filter_category.name === 'Duração' ||
          filter.filter_category.name === 'Intenção'
        ) {
          valid = true;
          break;
        }
      }
      if (!valid) {
        return;
      }
      return (
        <>
          <Divider />
          <DataAboutLesson>
            <ul>
              {lessonInformations.filters.map((filter) => {
                if (filter.filter_category.name === 'Nivel') {
                  return (
                    <li>
                      <img src={level} alt="Ícone de nível da série" />
                      <span>Nível: {filter.name}</span>
                    </li>
                  );
                }
                if (filter.filter_category.name === 'Duração') {
                  return (
                    <li>
                      <img
                        src={time}
                        alt="Ícone de duração em horas da série"
                      />
                      <span>Duração: {filter.name}</span>
                    </li>
                  );
                }
                if (filter.filter_category.name === 'Intenção') {
                  return (
                    <li>
                      <img src={intention} alt="Ícone de intenção da série" />
                      <span>Intenção:{filter.name}</span>
                    </li>
                  );
                }
              })}
            </ul>
          </DataAboutLesson>
          <Divider />
        </>
      );
    }
  }

  function copyToClipboard() {
    const link = `${window.location.origin}/#/aula/${id}`;
    navigator.clipboard.writeText(link).then(function () {
      setShowTooltipClipboard(true);
      setTimeout(function () {
        setShowTooltipClipboard(false);
      }, 2000);
    });
  }

  function renderTitle() {
    let completed =
      lessonInformations.post.lesson_history.length === 0
        ? false
        : lessonInformations.post.lesson_history[0].completed;
    return (
      <RowTitle>
        <CheckIfClassCompleted
          videoFinish={videoFinish}
          completed={completed}
          post_id={lessonInformations.post.id}
          setShowLoadModal={setShowLoadModal}
          canAccessPaidContent={canAccessPaidContent}
        />
        <h1>{lessonInformations.name}</h1>
      </RowTitle>
    );
  }

  function renderDuration() {
    const { duration } = lessonInformations;
    if (duration > 0) {
      if (duration < 60) {
        return (
          <div>
            <img src={time} alt="Ícone de tempo da aula" />
            <span>{duration} min</span>
          </div>
        );
      } else {
        const hours = Math.trunc(duration / 60);
        const minutes = Math.trunc(duration - 60 * hours);
        return (
          <div>
            <img src={time} alt="Ícone de tempo da aula" />
            <span>
              {hours} {hours === 1 ? 'hora' : 'horas'}
              {minutes > 0 && ` e ${minutes} minutos`}
            </span>
          </div>
        );
      }
    }
  }

  function renderDescription() {
    return <p>{lessonInformations.description}</p>;
  }

  function renderListClasses() {
    return (
      <>
        <ListCard
          currentLesson={lessonInformations}
          nextLessons={lessonsOfSerie}
          pathname={pathname}
          setShowLoadModal={setShowLoadModal}
          setShowNoAccessModal={setShowNoAccessModal}
          canAccessPaidContent={canAccessPaidContent}
        />
        {renderShowMoreLessons()}
      </>
    );
  }

  function renderShowMoreLessons() {
    if (showLoadNextLessons) {
      return <Spinner />;
    }

    if (lessonsOfSerie.length < total) {
      return (
        <ShowMoreLessons onClick={() => showMoreLessons()}>
          Carregar mais aulas
        </ShowMoreLessons>
      );
    }
  }

  function renderNextClass() {
    return (
      <NextClass
        currentLesson={lessonInformations}
        lessonsOfSerie={lessonsOfSerie}
        pathname={pathname}
        setShowLoadModal={setShowLoadModal}
        setShowNoAccessModal={setShowNoAccessModal}
        canAccessPaidContent={canAccessPaidContent}
      />
    );
  }

  function renderSchedulingComponent() {
    if (showSchedulingComponent) {
      return (
        <Scheduling
          type="aula"
          show={showSchedulingComponent}
          setShow={setShowSchedulingComponent}
          postId={lessonInformations.post_id}
        />
      );
    }
  }

  const handleSchedule = () => {
    if (canAccessPaidContent) setShowSchedulingComponent(true);
    else setShowNoAccessModal(true);
  };

  function renderLessonsOrComments() {
    if (chosenMenu === 'Lessons') {
      if (showLoadModalNextLessons) {
        return <Spinner />;
      }
      return (
        <>
          {renderNextClass()}
          <Content>{renderListClasses()}</Content>
        </>
      );
    }

    let route;
    if (pathname.includes('podcast') || pathname.includes('meditacao')) {
      route = `/episodes`;
    } else {
      route = `/lessons`;
    }

    return (
      <ListComments lessonInformations={lessonInformations} route={route} />
    );
  }

  function initializeCastPlayer() {
    var options = {};

    //window.chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID

    options.receiverApplicationId = 'CC1AD845';
    options.autoJoinPolicy = window.chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED;
    options.androidReceiverCompatible = true;
    window.cast.framework.CastContext.getInstance().setOptions(options);

    const remotePlayer = new window.cast.framework.RemotePlayer();
    const remotePlayerController =
      new window.cast.framework.RemotePlayerController(remotePlayer);

    remotePlayerController.addEventListener(
      window.cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
      function (e) {
        var isConnected = e.value;
      },
    );
  }

  function castSrc() {
    let mediaInfo = new window.chrome.cast.media.MediaInfo(1, 'video/mp4');
    mediaInfo.contentUrl = lessonInformations?.url;

    mediaInfo.streamType = window.chrome.cast.media.StreamType.LIVE;
    mediaInfo.metadata = new window.chrome.cast.media.TvShowMediaMetadata();
    mediaInfo.metadata.title = lessonInformations?.name;

    let request = new window.chrome.cast.media.LoadRequest(mediaInfo);
    request.autoplay = true;
    var session =
      window.cast.framework.CastContext.getInstance().getCurrentSession();
    if (session) {
      session
        .loadMedia(request)
        .then(() => {})
        .catch((e) => {});
    } else {
    }
  }

  function stopSrc() {
    var session =
      window.cast.framework.CastContext.getInstance().getCurrentSession();
    if (session) {
      var player = new window.cast.framework.RemotePlayer();
      var playerController = new window.cast.framework.RemotePlayerController(
        player,
      );
      playerController.playOrPause();
    }
  }

  const validateAccess = async () => {
    const userHasAccess = await checkIfUserHasAnActivePlan();
    setCanAccessPaidContent(userHasAccess);
    setShowNoAccessModal(!userHasAccess);
  };

  useEffect(() => {
    validateAccess();
    if (id !== '' && id !== undefined) {
      setLessonInformations({
        name: '',
        description: '',
        thumbnail: '',
        theme: '',
        post_id: '',
        filters: [],
        post: {
          id: '',
          lesson_history: [{ completed: false }],
        },
      });

      setLessonsOfSerie([]);
      setFirstViewVideo(true);

      loadLessonInformations();
    }
    window.scrollTo(0, 0);

    if (logoutUserAutomatically()) {
      dispatch({ type: 'yoga-adm/user/logout' });
      history.push('/login');
    }
  }, [id]);

  function renderChromecastLauncher() {
    if (!pathname.includes('podcast') && !pathname.includes('meditacao')) {
      return <google-cast-launcher></google-cast-launcher>;
    }
  }

  return (
    <Container>
      {/* <Header /> */}
      {/* <GradientBackground /> */}
      <Content>
        {renderVideo()}
        <Description>
          <TitleContainer>
            <div>
              {renderTitle()}
              <MenuMobile>
                <CheckIfClassCompleted
                  videoFinish={videoFinish}
                  completed={
                    lessonInformations.post.lesson_history.length === 0
                      ? false
                      : lessonInformations.post.lesson_history[0].completed
                  }
                  post_id={lessonInformations.post.id}
                  setShowLoadModal={setShowLoadModal}
                />
                <FavoriteButton
                  post_id={lessonInformations.post_id}
                  is_favorite={lessonInformations.is_favorite}
                  favorite_id={lessonInformations.favorite_id}
                  setShowLoadModal={setShowLoadModal}
                  canAccessPaidContent={canAccessPaidContent}
                />
                <li onClick={() => copyToClipboard()}>
                  <img src={share} alt="Ícone de compartilhar série" />
                  <TooltipClipboard showTooltip={showTooltipClipboard}>
                    Aula copiada
                  </TooltipClipboard>
                </li>
              </MenuMobile>
              <ul>
                <li>
                  <FavoriteButton
                    post_id={lessonInformations.post_id}
                    is_favorite={lessonInformations.is_favorite}
                    favorite_id={lessonInformations.favorite_id}
                    setShowLoadModal={setShowLoadModal}
                    canAccessPaidContent={canAccessPaidContent}
                  />
                </li>
                <li onClick={() => copyToClipboard()}>
                  <img src={share} alt="Ícone de compartilhar série" />
                  <TooltipClipboard showTooltip={showTooltipClipboard}>
                    Aula copiada
                  </TooltipClipboard>
                </li>
                <li>{renderDuration()}</li>
              </ul>
            </div>
            {/* <IconTV src={iconTV} alt="Ícone de TV" /> */}
            {renderChromecastLauncher()}
          </TitleContainer>
          <Row>
            {renderDescription()}
            <ScheduleClassButton onClick={() => handleSchedule()}>
              <img src={calendar} alt="Ícone de calendário" />
              Agendar aula
            </ScheduleClassButton>
          </Row>
          {renderFilters()}
        </Description>
        <MenuLessonsAndComments>
          <Item
            chosenMenu={chosenMenu === 'Lessons'}
            onClick={() => setChosenMenu('Lessons')}
          >
            Aulas<div></div>
          </Item>
          <Item
            chosenMenu={chosenMenu === 'Comments'}
            onClick={() => setChosenMenu('Comments')}
          >
            Comentários<div></div>
          </Item>
        </MenuLessonsAndComments>
      </Content>
      {renderLessonsOrComments()}
      {/* <Footer /> */}
      <LoadModal show={showLoadModal} />
      {renderNoAccessModal()}
      {renderSchedulingComponent()}
      <WarningCookie />
    </Container>
  );
};

export default Class;
