import styled from 'styled-components';

export const Container = styled.section``;

export const Row = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 1040px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const AddNote = styled.div`
  position: relative;
  margin-right: 80px;
  cursor: pointer;
  > img {
    width: 150px;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Aller-Light';
    font-size: 4rem;
    color: #fff;
    position: absolute;
    bottom: 0px;
    right: -20px;
    height: 60px;
    width: 60px;
    border-radius: 100%;
    background-color: ${({ diaryColor }) => {
      if (diaryColor === 'green') {
        return 'var(--dark03)';
      } else if (diaryColor === 'yellow') {
        return '#FF9999';
      } else if (diaryColor === 'purple') {
        return '#8430CA';
      }
      return '#fff';
    }};
  }

  @media (max-width: 1040px) {
    margin-right: 0px;
    margin-bottom: 40px;
  }
`;

export const SearchContainer = styled.div`
  > div:nth-of-type(1) {
    margin-top: -20px;
    width: 620px;
  }

  @media (max-width: 1040px) {
    width: 100%;

    > div:nth-of-type(1) {
      width: 100%;
    }
  }
`;

export const Title = styled.h1`
  font-family: 'Bartolomeo-Regular';
  font-size: 6rem;
  color: #fff;
  position: relative;
  width: fit-content;
  span {
    font-family: 'Aller-Bold';
    font-size: 3rem;
    position: absolute;
    left: 35%;
    top: 0px;
  }

  @media (max-width: 1040px) {
    color: ${(props) => {
      if (props.diaryColor === 'green') {
        return 'var(--dark03)';
      } else if (props.diaryColor === 'yellow') {
        return '#FF9999';
      } else {
        return '#6818AA';
      }
    }};
  }
`;

export const GreenBackground = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 500px;
  z-index: -1;
  background: linear-gradient(
    180deg,
    rgba(97, 225, 113, 1) 0%,
    rgba(248, 251, 246, 1) 70%,
    rgba(255, 255, 255, 1) 80%
  );
`;
export const YellowBackground = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 500px;
  z-index: -1;
  background: linear-gradient(
    180deg,
    rgba(250, 207, 88, 1) 0%,
    rgba(248, 251, 246, 1) 70%,
    rgba(255, 255, 255, 1) 80%
  );
`;

export const PurpleBackground = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 500px;
  z-index: -1;
  background: linear-gradient(
    180deg,
    rgba(132, 48, 202, 1) 0%,
    rgba(248, 251, 246, 1) 70%,
    rgba(255, 255, 255, 1) 80%
  );
`;
