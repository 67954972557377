import styled from 'styled-components';

export const Container = styled.div`
  input[type='radio'] {
    cursor: pointer;
  }

  > div {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 1260px) {
    > div {
      flex-direction: column;
      text-align: center;
    }
  }
`;

export const GradientBackground = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 300px;
  z-index: -1;
  background: rgb(97, 225, 113);
  background: linear-gradient(
    180deg,
    rgba(97, 225, 113, 1) 0%,
    rgba(248, 251, 246, 1) 70%,
    rgba(255, 255, 255, 1) 80%
  );
`;

export const PlanContainer = styled.div`
  > span {
    font-family: 'Aller-Bold';
    font-size: 1.4rem;
    color: #b4b4b4;
    cursor: pointer;
    display: block;
    text-align: center;
  }

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const PlanDescription = styled.div`
  margin-top: 20px;
  width: 500px;

  > div {
    font-family: 'Aller-Bold';
    font-size: 2rem;
    color: var(--dark03);
  }

  > p {
    font-family: 'Aller-Light';
    font-size: 1.6rem;
    color: #5c5c5c;
  }

  > label {
    font-family: 'Aller-Regular';
    font-size: 1.4rem;
    color: var(--dark03);
    margin-left: 10px;
  }

  @media (max-width: 1260px) {
    margin-top: 40px;
    width: 100%;
  }
`;

export const Form = styled.form`
  width: 100%;
  margin-left: 100px;

  > div:nth-of-type(2) {
    width: 79%;
    background-color: #eef0f1;

    > input {
      background-color: #eef0f1;
    }
  }

  > div:nth-of-type(4) {
    width: 64%;
    background-color: #eef0f1;

    > input {
      background-color: #eef0f1;
    }
  }

  > button {
    font-family: 'Aller-Regular';
    font-size: 1.4rem;
    margin-top: 40px;
  }

  @media (max-width: 1260px) {
    width: 100%;
    margin-top: 20px;
  }

  @media (max-width: 1260px) {
    margin-left: 0%;

    > div {
      width: 100%;
    }

    > div:nth-of-type(2) {
      width: 100%;
    }

    > div:nth-of-type(4) {
      width: 100%;
    }

    > button {
      width: 100%;
    }
  }
`;

export const InputRow = styled.div`
  display: flex;
  width: 100%;

  > div:nth-of-type(1) {
    width: 54%;
    background-color: #eef0f1;
    > input {
      background-color: #eef0f1;
    }
  }

  > div:nth-of-type(2) {
    width: 20%;
    margin-left: 28px;
    background-color: #eef0f1;
    > input {
      background-color: #eef0f1;
    }
  }

  @media (max-width: 1260px) {
    flex-direction: column;

    > div {
      width: 100%;
    }

    > div:nth-of-type(1) {
      width: 100%;
    }

    > div:nth-of-type(2) {
      width: 100%;
      margin-left: 0px;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  height: fit-content;

  img {
    height: 130px;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;

    span {
      display: block;
    }

    span:nth-of-type(1) {
      font-family: 'Bartolomeo-Regular';
      font-size: 3rem;
      color: var(--dark03);
    }

    span:nth-of-type(2) {
      font-family: 'Aquawax-Regular';
      font-size: 2rem;
      color: #707070;
    }
  }

  @media (max-width: 1260px) {
    margin: 0 auto;
    width: fit-content;
  }
`;

export const ChoosePaymentMethod = styled.div`
  > p {
    font-family: 'Aller-Bold';
    font-size: 2rem;
    color: var(--dark03);
    margin-top: 20px;
  }

  > button {
    background-color: #19b441;
    border-radius: 40px;
    font-family: 'Aller-Regular';
    height: 70px;
  }

  @media (max-width: 1260px) {
    > button {
      margin: 20px auto;
    }
  }

  @media (max-width: 460px) {
    > button {
      width: 100%;
    }
  }
`;
