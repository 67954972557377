import Store from '../redux';
import axios from '../services/api';

import { useHistory } from 'react-router-dom';

async function refreshToken() {
  return new Promise((resolve, reject) => {
    try {
      const refreshToken = Store.getState().User.refreshToken;
      axios
        .put('/sessions/refresh-token', {
          refreshToken,
        })
        .then((res) => {
          Store.dispatch({
            type: 'yoga-web/user/get-new-token',
            payload: res.data,
          });
          window.location.reload();
          return res;
        })
        .catch((err) => {
          Store.dispatch({
            type: 'yoga-adm/user/logout',
          });
          window.location.reload();
          return err;
        });
    } catch (err) {
      return err;
    }
  });
}

export default refreshToken;
