import { Button, Spinner } from './styles';

const ButtonLoading = ({
  label = 'Ok',
  type = 'submit',
  disabled = false,
  loading,
  width = 'auto',
  backgroundColor = '#8430CA',
  styles = {},
}) => {
  return (
    <Button
      type={type}
      disabled={disabled}
      width={width}
      backgroundColor={backgroundColor}
      className={styles}
    >
      {loading ? <Spinner /> : label}
    </Button>
  );
};

export default ButtonLoading;
