import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';

import { Message } from './styles';

const LoadModal = ({
  show,
  setShow,
  message,
  confirmAction,
  invertedButtonColorModal,
}) => {
  const handleConfirmAction = () => {
    setShow(false);
    confirmAction();
  };

  function renderButtons() {
    if (invertedButtonColorModal) {
      return (
        <>
          <Button onClick={() => handleConfirmAction()} variant="danger">
            Sim
          </Button>
          <Button onClick={() => setShow(false)} variant="success">
            Não
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button onClick={() => handleConfirmAction()} variant="success">
            Sim
          </Button>
          <Button onClick={() => setShow(false)} variant="danger">
            Não
          </Button>
        </>
      );
    }
  }

  return (
    <Modal show={show} backdrop="static" keyboard={false} centered>
      <Modal.Body>
        <Message>{message}</Message>
      </Modal.Body>
      <Modal.Footer>{renderButtons()}</Modal.Footer>
    </Modal>
  );
};

export default LoadModal;
