import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';

import Description from './components/Description';

import podcast from '../../../../../../assets/home/podcast.png';

import './carousel.css';

import { ContainerCard, Card, CardSkeleton } from './styles';

const Slider3D = ({
  route,
  lessons,
  loading,
  podcastOrMeditation = false,
  type = '',
  search,
}) => {
  const history = useHistory();

  const sliderSettings = {
    className: 'center',
    centerMode: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const formatString = (value) => {
    let newValue = value.toUpperCase();
    newValue = newValue.replace(/[ÀÁÂÃÄÅ]/, 'A');
    newValue = newValue.replace(/[ÈÉÊË]/, 'E');
    newValue = newValue.replace(/[ÌÍÎÏ]/, 'I');
    newValue = newValue.replace(/[ÒÓÕÔÖ]/, 'O');
    newValue = newValue.replace(/[ÙÚÛÜ]/, 'U');
    newValue = newValue.replace(/[Ç]/, 'C');
    return newValue;
  };

  const onLoadCard = (item) => {
    let id = '';

    if (type === 'episode') {
      id = item.post.episode.id;
    }

    let cards = document.querySelectorAll('img#card-' + id);
    for (let card of cards) {
      card.style = 'opacity: 1; height: auto;';
    }

    let items = document.querySelectorAll('div#skeleton-' + id);
    for (let card of items) {
      card.style = 'display: none !important';
    }
  };

  function renderSlider() {
    let listCards;

    if (loading) {
      listCards = Array(4)
        .fill()
        .map(() => <CardSkeleton />);
    } else {
      listCards = lessons.map((item) => {
        let id = '';
        let thumbnail_url = '';
        let name = '';
        if (type === 'episode') {
          id = item.post.episode.id;
          thumbnail_url = item.post.episode.thumbnail_url;
          name = item.post.episode.name;
        }

        const searched = formatString(search);
        const nameFormatted = formatString(name);

        if (searched.trim() !== '' && nameFormatted.indexOf(searched) < 0) {
          return;
        }

        return (
          <div>
            <ContainerCard>
              <Card
                podcastOrMeditation={podcastOrMeditation}
                id={'card-' + id}
                onClick={() =>
                  history.push({
                    pathname: `/${route}/${id}`,
                  })
                }
                src={thumbnail_url}
                alt={`${name} - Thumbnail da ${route}`}
                onLoad={() => onLoadCard(item)}
              />
              <Description
                route={`/${route}/${id}`}
                podcastOrMeditation={podcastOrMeditation}
              />
            </ContainerCard>
            <div id={`skeleton-${id}`}>
              <CardSkeleton />
            </div>
          </div>
        );
      });
    }

    if (lessons.length > 3 || loading) {
      return <Slider {...sliderSettings}>{listCards}</Slider>;
    } else if (lessons.length > 1) {
      return (
        <Slider {...sliderSettings}>
          {listCards}
          {listCards}
        </Slider>
      );
    } else {
      return (
        <Slider {...sliderSettings}>
          {listCards}
          {listCards}
          {listCards}
          {listCards}
        </Slider>
      );
    }
  }

  return <>{renderSlider()}</>;
};

export default Slider3D;
