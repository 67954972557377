import styled from 'styled-components';

export const Container = styled.div`
  margin: 30px;
  cursor: pointer;
`;

export const DateComponent = styled.div`
  font-family: 'Aller-Regular';
  font-size: 1.2rem;
  color: #5c5c5c;

  span {
    font-family: 'Aller-Bold';
  }
`;

export const Note = styled.div`
  width: 260px;
  height: 260px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px lightgrey;
  padding: 20px;
  margin-top: 20px;
`;

export const Title = styled.div`
  font-family: 'Aller-Regular';
  font-size: 1.6rem;
  color: var(--dark03);
`;

export const Description = styled.div`
  font-family: 'Aller-Light';
  font-size: 1rem;
  color: #5c5c5c;
  margin-top: 10px;
`;
