import Button from '../../../../components/Button';
import Card from './components/Card/Card';

import bankSlip from '../../../../assets/profile/bank-slip.png';
import creditCards from '../../../../assets/profile/credit-cards.png';

import { Container, CreditCardBanners } from './_addCreditCard';

const AddCreditCard = ({
  informations,
  showFormDataSection,
  setShowFormDataSection,
}) => {
  const disabled = () => {
    return showFormDataSection === 'register' ? 'disabled' : '';
  };
  return (
    <Container className={disabled()}>
      <Card
        informations={informations}
        onClick={() => setShowFormDataSection('credit-card')}
      />

      <CreditCardBanners
        src={creditCards}
        alt="Bandeira da Visa, MasterCard, Elo e HiperCard"
      />

      <Button
        iconLeft={bankSlip}
        onClick={() => setShowFormDataSection('bankslip')}
      >
        Pagar por boleto bancário
      </Button>
    </Container>
  );
};

export default AddCreditCard;
