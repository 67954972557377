import { message, Modal } from 'antd';
import React from 'react';

import ButtonLoading from '../../../../../../components/ButtonLoading';
import InputMask from '../../../../../../components/InputMask';
import LoadModal from '../../../../../../components/LoadModal';
import api from '../../../../../../services/api';
import { apiErrorMessage } from '../../../../../../utils/apiErrorMessage';
import { apiResponse } from '../../../../../../utils/apiResponse';
import { formatMoney } from '../../../../../../utils/formatMoney';
import { onlyNumbers } from '../../../../../../utils/onlyNumbers';
import { validCPF } from '../../../../../../utils/validCPF';
import MyCards from '../../../MyCards';
import PlanCard from '../PlanCard';
import ApplyCoupon from './ApplyCoupon';
import { AddressForm, Box, CPFForm, Main, Option } from './styles';

class PaymentOptions extends React.Component {
  constructor(props) {
    super();
    this.props = props;
  }

  state = {
    selectedPaymentMethod: '',
    couponId: null,
    couponApplied: false,
    installments: [],
    modalAddressOpen: false,
    modalCPFOpen: false,
    address: {
      id: '',
      cep: '',
      street: '',
      number: '',
      neighborhood: '',
      complement: '',
      uf: '',
      city: '',
    },
    cpf: '',
    registeredCpf: false,
    registeredAddress: false,
    credit_card_token: '',
    months: 1,
    loading: false,
    finishing: false,
  };

  componentDidMount() {
    this.getUser();
    this.getPlanInformation();
  }

  validatePayment(subscription, signatureMade = true, messageError = '') {
    if (signatureMade && this.state.selectedPaymentMethod === 'credit_card')
      this.props.setStep(3);
    else if (
      !signatureMade &&
      this.state.selectedPaymentMethod === 'credit_card'
    ) {
      localStorage.setItem('messageError', JSON.stringify(messageError));
      this.props.setStep(4);
    } else if (
      subscription &&
      this.state.selectedPaymentMethod !== 'credit_card'
    ) {
      this.props.setPaymentInformation(subscription);
      this.props.setStep(2);
    }
  }

  validIsForm() {
    if (!this.state.selectedPaymentMethod || this.state.finishing) return false;
    if (this.state.selectedPaymentMethod === 'credit_card')
      return this.state.credit_card_token ? true : false;
    return true;
  }

  onSetMonths(value) {
    this.setState((state) => (state.months = Number(value)));
  }

  setAddress({ target }) {
    if (target.name === 'cep') {
      if (target.value.length === 9) {
        api
          .get(`https://viacep.com.br/ws/${target.value}/json/`)
          .then((response) => {
            apiResponse(response.data);
            const result = response.data;
            this.setState({
              ...this.state,
              address: {
                cep: onlyNumbers(result.cep),
                street: result.logradouro,
                neighborhood: result.bairro,
                complement: result.complemento,
                uf: result.uf,
                city: result.localidade,
              },
            });
          });
      }
    } else {
      this.setState((state) => (state.address[target.name] = target.value));
    }
  }

  onSetCreditCardToken(value) {
    this.setState((state) => (state.credit_card_token = value));
  }

  async getUser() {
    try {
      const user = apiResponse(
        await api.get(`users/${this.props.user.id}/?relations=true`),
      );
      if (user.addresses.length) {
        this.setState((state) => (state.address = user.addresses[0]));
        this.setState((state) => (state.registeredAddress = true));
      }
      if (user?.cpf) {
        this.setState((state) => (state.registeredCpf = true));
        this.setState((state) => (state.cpf = user.cpf));
      }
    } catch (error) {
      apiErrorMessage(error, true);
    }
  }

  resetPaymentMethod() {
    this.setState((state) => (state.selectedPaymentMethod = ''));
    this.closeModalAddress();
    this.closeModalCPF();
  }

  async storeAddress(event) {
    try {
      event.preventDefault();
      this.setState((state) => (state.loading = true));
      apiResponse(await api.post(`addresses/`, this.state.address));
      message.success('Endereço cadastrado com sucesso!');
      this.setState((state) => (state.loading = false));
      this.getUser();
      this.closeModalAddress();
      this.setState((state) => (state.registeredAddress = true));
      if (!this.state.registeredCpf) this.openModalCPF();
    } catch (error) {
      apiErrorMessage(error, true);
      this.setState((state) => (state.loading = false));
    }
  }

  async storeCPF(event) {
    try {
      event.preventDefault();
      this.setState((state) => (state.loading = true));
      const user = apiResponse(
        await api.patch(`users/cpf/`, { cpf: this.state.cpf }),
      );
      this.closeModalCPF();
      this.setState((state) => (state.user = user));
      this.setState((state) => (state.registeredCpf = true));
      this.setState((state) => (state.loading = false));
      message.success('CPF cadastrado com sucesso!');
    } catch (error) {
      this.setState((state) => (state.loading = false));
      apiErrorMessage(error, true);
    }
  }

  onSelectPaymentOption({ target }) {
    const selectedPaymentMethod = target.value;
    this.setState({ ...this.state, selectedPaymentMethod });
    if (
      selectedPaymentMethod === 'bank_slip' &&
      !this.state.registeredAddress === true
    )
      this.openModalAddress();
    else if (
      selectedPaymentMethod === 'bank_slip' &&
      !this.state.registeredCpf === true
    )
      this.openModalCPF();
  }

  onSetCouponId(id) {
    this.setState((state) => (state.couponId = id));
    this.getPlanInformation();
  }

  async getPlanInformation() {
    try {
      this.setState((state) => (state.loading = true));
      const hasCouponId = this.state.couponId;
      const standardFilter = {
        isVisible: true,
        addInstallment: true,
      };
      const searchParams = new URLSearchParams(standardFilter);
      if (hasCouponId) searchParams.append('coupom_id', hasCouponId);
      const { data: planTypes } = await api.get(
        `plan-types?${searchParams.toString()}`,
      );
      this.setState((state) => (state.loading = false));

      if (planTypes?.length)
        this.setState({
          ...this.state,
          installments: planTypes[0].installments,
        });

      if (hasCouponId) {
        this.setState((state) => (state.couponApplied = true));
        message.success('Cupom aplicado com sucesso!');
      }
    } catch (error) {
      apiErrorMessage(error, true);
      this.setState((state) => (state.loading = false));
    }
  }
  async checkout() {
    if (this.state.finishing) return;
    try {
      this.setState((state) => (state.finishing = true));
      let body = {
        payment: {
          payable_with: this.state?.selectedPaymentMethod,
          coupom_id: this.state?.couponId || null,
          plan_type_id: this.props?.planOnOffer?.id,
        },
        user: {
          name: this.props?.user?.name,
          email: this.props?.user?.email,
          birthday: this.props?.user?.birthday,
        },
      };
      if (this.state.selectedPaymentMethod === 'bank_slip') {
        const { cep, street, number, neighborhood, complement, uf, city } =
          this.state.address;
        const address = {
          cep,
          street,
          number,
          neighborhood,
          complement,
          uf,
          city,
        };
        body.address = address;
        body.user.cpf = this.state.cpf;
      } else if (this.state.selectedPaymentMethod === 'credit_card') {
        body.payment.customer_payment_method_id = this.state.credit_card_token;
        body.payment.months = this.state.months;
      }

      const response = apiResponse(
        await api.post('plans/without-recurrence', body),
      );
      this.setState((state) => (state.finishing = false));
      // this.loadCheckout(response);
      this.validatePayment(response);
    } catch (error) {
      this.setState((state) => (state.finishing = false));

      this.validatePayment({}, false, apiErrorMessage(error, false));

      apiErrorMessage(error, true);
    }
  }

  openModalAddress() {
    this.setState((state) => (state.modalAddressOpen = true));
  }

  closeModalAddress() {
    this.setState((state) => (state.modalAddressOpen = false));
  }

  openModalCPF() {
    this.setState((state) => (state.modalCPFOpen = true));
  }

  closeModalCPF() {
    this.setState((state) => (state.modalCPFOpen = false));
  }

  setCpf(event) {
    this.setState({
      ...this.state,
      cpf: onlyNumbers(event.target.value),
    });
  }

  render() {
    return (
      <Box>
        <PlanCard startOfRegistration={true} plan={this.props.planOnOffer} />
        <Main>
          {/* {JSON.stringify(this.state.credit_card_token)} */}
          {/* {JSON.stringify(this.state.installments)} */}
          {/* {JSON.stringify(this.props.planOnOffer.id)} */}
          {/* {JSON.stringify(this.state.months)} */}
          <h3>Escolha uma forma de pagamento</h3>
          <p>Adicione uma forma de pagamento entre as opções abaixo.</p>
          <div className="box-pament-options">
            <Option selected={this.state.selectedPaymentMethod === 'bank_slip'}>
              <input
                value="bank_slip"
                checked={this.state.selectedPaymentMethod === 'bank_slip'}
                onChange={(event) => this.onSelectPaymentOption(event)}
                type="radio"
                id="bank_slip"
              />
              <label htmlFor="bank_slip">Boleto bancário</label>
            </Option>

            <Option selected={this.state.selectedPaymentMethod === 'pix'}>
              <input
                value="pix"
                checked={this.state.selectedPaymentMethod === 'pix'}
                onChange={(event) => this.onSelectPaymentOption(event)}
                type="radio"
                id="pix"
              />
              <label htmlFor="pix">PIX</label>
            </Option>

            <Option
              selected={this.state.selectedPaymentMethod === 'credit_card'}
            >
              <input
                value="credit_card"
                checked={this.state.selectedPaymentMethod === 'credit_card'}
                onChange={(event) => this.onSelectPaymentOption(event)}
                type="radio"
                id="credit_card"
              />
              <label htmlFor="credit_card">Cartão de crédito</label>
            </Option>

            {this.state.selectedPaymentMethod === 'credit_card' && (
              <>
                <MyCards
                  setCreditCardToken={this.onSetCreditCardToken.bind(this)}
                />

                <div className="col-12 mb-3">
                  <label htmlFor="installments">Parcelas</label>
                  <select
                    id="installments"
                    onChange={({ target }) => this.onSetMonths(target.value)}
                  >
                    {this.state.installments.map((item, index) => (
                      <option value={item.installments} key={index}>
                        {item.installments} parcela{index > 0 ? 's' : ''} de{' '}
                        {formatMoney(item.installment_value)} (
                        {formatMoney(item.price)})
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )}
          </div>

          <ApplyCoupon
            setCouponId={this.onSetCouponId.bind(this)}
            couponApplied={this.state.couponApplied}
          />
          <center>
            <span onClick={() => this.checkout()}>
              <ButtonLoading
                label="Finalizar compra"
                disabled={!this.validIsForm()}
                loading={this.state.finishing}
              />
            </span>
          </center>

          <Modal
            closable={false}
            title="Cadastrar CPF"
            visible={this.state.modalCPFOpen}
            onOk={(event) => this.storeCPF(event)}
            onCancel={() => this.resetPaymentMethod()}
            footer={false}
          >
            <CPFForm onSubmit={(event) => this.storeCPF(event)}>
              <InputMask
                required
                type="text"
                mask="cpf"
                onInput={(event) => this.setCpf(event)}
              />
              {validCPF(this.state.cpf) === false && (
                <small>informe um CPF válido.</small>
              )}
              <div>
                <button type="submit">Cadastrar</button>
              </div>
            </CPFForm>
          </Modal>
          <Modal
            title="Cadastrar endereço"
            visible={this.state.modalAddressOpen}
            onCancel={() => this.resetPaymentMethod()}
            footer={false}
          >
            {/* <MegaAddressForm address={address} setAddress={setAddress} /> */}
            <AddressForm onSubmit={(event) => this.storeAddress(event)}>
              <InputMask
                required
                mask="cep"
                name="cep"
                placeholder="CEP"
                type="text"
                onInput={(event) => this.setAddress(event)}
              />
              <input
                required
                value={this.state.address.street}
                name="street"
                placeholder="Rua"
                onInput={(event) => this.setAddress(event)}
              />
              <input
                required
                value={this.state.address.number}
                name="number"
                placeholder="Número"
                type="number"
                onInput={(event) => this.setAddress(event)}
              />
              <input
                required
                value={this.state.address.neighborhood}
                name="neighborhood"
                placeholder="Bairro"
                onInput={(event) => this.setAddress(event)}
              />
              <input
                value={this.state.address.complement}
                name="complement"
                placeholder="Complemento"
                onInput={(event) => this.setAddress(event)}
              />
              <input
                required
                value={this.state.address.uf}
                name="uf"
                placeholder="Estado"
                onInput={(event) => this.setAddress(event)}
              />
              <input
                required
                value={this.state.address.city}
                name="city"
                placeholder="Cidade"
                onInput={(event) => this.setAddress(event)}
              />
              <div>
                <button type="submit">Cadastrar</button>
              </div>
            </AddressForm>
          </Modal>
        </Main>
        <LoadModal show={this.state.loading} />
      </Box>
    );
  }
}

export default PaymentOptions;
