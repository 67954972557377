import { useEffect, useState } from 'react';

import arrowSrc from './img/arrow.svg';
import loadingGIF from './img/loading.gif';
import { Arrow, Box, LoadingBox } from './styles';

const JivoChatToApp = () => {
  const [jivoInitialized, setJivoInitialized] = useState(false);

  const checkScript = () => {
    const script = document.getElementById('jivo-iframe-container');
    if (script) {
      clearInterval(intervalId);
      setJivoInitialized(true);
    }
  };

  let intervalId = setInterval(checkScript, 500);

  useEffect(() => {
    checkScript();
  }, []);

  const InitialMessage = () => {
    return (
      <div>
        <LoadingBox>
          <img src={loadingGIF} alt="Carregando Chat" />
        </LoadingBox>
        <h2>Logo estaremos prontos para te atender!</h2>
        <p>
          Aguarde até que o ícone da conversa apareça no canto inferior e toque
          nele para iniciar o atendimento.
        </p>
      </div>
    );
  };

  const FinalMessage = () => {
    return (
      <div>
        <h2>Estamos prontos para te atender!</h2>
        <p>Toque nele para iniciar o atendimento.</p>
        <Arrow src={arrowSrc} alt="Seta" />
      </div>
    );
  };

  return (
    <>
      <Box>
        <h1>Chat</h1>
        {jivoInitialized ? <FinalMessage /> : <InitialMessage />}
      </Box>
    </>
  );
};

export default JivoChatToApp;
