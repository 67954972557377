import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import defaultProfile from './icons/defaultProfile.png';
import help from './icons/help.svg';
import logOut from './icons/logOut.svg';
import srcMenu from './icons/menu.svg';
import settings from './icons/settings.svg';
import { ContentBox, HamburgerMenu, Icon, Options, UserPhoto } from './styles';

const Menu = () => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const hamburgerMenu = (
    <HamburgerMenu onClick={toggle} title="Menu" alt="Menu" src={srcMenu} />
  );
  const userPhoto = useSelector((state) => state.User.avatar_url);
  const isAuthenticated = useSelector((state) => state.User.accessToken);
  const dispatch = useDispatch();
  const history = useHistory();
  const [show, setShow] = useState(false);

  function toggle() {
    setShow(!show);
  }

  function logout() {
    dispatch({ type: 'yoga-adm/user/logout' });
    history.push('/login');
  }

  const menuItems = [
    {
      show: true,
      name: 'MEU PERFIL',
      path: 'perfil',
      icon: (
        <UserPhoto
          src={isAuthenticated ? userPhoto : defaultProfile}
          alt="MEU PERFIL"
        />
      ),
    },
    {
      show: true,
      name: 'CONFIGURAÇÕES',
      path: 'configuracao',
      icon: <Icon src={settings} alt="CONFIGURAÇÕES" />,
    },
    {
      show: true,
      name: 'AJUDA',
      path: 'contato',
      icon: <Icon src={help} alt="AJUDA" />,
    },
    {
      show: isAuthenticated,
      name: 'SAIR',
      path: '',
      icon: <Icon src={logOut} alt="SAIR" />,
    },
  ];

  function menu() {
    const validMenuItems = menuItems.filter((item) => item.show);

    const HTML = validMenuItems.map(({ name, path, icon }, index) => (
      <Link
        onClick={index === validMenuItems.length - 1 ? logout : toggle}
        to={path}
        key={name + path}
        title={name}
      >
        <div key={path}>
          {icon}
          {name}
        </div>
      </Link>
    ));
    return (
      <>
        {hamburgerMenu}
        <Options>{HTML}</Options>
      </>
    );
  }

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) setShow(false);
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <>
      {hamburgerMenu}
      {show === true && <ContentBox ref={wrapperRef}>{menu()}</ContentBox>}
    </>
  );
};
export default Menu;
