import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { HiCheck } from 'react-icons/hi';

// import thumbnailClass from '../../../../assets/serie/thumbnail-class.png';
// import time from '../../../../assets/serie/time.png';

import {
  Container,
  Thumbnail,
  Informations,
  ClassAttendedIcon,
  TitleContainer,
  Description,
  Options,
  CardSkeleton,
} from './styles';

const Class = ({ lesson, route }) => {
  const history = useHistory();
  const [assistedClass, setAssistedClass] = useState(false);

  const handleCard = () => {
    history.push(`${route}/${lesson.id}`);
  };

  function renderCard() {
    if (lesson !== undefined) {
      return (
        <Container>
          <Thumbnail
            id={'search-' + lesson.id}
            onClick={() => handleCard()}
            src={lesson.thumbnail_url}
            alt="Thumbnail da aula"
            onLoad={() => {
              let cards = document.querySelectorAll('img#search-' + lesson.id);
              for (let card of cards) {
                card.style = 'opacity: 1;';
              }
              let items = document.querySelectorAll(
                'div#skeleton-search-' + lesson.id,
              );
              for (let card of items) {
                card.style = 'display: none !important';
              }
            }}
          />
          <div id={`skeleton-search-${lesson.id}`}>
            <CardSkeleton />
          </div>
        </Container>
      );
    }
  }

  return <>{renderCard()}</>;
};

export default Class;
