import * as Types from './types';

const INITIAL_STATE = {
  id: '',
  name: '',
  email: '',
  cpf: '',
  birthday: '',
  accessToken: '',
  refreshToken: '',
  externalID: '',
  avatar_url: '',
  plan: null,
};

const userState = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case Types.GET_USER_DATA:
      return {
        id: payload.id,
        name: payload.name,
        email: payload.email,
        cpf: payload.cpf,
        birthday: payload.birthday,
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken,
        externalID: payload.external_id,
        avatar_url: payload.avatar_url,
        plan: payload.plan || null,
      };
    case Types.GET_USER_PROFILE_PHOTO:
      return {
        ...state,
        avatar_url: payload.avatar_url,
      };
    case Types.GET_NEW_TOKEN:
      return {
        ...state,
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken,
      };
    case Types.LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default userState;
