import React from 'react';
import { Link } from 'react-router-dom';

import api from '../../../../services/api';
import MyLessons from '../../../MyLessons';
import Carousel from '../../components/Carousel';
import TitleHightlight from '../TitleHightlight';
import { Box, Button, Options } from './styles';

class MyPractice extends React.Component {
  state = {
    favorites: [],
    selectedScreen: {
      name: 'FAVORITOS',
      content: <Carousel items={[]} />,
    },
  };

  async getHighlightsClasses() {
    const { data: allFavorites } = await api.get('favorites');
    const favorites = [
      ...allFavorites.lessons.map((lesson) => lesson.post.lesson),
      ...allFavorites.meditation_series,
      ...allFavorites.meditations,
      ...allFavorites.podcast_series,
      ...allFavorites.podcasts,
      ...allFavorites.series,
    ];
    this.setState((state) => (state.favorites = favorites));
  }

  componentDidMount() {
    this.getHighlightsClasses();
  }

  getScreens() {
    const screens = {
      FAVORITOS: {
        name: 'FAVORITOS',
        content: this.state.favorites.length ? (
          <Carousel items={this.state.favorites} />
        ) : (
          <h2>Nenhum favorito encontrado</h2>
        ),
      },
      'MINHA AGENDA': {
        name: 'MINHA AGENDA',
        // content: <MyCalendar />,
        content: <h1>Agenda</h1>,
      },
      HISTÓRICO: {
        name: 'HISTÓRICO',
        content: <MyLessons />,
      },
    };
    return screens;
  }

  selectScreen(screenName = 'FAVORITOS') {
    const selectedScreen = this.getScreens()[screenName];
    this.setState((state) => (state.selectedScreen = selectedScreen));
  }

  main() {
    return this.getScreens()[this.state.selectedScreen.name].content;
  }

  options() {
    return (
      <>
        <Options>
          <Link to={'calendario'}>
            <Button>MINHA AGENDA</Button>
          </Link>
          <Button
            selected={this.state.selectedScreen.name === 'FAVORITOS'}
            onClick={() => this.selectScreen('FAVORITOS')}
          >
            FAVORITOS
          </Button>
          <Button
            selected={this.state.selectedScreen.name === 'HISTÓRICO'}
            onClick={() => this.selectScreen('HISTÓRICO')}
          >
            HISTÓRICO
          </Button>
        </Options>
      </>
    );
  }
  render() {
    return (
      <>
        <Box>
          <TitleHightlight title="MINHA PRÁTICA" />
          {this.options()}
          <div style={{ paddingLeft: '50px' }}>
            <TitleHightlight
              title={this.state.selectedScreen.name}
              fontSize={25}
              lineHeight={30}
              desktopOnly={true}
            />
          </div>

          {this.main()}
        </Box>
      </>
    );
  }
}

export default MyPractice;
