import React, { useState, useEffect } from 'react';

import CommentReply from '../CommentReply';

import { Container, ShowMore } from './styles';

const ListCommentsReply = ({ commentResponses, loadListComment }) => {
  const [limitComment, setLimitComment] = useState(3);

  const renderCommentResponses = () => {
    let counter = 0;
    let list = [];
    for (let comment of commentResponses) {
      {
        counter++;
        if (counter <= limitComment) {
          list.push(
            <CommentReply
              key={comment.id}
              data={comment}
              loadListComment={loadListComment}
            />,
          );
        } else {
          break;
        }
      }
    }

    return list;
  };

  useEffect(() => {
    if (commentResponses.length === 3) {
      setLimitComment(3);
    }
  }, [commentResponses]);

  const renderShowMoreComments = () => {
    if (limitComment < commentResponses.length) {
      return (
        <ShowMore onClick={() => setLimitComment(limitComment + 3)}>
          Ver mais respostas
        </ShowMore>
      );
    } else if (limitComment > 3) {
      return (
        <ShowMore onClick={() => setLimitComment(limitComment - 3)}>
          Ver menos respostas
        </ShowMore>
      );
    }
  };

  return (
    <Container>
      {renderCommentResponses()}
      {renderShowMoreComments()}
    </Container>
  );
};

export default ListCommentsReply;
