import * as Types from './types';

const INITIAL_STATE = {
  id: '',
  lessons: [],
  totalLessonsInTheSerie: 0,
  currentLesson: {},
};

const serieState = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case Types.GET_SERIE_ID:
      return {
        ...state,
        id: payload.id,
      };
    case Types.GET_LESSONS_FROM_SERIE:
      return {
        ...state,
        totalLessonsInTheSerie: payload.total,
        lessons: payload.lessons,
      };
    case Types.GET_CURRENT_LESSON:
      return {
        ...state,
        currentLesson: payload.lesson,
      };
    default:
      return state;
  }
};

export default serieState;
