export const formatUserData = ({ accessToken, refreshToken, user }) => {
  const {
    id,
    name,
    email,
    external_id,
    avatar_url = '',
    birthday = null,
    cpf = null,
    plan = null,
  } = user;
  const userData = {
    accessToken,
    refreshToken,
    id,
    name,
    email,
    external_id,
    avatar_url,
    birthday,
    cpf,
    plan,
  };
  return userData;
};
