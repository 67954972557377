import { Drawer, Tooltip } from 'antd';
import React, { useState } from 'react';

import MegaFilter from '../../MegaFilter';
import srcSearch from './../icons/search.png';
import { BoxSearch } from './styles';

const MiniFilter = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  return (
    <>
      <BoxSearch>
        <Tooltip title="Pesquisar">
          <img onClick={() => toggleModal()} alt="Pesquisar" src={srcSearch} />
        </Tooltip>
        <Drawer
          visible={modalIsOpen}
          width="100VW"
          onClose={() => toggleModal()}
          destroyOnClose={true}
        >
          <MegaFilter noBgColor={true} setModalIsOpen={setModalIsOpen} />
        </Drawer>
      </BoxSearch>
    </>
  );
};

export default MiniFilter;
