import React, { useState, useEffect, useRef, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import logoutUserAutomatically from '../../utils/logoutUserAutomatically';

import api from '../../services/api';

import Header from '../../components/Header';
import Content from '../../components/Content';
import PlaylistOfTheMonth from './components/PlaylistOfTheMonth';
import Input from '../../components/Input';
import Carousel from '../../components/Carousel';
import Footer from '../../components/Footer';
import ResultsFound from './components/ResultsFound';
import LatestLessons from './components/LatestLessons';
import SeriesChannelPri from './components/SeriesChannelPri';
import LessonsChannelPri from './components/LessonsChannelPri';
import ExclusiveSeries from './components/ExclusiveSeries';
import ExclusiveLessons from './components/ExclusiveLessons';
import ExclusivePodcasts from './components/ExclusivePodcasts';
import ExclusiveMeditations from './components/ExclusiveMeditations';
import Filters from './components/Filters';
import WarningCookie from '../../components/WarningCookie';

import heart from '../../assets/home/heart.png';
import searchIcon from '../../assets/home/search.png';
import filter from '../../assets/home/filter.png';

import {
  Container,
  SearchContainer,
  FilterButton,
  ContainerInput,
  ButtonShowFilters,
  Menu,
  MenuItem,
} from './styles';

const Home = () => {
  const searchRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [searchedLessons, setSearchedLessons] = useState([]);
  const [searchedSeries, setSearchedSeries] = useState([]);
  const [searchedPodcastSeries, setSearchedPodcastSeries] = useState([]);
  const [searchedMeditationSeries, setSearchedMeditationSeries] = useState([]);
  const [searchedPodcasts, setSearchedPodcasts] = useState([]);
  const [searchedMeditations, setSearchedMeditations] = useState([]);
  const [
    showSpinnerPodcastAndMeditations,
    setShowSpinnerPodcastAndMeditations,
  ] = useState(false);
  const [allPodcastsAndMeditationsSeries, setAllPodcastsAndMeditationsSeries] =
    useState([]);
  const [appointments, setAppointments] = useState({});
  const [informationPlaylistOfTheMonth, setInformationPlaylistOfTheMonth] =
    useState({
      data: [],
    });
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState([]);
  const [loadSearch, setLoadSearch] = useState(false);
  const [chosenMenu, setChosenMenu] = useState('');

  const [latestLessons, setLatestLessons] = useState([]);
  const [showSpinnerLatestLessons, setShowSpinnerLatestLessons] = useState([]);

  const [exclusiveSeries, setExclusiveSeries] = useState([]);
  const [exclusiveSeriesFromChannel, setExclusiveSeriesFromChannel] = useState(
    [],
  );
  const [showSpinnerExclusiveSeries, setShowSpinnerExclusiveSeries] = useState(
    [],
  );
  const [
    showSpinnerExclusiveSeriesFromChannel,
    setShowSpinnerExclusiveSeriesFromChannel,
  ] = useState([]);

  const [exclusiveLessons, setExclusiveLessons] = useState([]);
  const [showSpinnerExclusiveLessons, setShowSpinnerExclusiveLessons] =
    useState([]);

  const [exclusiveLessonsFromChannel, setExclusiveLessonsFromChannel] =
    useState([]);
  const [
    showSpinnerExclusiveLessonsFromChannel,
    setShowSpinnerExclusiveLessonsFromChannel,
  ] = useState([]);

  const fetchFilters = async () => {
    await api.get('filter-categories?embed%5B%5D=filters').then((response) => {
      setFilters(response.data);
    });
  };

  useEffect(() => {
    handleSearch();
  }, [selectedFilters]);

  const handleSearch = async () => {
    const value = document.getElementById('search-home').value;
    setSearch(value);

    let filterIds = '';
    for (let i = 0; i < selectedFilters.length; i++) {
      const element = selectedFilters[i].id;
      if (i === 0) {
        filterIds += `filters%5B%5D=${element}`;
      } else {
        filterIds += `&filters%5B%5D=${element}`;
      }
    }

    if (value === '' && selectedFilters.length === 0) {
      setSearchedLessons([]);
      setSearchedSeries([]);
      setSearchedPodcastSeries([]);
      setSearchedMeditationSeries([]);
      setSearchedPodcasts([]);
      setSearchedMeditations([]);
      return;
    }

    let route = '';

    if (value.trim() !== '') {
      route = `search=${value}`;
    }

    if (filterIds.length > 0) {
      if (value.trim() !== '') {
        route += `&`;
      }

      route += filterIds;
    }

    setLoadSearch(true);
    await api
      .get(`/posts/search?${route}`)
      .then((response) => {
        saveSearchResponse(response.data);
        setLoadSearch(false);
      })
      .catch((error) => setLoadSearch(false));
  };

  const saveSearchResponse = (data) => {
    setSearchedSeries(data.series);
    setSearchedLessons(data.lessons);
    setSearchedPodcastSeries(data.podcast_series);
    setSearchedMeditationSeries(data.meditation_series);
    setSearchedPodcasts(data.podcasts);
    setSearchedMeditations(data.meditations);
  };

  const handleMenu = (option) => {
    if (option === chosenMenu) {
      setChosenMenu('');
    } else {
      setChosenMenu(option);
    }
  };

  function renderContent() {
    if (search.trim() === '' && selectedFilters.length === 0) {
      return (
        <>
          {(chosenMenu === '' || chosenMenu === 'Aulas e Séries') && (
            <LatestLessons
              latestLessons={latestLessons}
              showSpinner={showSpinnerLatestLessons}
            />
          )}
          {(chosenMenu === '' || chosenMenu === 'Aulas e Séries') && (
            <ExclusiveSeries
              exclusiveSeries={exclusiveSeries}
              showSpinner={showSpinnerExclusiveSeries}
            />
          )}
          {(chosenMenu === '' || chosenMenu === 'Aulas e Séries') && (
            <ExclusiveLessons
              exclusiveLessons={exclusiveLessons}
              showSpinner={showSpinnerExclusiveLessons}
            />
          )}

          {(chosenMenu === '' || chosenMenu === 'Aulas e Séries') && (
            <SeriesChannelPri
              allSeries={exclusiveSeriesFromChannel}
              showSpinner={showSpinnerExclusiveSeriesFromChannel}
            />
          )}

          {(chosenMenu === '' || chosenMenu === 'Aulas e Séries') && (
            <LessonsChannelPri
              exclusiveLessonsFromChannel={exclusiveLessonsFromChannel}
              showSpinner={showSpinnerExclusiveLessonsFromChannel}
            />
          )}

          {(chosenMenu === '' || chosenMenu === 'Podcasts') && (
            <ExclusivePodcasts
              allPodcastsAndMeditations={allPodcastsAndMeditationsSeries}
            />
          )}

          {(chosenMenu === '' || chosenMenu === 'Meditações') && (
            <ExclusiveMeditations
              allPodcastsAndMeditations={allPodcastsAndMeditationsSeries}
              showSpinner={showSpinnerPodcastAndMeditations}
            />
          )}
        </>
      );
    } else {
      return (
        <ResultsFound
          loadSearch={loadSearch}
          searchedLessons={searchedLessons}
          searchedSeries={searchedSeries}
          searchedPodcastSeries={searchedPodcastSeries}
          searchedMeditationSeries={searchedMeditationSeries}
          searchedPodcasts={searchedPodcasts}
          searchedMeditations={searchedMeditations}
        />
      );
    }
  }

  const renderPlaylistOfTheMonth = () => {
    if (
      JSON.stringify(appointments) !== '{}' &&
      JSON.stringify(informationPlaylistOfTheMonth) !== '{}' &&
      informationPlaylistOfTheMonth.playlist
    ) {
      return (
        <PlaylistOfTheMonth
          data={informationPlaylistOfTheMonth}
          calendarAppointments={informationPlaylistOfTheMonth.data}
          showAllAppointments={false}
        />
      );
    }
  };

  const loadPodcastAndMeditations = async () => {
    setShowSpinnerPodcastAndMeditations(true);
    try {
      const response = await api.get('/podcast-series/published');
      setAllPodcastsAndMeditationsSeries(response.data);
    } catch (error) {}
    setShowSpinnerPodcastAndMeditations(false);
  };

  const loadInformationPlaylistOfTheMonth = async () => {
    await api.get('/appointments/official?playlist=true').then((response) => {
      setInformationPlaylistOfTheMonth(response.data);
    });
  };

  const loadAppointments = async () => {
    await api.get('/student-appointments').then((response) => {
      setAppointments(response.data);
    });
  };

  const loadLatestLessons = async () => {
    setShowSpinnerLatestLessons(true);

    await api
      .get(
        `lessons/published?filter_ids%5B%5D=${process.env.REACT_APP_FILTER_RECENT_ID}`,
      )
      .then((response) => {
        setLatestLessons(response.data);
        setShowSpinnerLatestLessons(false);
      })
      .catch((error) => setShowSpinnerLatestLessons(false));
  };

  const loadExclusiveSeries = async () => {
    setShowSpinnerExclusiveSeries(true);

    await api
      .get('/series/published?is_exclusive=true')
      .then((response) => {
        setExclusiveSeries(response.data);
        setShowSpinnerExclusiveSeries(false);
      })
      .catch((error) => setShowSpinnerExclusiveSeries(false));
  };

  const loadExclusiveSeriesFromChannel = async () => {
    setShowSpinnerExclusiveSeriesFromChannel(true);

    await api
      .get('/series/published?is_from_the_channel=true')
      .then((response) => {
        setExclusiveSeriesFromChannel(response.data);
        setShowSpinnerExclusiveSeriesFromChannel(false);
      })
      .catch((error) => setShowSpinnerExclusiveSeriesFromChannel(false));
  };

  const loadExclusiveLessons = async () => {
    setShowSpinnerExclusiveLessons(true);

    await api
      .get(
        `/series/${process.env.REACT_APP_SINGLE_LESSONS_EXCLUSIVES}/lessons/published?limit=18`,
      )
      .then((response) => {
        setExclusiveLessons(response.data.lessons);
        setShowSpinnerExclusiveLessons(false);
      })
      .catch((error) => setShowSpinnerExclusiveLessons(false));
  };

  const loadExclusiveLessonsFromChannel = async () => {
    setShowSpinnerExclusiveLessonsFromChannel(true);

    await api
      .get(
        `/series/${process.env.REACT_APP_SINGLE_LESSONS_CHANNEL}/lessons/published?limit=18`,
      )
      .then((response) => {
        setExclusiveLessonsFromChannel(response.data.lessons);
        setShowSpinnerExclusiveLessonsFromChannel(false);
      })
      .catch((error) => setShowSpinnerExclusiveLessonsFromChannel(false));
  };

  useEffect(() => {
    loadLatestLessons();
    loadExclusiveSeries();
    loadExclusiveSeriesFromChannel();
    loadExclusiveLessons();
    loadExclusiveLessonsFromChannel();
    loadPodcastAndMeditations();
    loadInformationPlaylistOfTheMonth();
    loadAppointments();
    fetchFilters();
    window.scrollTo(0, 0);
    if (logoutUserAutomatically()) {
      dispatch({ type: 'yoga-adm/user/logout' });
      history.push('/login');
    }
  }, []);

  const renderFilters = () => {
    if (showFilters) {
      return (
        <Filters
          filters={filters}
          setFilters={setFilters}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          handleSearch={handleSearch}
        />
      );
    }
  };

  const renderMenu = () => {
    if (search.trim() === '' && selectedFilters.length === 0) {
      return (
        <Menu>
          <MenuItem
            code="Aulas e Séries"
            chosenMenu={chosenMenu}
            onClick={() => handleMenu('Aulas e Séries')}
          >
            <span>Aulas e Séries</span>
            <div></div>
          </MenuItem>
          <MenuItem
            code="Meditações"
            chosenMenu={chosenMenu}
            onClick={() => handleMenu('Meditações')}
          >
            <span>Meditações</span>
            <div></div>
          </MenuItem>
          <MenuItem
            code="Podcasts"
            chosenMenu={chosenMenu}
            onClick={() => handleMenu('Podcasts')}
          >
            <span>Podcasts</span>
            <div></div>
          </MenuItem>
          <MenuItem
            code="Meu diário"
            chosenMenu={chosenMenu}
            onClick={() => history.push('/meu-diario')}
          >
            <span>Meu diário</span>
            <div></div>
          </MenuItem>
        </Menu>
      );
    }
  };

  return (
    <Container>
      {/* <Header /> */}
      {renderPlaylistOfTheMonth()}
      <SearchContainer>
        <Content>
          <ContainerInput>
            <div>
              <Input
                id="search-home"
                inputRef={searchRef}
                placeholder="Buscar conteúdos"
                iconLeft={heart}
                iconRight={searchIcon}
                onClickIconRight={() => handleSearch()}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
              />
              <ButtonShowFilters onClick={() => setShowFilters(!showFilters)}>
                <img src={filter} alt="Ícone de filtros" />
                Filtrar busca
              </ButtonShowFilters>
            </div>
            {renderFilters()}
          </ContainerInput>
        </Content>
      </SearchContainer>
      <Content>{renderMenu()}</Content>
      {renderContent()}
      {/* <Footer /> */}
      <WarningCookie />
    </Container>
  );
};

export default memo(Home);
