import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 40px 0px;

  > div:nth-of-type(1) {
    display: flex;
    > div:nth-of-type(1) {
      display: flex;
      margin-left: 40px;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;

    > div:nth-of-type(1) {
      flex-direction: column;
      > img {
        margin: 0 auto;
      }
      > div:nth-of-type(1) {
        margin-left: 0px;
      }
    }
  }
`;

export const Thumbnail = styled.img`
  height: 180px;
  width: auto;
  cursor: pointer;
  border-radius: 20px;

  @media (max-width: 360px) {
    height: 160px;
  }

  @media (max-width: 320px) {
    height: 140px;
  }
`;

export const Informations = styled.div`
  //max-width: 400px;

  @media (max-width: 1024px) {
    max-width: 100%fit-content;
  }
`;

export const ClassAttendedIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: ${(props) => (props.assistedClass ? '#19b441' : '#fff')};
  border-style: solid;
  border-width: 2px;
  border-color: #19b441;
  transition: 0.2s all;

  @media (max-width: 1024px) {
    height: 33px;
    width: 33px;
    margin-right: 20px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  cursor: pointer;

  > div {
    font-family: 'Aller-Regular';
    font-size: 2rem;
    color: var(--dark03);
    margin-left: 10px;
  }

  @media (max-width: 1024px) {
    align-items: center;
    width: fit-content;
    margin: 10px auto 0 auto;

    > div:nth-of-type(1) {
      margin: 10px auto;
      text-align: center;
    }
  }
`;

export const Description = styled.div`
  > div {
    margin-top: 10px;
    display: flex;
    align-items: center;
    > img {
      height: 25px;
    }

    > span {
      font-family: 'Aller-Regular';
      font-size: 1.4rem;
      color: #5c5c5c;
      margin-left: 10px;
    }
  }

  > p {
    font-family: 'Aller-Light';
    font-size: 1.2rem;
    color: #5c5c5c;
    margin-top: 10px;
  }

  @media (max-width: 1024px) {
    > div {
      justify-content: center;
    }

    > p {
      text-align: center;
    }
  }
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    display: none;
  }

  > img {
    display: block;
    height: 25px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ContainerCheckedIconDesktop = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ContainerCheckedIconMobile = styled.div`
  display: none;

  @media (max-width: 1024px) {
    display: block;
  }
`;

export const MenuMobile = styled.div`
  display: none;

  @media (max-width: 1024px) {
    display: flex;
    justify-content: center;
    margin: 10px 0px 20px 0px;

    img {
      height: 25px;
    }

    img:nth-of-type(1) {
      margin-right: 20px;
    }

    img:nth-of-type(2) {
      margin-right: 0px;
    }
  }
`;
