import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  box-shadow: 0px 0px 6px 0px lightgrey;
`;

export const GradientBackground = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 100px;
  z-index: -1;
  background: rgb(97, 225, 113);
  background: linear-gradient(
    0deg,
    rgba(97, 225, 113, 1) -60%,
    rgba(248, 251, 246, 1) 70%,
    rgba(255, 255, 255, 1) 80%
  );
`;

export const Menu = styled.menu`
  > ul {
    display: flex;
    /* justify-content: space-between; */
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;

    > li {
      font-family: 'Aller-Bold';
      font-size: 1rem;
      color: #9e9e9e;
      list-style: none;
      cursor: pointer;
    }
  }
`;

export const Logo = styled.img`
  display: block;
  margin: 60px auto;
  cursor: pointer;

  @media (max-width: 640px) {
    margin-top: 40px;
  }

  @media (max-width: 560px) {
    width: 156px;
    height: 25px;
  }
`;

export const CopyRight = styled.span`
  position: absolute;
  bottom: 10px;
  right: 20px;
  font-family: 'Aller-Regular';
  font-size: 1rem;
  color: grey;

  @media (max-width: 1080px) {
    left: 50%;
    transform: translateX(-50%);
    right: auto;
  }
`;
