import styled from 'styled-components';

export const BoxAuthorizedPayment = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  .welcomeImg {
    width: 100%;
    object-fit: contain;
  }

  .successImg {
    width: 29px;
    height: 29px;
    margin-right: 16px;
  }

  span,
  h1,
  p {
    color: #030303;
    font-family: 'Aller-Regular';
    font-style: normal;
    text-align: center;
  }

  span {
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
  }

  h1 {
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 40px;
    font-weight: 700;
    line-height: 140%;
    text-transform: uppercase;
  }

  p {
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
  }
`;
