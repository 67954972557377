import { Hightlight } from './styles.js';

const TitleHightlight = ({
  title = '',
  fontSize = 0,
  lineHeight,
  desktopOnly = false,
}) => {
  return (
    <>
      <Hightlight
        fontSize={fontSize}
        lineHeight={lineHeight}
        desktopOnly={desktopOnly}
      >
        {title}
      </Hightlight>
    </>
  );
};

export default TitleHightlight;
