import React, { useState, memo } from 'react';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';

import podcast from '../../../../../../assets/home/podcast2.png';

import './carousel.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Card, ListCardsWithouSlider, CardSkeleton } from './styles';

const Slider3D = ({ lessons, loading }) => {
  const [imgLoaded, setImgLoaded] = useState(false);
  const history = useHistory();

  const sliderSettings = {
    className: 'center',
    centerMode: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          centerMode: true,
          variableWidth: true,
        },
      },
    ],
  };

  function renderSlider() {
    let listCards;

    if (loading) {
      listCards = Array(4)
        .fill()
        .map((item) => <CardSkeleton />);
    } else {
      listCards = lessons.map((item) => (
        <div>
          <Card
            id={'podcast-' + item.id}
            onClick={() =>
              history.push({
                pathname: `/meditacao/aula/${item.id}`,
              })
            }
            src={item.thumbnail_url}
            alt={`${item.name} - Thumbnail da Meditação`}
            onLoad={() => {
              let cards = document.querySelectorAll('img#podcast-' + item.id);
              for (let card of cards) {
                card.style = 'opacity: 1; height: auto;';
              }
              let items = document.querySelectorAll(
                'div#skeleton-podcast-' + item.id,
              );
              for (let card of items) {
                card.style = 'display: none !important';
              }
            }}
          />
          <div id={`skeleton-podcast-${item.id}`}>
            <CardSkeleton />
          </div>
        </div>
      ));
    }

    if (lessons.length > 3 || loading) {
      return <Slider {...sliderSettings}>{listCards}</Slider>;
    } else if (lessons.length > 1) {
      return (
        <Slider {...sliderSettings}>
          {listCards}
          {listCards}
        </Slider>
      );
    } else {
      return (
        <Slider {...sliderSettings}>
          {listCards}
          {listCards}
          {listCards}
          {listCards}
        </Slider>
      );
    }
  }

  return <>{renderSlider()}</>;
};

export default memo(Slider3D);
