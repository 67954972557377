import styled from 'styled-components';

export const BoxCard = styled.div`
  h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 25px;
  }
  img {
    max-width: 100%;
    margin-bottom: 25px;
    display: block;
    object-fit: contain;
  }
  div {
    margin-bottom: 25px;
    font-size: 20px;
    line-height: 24px;
    span {
      font-weight: 400;
      display: block;
    }
    b {
      font-weight: 700;
      text-transform: uppercase;
    }
  }
  @media (max-width: 720px) {
    h3 {
      font-size: 17px;
      line-height: 18px;
    }
  }
`;
