import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  min-width: 40px;
  height: 25px;
  min-width: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: ${(props) => (props.assistedClass ? '#19b441' : '#fff')};
  border-style: solid;
  border-width: 2px;
  border-color: #19b441;
  transition: 0.2s all;
  cursor: pointer;

  @media (max-width: 1024px) {
    /* height: 33px;
    min-width: 33px; */
    margin-right: 20px;
  }
`;
