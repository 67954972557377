import styled from 'styled-components';

export const Container = styled.div`
  border-style: solid;
  border-color: #61e171;
  border-width: 2px;
  border-radius: 10px;
  padding: 20px;
  width: 400px;
  margin: 20px auto;

  @media (max-width: 560px) {
    width: 100%;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;

  > div {
    margin-left: 20px;

    > span {
      display: block;
    }
    > span:nth-of-type(1) {
      font-family: 'Bartolomeo-Regular';
      font-size: 2rem;
      color: var(--dark03);
    }

    > span:nth-of-type(2) {
      font-family: 'Aller-Regular';
      font-size: 1.6rem;
      color: #707070;
    }
  }
`;
