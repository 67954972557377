import styled, { css } from 'styled-components';

export const Container = styled.div`
  font-family: 'Aller-Regular';
  visibility: hidden;
  width: 120px;
  background-color: #61e171;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: all 0.3s;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #61e171 transparent transparent transparent;
  }

  ${(props) =>
    props.showTooltip &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`;
