import styled, { css } from 'styled-components';

export const Main = styled.main`
  padding: 50px 0 50px 0;
  width: 100%;
  display: grid;
  place-items: center;
  margin-top: -20px;

  background: #f5f5f5;
  background: ${(props) => (props.noBgColor ? '#fff' : '#f5f5f5')};
  border-radius: 20px;
`;

export const BoxFilter = styled.div`
  width: 80vw;
  max-width: 655px;
  input[type='search'] {
    height: 70px;
    width: 100%;
    background: #ffffff;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    border: none;
    padding: 0 15px;
    margin: 0;

    font-family: 'Aller-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;

    color: #929191;
  }
  img {
    position: relative;
    top: -51px;
    right: -85%;
  }
  button {
    background-color: transparent;
    cursor: pointer;
    margin-left: 47px;
    font-family: 'Aller-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    outline: none;
    border: none;
    margin-top: 12px;
  }

  @media (max-width: 520px) {
    input[type='search'],
    button {
      font-size: 16px;
    }
  }
`;

export const BoxOptions = styled.div`
  margin-top: -36px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.05);
  padding: 15px;
  border-radius: 0 0 9px 9px;
  background-color: #fff;

  font-family: 'Aller-Bold';
  div {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
  }
`;

export const Filter = styled.span`
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.05);
  padding: 3px 10px;
  cursor: pointer;
  color: var(--dark03);
  border-radius: 9px;
  border: none;
  ${(props) =>
    props.selected
      ? css`
          background-color: lightgreen;
        `
      : css`
          background-color: lightgray;
        `}
`;

export const FilterSelect = styled(Filter)`
  border-radius: 50px;
  background-color: var(--purple84);
`;

export const BoxResults = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 25px;
  flex-wrap: wrap;
  padding: 20px;
`;
