import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';

import Header from '../../components/Header';
import Content from '../../components/Content';
import Plans from './components/Plans';
import LoadModal from '../../components/LoadModal';
import Footer from '../../components/Footer';

import iconBack from '../../assets/plans/icon-back.png';
import check from '../../assets/changePlan/check.png';

import {
  Container,
  Row,
  Description,
  Benefits,
  GradientBackground,
  GiftForFriend,
} from './styles';
import { checkIfUserHasAnActivePlan } from '../../utils/checkIfUserHasAnActivePlan';

const ChangePlan = () => {
  const history = useHistory();
  const [userPlan, setUserPlan] = useState({});
  const [plansOptions, setPlansOptions] = useState([{ name: '', price: '' }]);
  const [showLoadModal, setShowLoadModal] = useState(false);

  const getCurrentPlan = async () => {
    try {
      const { data } = await api.get(`/plan-types?isVisible=true`);
      if (data) {
        setUserPlan(data);
        listPlanOptionsForTheUserToChange(userPlan);
      }
    } catch (error) {
      history.push('/escolha-um-plano');
    }
  };

  const listPlanOptionsForTheUserToChange = async (userPlan) => {
    try {
      const response = await api.get(`/plan-types?isVisible=true`);
      let plans = [];
      response.data.map(
        (plan) => plan.id !== userPlan.plan_type_id && plans.push(plan),
      );

      setPlansOptions(plans);
    } catch (error) {
      history.push('/escolha-um-plano');
    }
  };

  useEffect(() => {
    const verifyPlan = async () => {
      setShowLoadModal(true);
      const hasActivePlan = await checkIfUserHasAnActivePlan();
      setShowLoadModal(false);
      if (hasActivePlan) getCurrentPlan();
    };
    verifyPlan();

    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Header />
      <Content>
        <Row>
          <img
            onClick={() => history.goBack()}
            src={iconBack}
            alt="Ícone de voltar para a página anterior"
          />
        </Row>
        <Row>
          <Description>
            <h1>Altere seu plano</h1>
            <div>E ACESSE AGORA</div>
            <div>Apoie o canal da Pri e tenha acesso a:</div>
            <Benefits>
              <div>
                <img src={check} alt="Ícone de Ok" />
                <span>+ de 300 aulas do canal da Pri</span>
              </div>
              <div>
                <img src={check} alt="Ícone de Ok" />
                <span>+ de 50 aulas exclusivas</span>
              </div>
            </Benefits>
            <Benefits>
              <div>
                <img src={check} alt="Ícone de Ok" />
                <span>+ 30 Podcasts</span>
              </div>
              <div>
                <img src={check} alt="Ícone de Ok" />
                <span>+ 10 meditações</span>
              </div>
            </Benefits>
          </Description>
          <div>
            <Plans
              setShowLoadModal={setShowLoadModal}
              plans={plansOptions}
              userPlan={userPlan}
              checkIfUserHasAnActivePlan={checkIfUserHasAnActivePlan}
            />
          </div>
        </Row>
      </Content>
      <Footer />
      <GradientBackground />
      <LoadModal show={showLoadModal} />
    </Container>
  );
};

export default ChangePlan;
