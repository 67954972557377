import { message, Tooltip } from 'antd';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Calendar from '../../../../components/Calendar';
import { watchVideoLesson } from '../../../../utils/watchVideoLesson';
import TitleHightlight from '../../components/TitleHightlight';
import api from './../../../../services/api';
import { Description, Main } from './styles';

const MegaPlayListMonth = () => {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [calendarAppointments, setCalendarAppointments] = useState(null);
  const [showAllAppointments, setShowAllAppointments] = useState(null);
  const [chosenClass, setChosenClass] = useState(null);

  useEffect(() => {
    async function getAllData() {
      const { data: response } = await api.get(
        '/appointments/official?playlist=true',
      );
      const { data, playlist: calendarAppointments } = response;

      setData(data);
      setCalendarAppointments(calendarAppointments);
      setShowAllAppointments(showAllAppointments);
    }
    getAllData();
  }, []);

  const goToLesson = () => {
    watchVideoLesson(chosenClass, history);
  };

  return (
    <>
      <Main>
        <Description>
          <TitleHightlight title="Calendário" fontSize={90} lineHeight={133} />
          <h2>UMA AULA POR DIA PARA VOCÊ</h2>
          <p>Praticar yoga com um clique</p>
          <p>é sempre uma boa pedida!</p>
          {/* <Tooltip
            title={chosenClass?.lesson?.name || 'Assistir agora'}
            visible={true}
          > */}
          {chosenClass && <button onClick={goToLesson}>VAMOS!</button>}

          {/* </Tooltip> */}
        </Description>

        {calendarAppointments && (
          <Calendar
            setChosenClass={setChosenClass}
            data={data}
            calendarAppointments={calendarAppointments}
            showAllAppointments={showAllAppointments}
            showHour={false}
          />
        )}
      </Main>
    </>
  );
};

export default MegaPlayListMonth;
