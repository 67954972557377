import styled from 'styled-components';

export const BoxPlanAdvertisement = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  h3,
  p {
    margin: 0;
    padding: 0;
  }
  h3 {
    color: #030303;
    font-family: 'Aller-Regular';
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    @media (max-width: 720px) {
      font-size: 17px;
      line-height: 18px;
    }
  }

  p {
    color: rgba(0, 0, 0, 0.8);
    font-family: 'Aller-Regular';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
    letter-spacing: -0.22px;
  }

  .price-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 24px;
    gap: 4px;
    background-color: var(--greyF5);
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    min-height: 138px;
    .price {
      display: flex;
      align-items: baseline;
    }
    span {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
    }
    button {
      padding: 16px;
      gap: 10px;
      height: 56px;
      background-color: var(--dark03);
      box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.07);
      border-radius: 8px;
      border: none;
      outline: none;
      color: #fff;
    }
    h5 {
      color: #030303;
      font-family: 'Aller-Regular';
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: strikethrough;
    }

    @media (max-width: 720px) {
      h3 {
        font-size: 17px;
        line-height: 18px;
      }
      h5 {
        font-size: 13px;
      }
      span {
        font-size: 14px;
        line-height: 15px;
      }
      button {
        margin: 0 auto;
      }
    }
  }
`;
