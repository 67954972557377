import { MUserForm } from '../../mocks/MUserForm';
import { CardBox } from './styles';
import amex from './icons/amex.svg';
import amex_single from './icons/amex_single.svg';
import visa from './icons/visa.svg';
import visa_single from './icons/visa_single.svg';
import mastercard from './icons/mastercard.svg';
import mastercard_single from './icons/mastercard_single.svg';
import discover from './icons/discover.svg';
import discover_single from './icons/discover_single.svg';
import jcb from './icons/jcb.svg';
import jcb_single from './icons/jcb_single.svg';
import unionpay from './icons/unionpay.svg';
import unionpay_single from './icons/unionpay_single.svg';
import diners from './icons/diners.svg';
import diners_single from './icons/diners_single.svg';
import maestro from './icons/maestro.svg';
import maestro_single from './icons/maestro_single.svg';
import elo from './icons/elo.png';
import brand from './icons/brand.png';
import radio_on from './icons/radio_on.svg';
import radio_off from './icons/radio_off.svg';

const MegaCreditCard = ({ creditCard = MUserForm }) => {
  // const images = {
  //   30: { img: diners, bg: diners_single },
  //   35: { img: jcb, bg: jcb_single },
  //   37: { img: amex, bg: amex_single },
  //   40: { img: visa, bg: visa_single },
  //   52: { img: mastercard, bg: mastercard_single },
  //   60: { img: discover, bg: discover_single },
  //   62: { img: unionpay, bg: unionpay_single },
  //   67: { img: maestro, bg: maestro_single },
  // };

  const brands = {
    Diners: { img: diners, bg: diners_single },
    35: { img: jcb, bg: jcb_single },
    AmericanExpress: { img: amex, bg: amex_single },
    Visa: { img: visa, bg: visa_single },
    Master: { img: mastercard, bg: mastercard_single },
    60: { img: discover, bg: discover_single },
    62: { img: unionpay, bg: unionpay_single },
    67: { img: maestro, bg: maestro_single },
    Elo: { img: elo, bg: maestro_single },
    brand: { img: brand, bg: maestro_single },
  };

  const lastThree = (text) => {
    if (text) {
      return text.substring(text.length - 4);
    }
  };

  const Brand = () => {
    try {
      if (creditCard.brand) {
        return (
          <img
            src={brands[creditCard.brand].img}
            alt={creditCard.brand}
            className="brand"
          />
        );
      }
      return null;
    } catch (error) {
      return <img src={brands.brand.img} className="brand" alt="Cartão" />;
    }
  };

  return (
    <>
      {creditCard?.display_number ? (
        <CardBox>
          <img src={radio_on} alt="Selecionado" />
          <div>
            <Brand />
            <div>{lastThree(creditCard.display_number)}</div>
          </div>
        </CardBox>
      ) : null}
    </>
  );
};

export default MegaCreditCard;
