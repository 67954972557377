import React, { useEffect, useState, memo } from 'react';
import Skeleton from 'react-loading-skeleton';

import SkeletonSlider3D from '../../../../components/SkeletonSlider3D';
import Content from '../../../../components/Content';
import Slider3D from '../../../../components/Slider3D';

import { Container, Spinner, Title } from './styles';

const LatestLessons = ({ latestLessons, showSpinner }) => {
  return (
    <Container>
      <Content>
        <Title>AULAS MAIS RECENTES</Title>
        <Slider3D
          route="aula"
          lessons={latestLessons.slice(0, 19)}
          loading={showSpinner}
        />
      </Content>
    </Container>
  );
};

export default memo(LatestLessons);
