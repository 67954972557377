import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import api from '../../services/api';

import MessageModal from '../../components/MessageModal';
import NoAccessModal from '../../components/NoAccessModal';

import favorite from '../../assets/serie/favorite.png';
import favoriteSelected from '../../assets/serie/favoriteSelected.png';

import { Image } from './styles';

const FavoriteButton = ({
  post_id,
  is_favorite,
  favorite_id,
  setShowLoadModal,
  canAccessPaidContent,
}) => {
  const user = useSelector((state) => state.User);
  const [isFavorite, setIsFavorite] = useState();
  const [favoriteId, setFavoriteId] = useState();
  const [error, setError] = useState('');
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showNoAcessModal, setShowNoAccessModal] = useState(false);

  useEffect(() => {
    setIsFavorite(is_favorite);
    setFavoriteId(favorite_id);
  }, [is_favorite, favorite_id]);

  const renderFavoriteButton = () => {
    if (isFavorite) {
      return (
        <Image
          src={favoriteSelected}
          alt="Ícone de desfavoritar aula"
          onClick={() => handleClick()}
        />
      );
    }

    return (
      <Image
        src={favorite}
        alt="Ícone de favoritar aula"
        onClick={() => handleClick()}
      />
    );
  };

  const favoriteItem = async () => {
    setShowLoadModal(true);
    await api
      .post('/favorites', {
        post_id,
      })
      .then((response) => {
        setShowLoadModal(false);
        setIsFavorite(true);
        setFavoriteId(response.data.id);
      })
      .catch((error) => handleError(error));
  };

  const deleteFavoriteItem = async () => {
    setShowLoadModal(true);
    await api
      .delete(`/favorites/${favoriteId}`)
      .then((response) => {
        setShowLoadModal(false);
        setIsFavorite(false);
      })
      .catch((error) => handleError(error));
  };

  const fetchIfUserHasPlanActive = async () => {
    const userHasPlanActive = await api
      .get(`/users/plans`)
      .then((response) => {
        if (
          response.data.status === 'pending' ||
          response.data.status === 'suspended'
        ) {
          return false;
        }
        return true;
      })
      .catch((error) => {
        return false;
      });

    return userHasPlanActive;
  };

  const handleError = async (error) => {
    let message;
    if (error.response.data?.message) {
      message = error.response.data.message;
    } else {
      message = error.toString();
    }

    const userHasPlanActive = await fetchIfUserHasPlanActive();

    setShowLoadModal(false);

    if (userHasPlanActive) {
      setError(message);
      setShowMessageModal(true);
    } else {
      setShowNoAccessModal(true);
    }
  };

  const renderMessageModal = () => {
    if (showMessageModal) {
      return (
        <MessageModal
          title="Ocorreu um erro!"
          description={error}
          setVisible={setShowMessageModal}
        />
      );
    }
  };

  const renderNoAccessModal = () => {
    if (showNoAcessModal) {
      return (
        <NoAccessModal show={showNoAcessModal} setShow={setShowNoAccessModal} />
      );
    }
  };

  const handleClick = () => {
    if (!canAccessPaidContent) {
      setShowNoAccessModal(true);
      return;
    }

    if (isFavorite) {
      deleteFavoriteItem();
    } else {
      favoriteItem();
    }
  };

  return (
    <>
      {renderFavoriteButton()}
      {renderMessageModal()}
      {renderNoAccessModal()}
    </>
  );
};

export default FavoriteButton;
