import styled from 'styled-components';

export const Card = styled.div`
  z-index: 9999;
  width: 100%;
  max-width: 320.5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 20px;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2);
  background-color: var(--grey9E);

  .box-header {
    height: 170px;
    width: 100%;
    background-image: url(${(props) => `'${props.bgImage}'`});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px 20px 0 0;
  }

  @media (max-width: 720px) {
    background-image: url(${(props) => `'${props.mobileThumbnail}'`});
  }
`;

export const BoxFooter = styled.div`
  max-width: 320.5px;
  margin-top: -20px;
  border-radius: 20px;
  height: 103px;
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Aller-Regular';
  font-style: normal;
  font-weight: 400;
  color: #030303;

  div {
    font-size: 14px;
    line-height: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    img {
      width: 25px;
      height: 25px;
      /* object-fit: contain; */
    }
  }
  span.live {
    border-radius: 3px;
    background-color: var(--redFE);
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 500;
    padding: 3px;
  }
`;

export const BoxTitle = styled.div`
  max-width: 270px;
  h5 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    font-size: 19px;
    line-height: normal;
  }
`;

export const Live = styled.span`
  border-radius: 3px;
  background-color: var(--redFE);
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 500;
  padding: 3px;
`;
