import React, { useEffect, useState } from 'react';

import searchIcon from '../../assets/home/search.png';
import Content from '../../components/Content';
import Input from '../../components/Input';
import api from '../../services/api';
import ListQuestions from './components/ListQuestions';
import { Container, SearchContainer, Spinner, Title } from './styles';

const FrequentlyAskedQuestions = () => {
  const [search, setSearch] = useState('');
  const [itensSearch, setItensSearch] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchQuestions = async () => {
    setLoading(true);
    await api
      .get('/common-questions')
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => setLoading(false));
  };

  const formatString = (value) => {
    let newValue = value.toUpperCase();
    newValue = newValue.replace(/[ÀÁÂÃÄÅ]/, 'A');
    newValue = newValue.replace(/[ÈÉÊË]/, 'E');
    newValue = newValue.replace(/[ÌÍÎÏ]/, 'I');
    newValue = newValue.replace(/[ÒÓÕÔÖ]/, 'O');
    newValue = newValue.replace(/[ÙÚÛÜ]/, 'U');
    newValue = newValue.replace(/[Ç]/, 'C');
    return newValue;
  };

  const handleSearch = (value) => {
    setSearch(value);

    let questionsFound = [];
    for (let item of data) {
      const question = formatString(item.question);
      const answer = formatString(item.answer);
      const valueUpperCase = formatString(value);
      if (
        question.indexOf(valueUpperCase) >= 0 ||
        answer.indexOf(valueUpperCase) >= 0
      ) {
        questionsFound.push(item);
      }
    }

    setItensSearch(questionsFound);
  };

  const renderQuestions = () => {
    if (loading) {
      return <Spinner />;
    }

    if (search !== '') {
      return <ListQuestions data={itensSearch} />;
    }
    return <ListQuestions data={data} />;
  };

  useEffect(() => {
    fetchQuestions();
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Content>
        <Title>Perguntas frequentes</Title>
        <SearchContainer>
          <Input
            placeholder="Qual é a sua dúvida?"
            iconRight={searchIcon}
            value={search}
            onInput={(event) => handleSearch(event.target.value)}
          />
        </SearchContainer>
        {renderQuestions()}
      </Content>
    </Container>
  );
};

export default FrequentlyAskedQuestions;
