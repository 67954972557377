import styled from 'styled-components';

export const Container = styled.div`
  > p {
    font-family: 'Aller-Bold';
    font-size: 1.6rem;
    color: var(--purple84);
    text-align: center;
    margin-top: 60px;
    margin-bottom: 40vh;
  }
`;
