import React, { useState } from 'react';
import { FaHeart } from 'react-icons/fa';
import { RiReplyLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';

import { BiEdit } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import AddCommentReply from '../../../AddCommentReply';
import CommentReply from './../../components/CommentReply';
import Input from '../../../../../../../../components/Input';
import Button from '../../../../../../../../components/Button';
import LoadModal from '../../../../../../../../components/LoadModal';
import MessageModal from '../../../../../../../../components/MessageModal';
import NoAccessModal from '../../../../../../../../components/NoAccessModal';
import LikeButton from '../../components/LikeButton';
import ListCommentsReply from '../../components/ListCommentsReply';
import ResponseTime from '../ResponseTime';
import LikesModal from '../LikesModal';

import api from '../../../../../../../../services/api';

import {
  Container,
  ProfilePhoto,
  Informations,
  Name,
  Text,
  Divider,
  Row,
  Row2,
  NumberOfLikes,
  AdminPhotoContainer,
  ReponseAndNumberOfLikesContainer,
} from './styles';

const Commment = ({ data, loadListComment }) => {
  const userID = useSelector((state) => state.User.id);

  const [showAddComment, setShowAddComment] = useState(false);
  const [showCommentsReply, setShowCommentsReply] = useState(false);
  const [showEditField, setShowEditField] = useState(false);
  const [content, setContent] = useState(data.content);
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showNoAccessModal, setShowNoAccessModal] = useState(false);
  const [error, setError] = useState('');
  const [likeList, setLikeList] = useState([]);
  const [showLikeList, setShowLikeList] = useState(false);
  const [showLoadingLikeList, setShowLoadingLikeList] = useState(false);

  async function handleShowLikeList(comment_id) {
    if (!showLikeList) {
      setShowLoadingLikeList(true);
      setShowLikeList(true);
      await api
        .get(`/comments/${comment_id}/likes`)
        .then((res) => {
          setLikeList(res.data.likes);
          setShowLoadingLikeList(false);
        })
        .catch((err) => {
          setShowLoadingLikeList(false);
        });
      return;
    }

    setShowLikeList(false);
  }

  const updateComment = async () => {
    setShowLoadModal(true);
    await api
      .put(`/comment-responses/${data.id}`, {
        content,
      })
      .then((response) => {
        setShowLoadModal(false);
        setShowEditField(false);
      })
      .catch((error) => handleError(error));
  };

  const deleteComment = async () => {
    setShowLoadModal(true);
    await api
      .delete(`/comment-responses/${data.id}`)
      .then((response) => {
        setShowLoadModal(false);
        setShowEditField(false);
        loadListComment(true, false);
      })
      .catch((error) => handleError(error));
  };

  const fetchIfUserHasPlanActive = async () => {
    const userHasPlanActive = await api
      .get(`/users/plans`)
      .then((response) => {
        if (
          response.data.status === 'pending' ||
          response.data.status === 'suspended'
        ) {
          return false;
        }
        return true;
      })
      .catch((error) => {
        return false;
      });

    return userHasPlanActive;
  };

  const handleError = async (error) => {
    let message;
    if (error?.response?.data?.message) {
      message = error?.response?.data?.message;
    } else {
      message = error.toString();
    }

    const userHasPlanActive = await fetchIfUserHasPlanActive();

    setShowLoadModal(false);
    setShowEditField(false);

    if (!userHasPlanActive) {
      setShowNoAccessModal(true);
    } else {
      setError(message);
      setShowMessageModal(true);
      setTimeout(function () {
        setShowMessageModal(false);
      }, 2000);
    }
  };

  const renderShowLoadModal = () => {
    if (showLoadModal) {
      return <LoadModal show={showLoadModal} />;
    }
  };

  const renderMessageModal = () => {
    if (showMessageModal) {
      return <MessageModal title={error} setVisible={setShowMessageModal} />;
    }
  };

  const renderAddCommentReply = () => {
    if (showAddComment) {
      return (
        <AddCommentReply
          idComment={data.id}
          idRootComment={data.comment_id}
          userName={data.user.name}
          loadListComment={loadListComment}
          setVisible={setShowAddComment}
        />
      );
    }
  };

  const renderListCommentsReply = () => {
    if (showCommentsReply) {
      return <ListCommentsReply commentResponses={data.comment_responses} />;
    }
  };

  const renderViewCommentsReplyButton = () => {
    if (data.length > 0) {
      if (showCommentsReply) {
        return (
          <>
            <Divider />
            <span onClick={() => setShowCommentsReply(!showCommentsReply)}>
              Ocultar respostas
            </span>
          </>
        );
      }

      return (
        <>
          <Divider />
          <span onClick={() => setShowCommentsReply(!showCommentsReply)}>
            Visualizar respostas
          </span>
        </>
      );
    }
  };

  const renderButtonUpdateAndDeleteComment = () => {
    if (userID === data.user_id) {
      return (
        <>
          <Divider />
          <span onClick={() => setShowEditField(!showEditField)}>
            <BiEdit size={20} />
          </span>
          <Divider />
          <span onClick={() => deleteComment()}>
            <MdDelete size={20} />
          </span>
        </>
      );
    }
  };

  const renderContent = () => {
    if (showEditField) {
      return (
        <Row>
          <Input
            value={content}
            onInput={(event) => setContent(event.target.value)}
          />
          <Button onClick={() => updateComment()}>Atualizar</Button>
        </Row>
      );
    }

    return <Text>{content}</Text>;
  };

  const renderNoAccessModal = () => {
    if (showNoAccessModal) {
      return (
        <NoAccessModal
          show={showNoAccessModal}
          setShow={setShowNoAccessModal}
        />
      );
    }
  };

  const renderNumberOfLikes = () => {
    if (data.number_of_likes === 1) {
      return (
        <>
          <Divider />
          <NumberOfLikes onClick={() => handleShowLikeList(data.id)}>
            {renderAdminPhoto()}
            {data.number_of_likes} curtida
          </NumberOfLikes>
        </>
      );
    } else if (data.number_of_likes > 1) {
      return (
        <>
          <Divider />
          <NumberOfLikes onClick={() => handleShowLikeList(data.id)}>
            {renderAdminPhoto()}
            {data.number_of_likes} curtidas
          </NumberOfLikes>
        </>
      );
    }
  };

  const renderLikeListModal = () => {
    if (showLikeList) {
      return (
        <LikesModal
          likes={likeList}
          showLoadingLikeList={showLoadingLikeList}
          setShowLikeList={setShowLikeList}
        />
      );
    }
  };

  const renderAdminPhoto = () => {
    if (data.avatar_adm) {
      return (
        <AdminPhotoContainer>
          <img src={data.avatar_adm_url} alt="Foto de perfil da Pri Leite" />
          <div>
            <FaHeart size={10} color="#FF1E00" />
          </div>
        </AdminPhotoContainer>
      );
    }
  };

  return (
    <Container showAddComment={showAddComment}>
      <ProfilePhoto src={data.user.avatar_url} alt="Foto de perfil" />
      <Informations>
        <Name>{data.user.name}</Name>
        {renderContent()}
        <Row>
          <ReponseAndNumberOfLikesContainer>
            <ResponseTime created_at={data.created_at} />
            {renderNumberOfLikes()}
          </ReponseAndNumberOfLikesContainer>
          <Row2>
            <Divider />
            <LikeButton
              idComment={data.id}
              liked={data.liked}
              like_id={data.like_id}
              setShowLoadModal={setShowLoadModal}
              handleError={handleError}
              loadListComment={loadListComment}
            />
            <Divider />
            <span onClick={() => setShowAddComment(!showAddComment)}>
              <RiReplyLine size={20} />
            </span>
            {renderViewCommentsReplyButton()}
            {renderButtonUpdateAndDeleteComment()}
          </Row2>
        </Row>

        {renderAddCommentReply()}
      </Informations>
      {renderShowLoadModal()}
      {renderMessageModal()}
      {renderNoAccessModal()}
      {renderLikeListModal()}
    </Container>
  );
};

export default Commment;
