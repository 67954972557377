import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

import {
  fecthChannelSeries,
  fecthExclusiveVideoTracks,
} from '../../services/homeService';
import ChannelVideos from './components/ChannelVideos';
import ExclusiveVideoTracks from './components/ExclusiveVideoTracks';
import HighlightsClasses from './components/HighlightsClasses';
import MegaFilter from './components/MegaFilter';
import MegaPlayListMonth from './components/MegaPlayListMonth';
import MyPractice from './components/MyPractice';
import { BoxMain } from './styles';
import { apiResponse } from '../../utils/apiResponse';
import api from '../../services/api';
import LoadModal from '../../components/LoadModal';

const MegaHome = () => {
  const [exclusiveVideoTracks, setExclusiveVideoTracks] = useState([]);
  const [channelSeries, setChannelSeries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasAnActiveSubscription, setHasAnActiveSubscription] = useState(false);

  const history = useHistory();

  useEffect(() => {
    onGetSubscription();
  }, []);

  const onGetSubscription = async () => {
    try {
      setLoading(true);
      const subscription = apiResponse(await api.get('users/plans'));
      setLoading(false);
      if (subscription.status === 'authorized') {
        setHasAnActiveSubscription(true);
        // AULAS Exclusivas
        fecthExclusiveVideoTracks(setExclusiveVideoTracks);
        // Séries do Canal da Pri
        fecthChannelSeries(setChannelSeries);
      } else history.push('/perfil');
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 422) {
        history.push('/perfil');
      }
    }
  };

  return (
    <>
      <BoxMain>
        {hasAnActiveSubscription && (
          <>
            {/* Calendário com a playlist do mês */}
            <MegaPlayListMonth />
            {/* Filtro */}
            <MegaFilter />
            {/* Destaques */}
            <HighlightsClasses />
            {/* AULAS Exclusivas */}
            <ExclusiveVideoTracks exclusiveVideoTracks={exclusiveVideoTracks} />
            {/* Séries do Canal da Pri*/}
            <ChannelVideos channelVideos={channelSeries} />
            {/* Minha prática */}
            <MyPractice />
          </>
        )}

        <LoadModal show={loading} />
      </BoxMain>
    </>
  );
};
export default MegaHome;
