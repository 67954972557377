import React, { useState } from 'react';
import { useEffect } from 'react';

import { Spinner } from '../../../../components/LoadModal/styles';
import api from '../../../../services/api';
import { useDebounce } from '../../../../utils/useDebounce';
import CardResult from '../CardResult';
import searchIcon from './icons/search.svg';
import {
  BoxFilter,
  BoxOptions,
  BoxResults,
  Filter,
  FilterSelect,
  Main,
} from './styles';

const MegaFilter = ({ noBgColor, setModalIsOpen }) => {
  const [keyword, setKeyword] = useState('');
  const [filters, setFilters] = useState([]);
  const [selectedParentFilters, setSelectedParentFilters] = useState({
    filters: [],
  });
  const [selectedChildFilters, setSelectedChildFilters] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [appliedFilter, setAppliedFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);

  const debouncedValue = useDebounce(keyword, 300);

  useEffect(() => {
    getPosts();
  }, [debouncedValue]);

  const toggleShowFilters = () => {
    if (!filters.length) getAllData();
    setShowFilters(!showFilters);
  };

  const toggleFilter = (index) => {
    let oldFilters = filters;
    oldFilters[index].selected = !oldFilters[index].selected;
    setSelectedParentFilters(oldFilters[index]);
    setFilters(oldFilters);
  };

  const toggleFilterSelect = (filter) => {
    let oldSelectedChildFilters = selectedChildFilters;
    const indexFilter = oldSelectedChildFilters.findIndex(
      (item) => item.id === filter.id,
    );

    if (indexFilter === -1) oldSelectedChildFilters.push(filter);
    else oldSelectedChildFilters.splice(indexFilter, 1);

    setSelectedChildFilters(oldSelectedChildFilters);
    getPosts();
  };

  const cleanFilter = () => {
    setSelectedParentFilters({ filters: [] });
    setSelectedChildFilters([]);
    setShowFilters(false);
    setAppliedFilter(false);
    setResults([]);
  };

  const getAllData = async () => {
    let { data } = await api.get('filter-categories?embed%5B%5D=filters');
    filters.forEach((data) => (data.selected = false));
    setFilters(data);
  };

  const urlParams = () => {
    const searchParams = new URLSearchParams();
    const base = 'filters[]';
    if (keyword) searchParams.append('search', keyword);
    selectedChildFilters.forEach((filter) => {
      searchParams.append(base, filter.id);
    });

    return `?${searchParams.toString()}`;
  };

  const getPosts = async (event) => {
    if (event) event.preventDefault();
    if (keyword || selectedChildFilters.length) {
      setLoading(true);
      const { data: results } = await api.get(`posts/search${urlParams()}`);

      const allResults = [
        ...results.lessons,
        ...results.meditation_series,
        ...results.meditations,
        ...results.podcast_series,
        ...results.podcasts,
        ...results.series,
      ];

      setResults(allResults);
      setAppliedFilter(true);
      setLoading(false);
    } else {
      setResults([]);
      setAppliedFilter(false);
    }
  };

  const search = () => {
    return (
      <>
        <BoxFilter>
          <form onSubmit={(event) => getPosts(event)}>
            <input
              value={keyword}
              onChange={({ target }) => setKeyword(target.value)}
              type="search"
              placeholder="Busque pelo título da aula..."
            />
            <img src={searchIcon} alt="Pesquisar" />
          </form>
          {showFilters === false && (
            <button onClick={toggleShowFilters}>ou combine os filtros.</button>
          )}
          {showFilters && (
            <BoxOptions>
              <div>
                {filters.map((filter, index) => {
                  return (
                    <Filter
                      key={filter.id}
                      onClick={() => toggleFilter(index)}
                      selected={filter.id === selectedParentFilters.id}
                    >
                      {filter.name}
                    </Filter>
                  );
                })}
              </div>
              {selectedParentFilters?.name && <hr />}
              <div>
                {selectedParentFilters.filters.map((filter) => {
                  return (
                    <Filter
                      onClick={() => toggleFilterSelect(filter)}
                      key={filter.id}
                    >
                      {filter.name}
                    </Filter>
                  );
                })}
              </div>
              {selectedChildFilters.length > 0 && <hr />}
              <div>
                {selectedChildFilters.map((selectedParentFilters) => {
                  return (
                    <FilterSelect
                      onClick={() => toggleFilterSelect(selectedParentFilters)}
                      selected={true}
                      key={selectedParentFilters.id}
                    >
                      {selectedParentFilters.name}
                    </FilterSelect>
                  );
                })}
              </div>
              <button onClick={cleanFilter}>Fechar filtros</button>
            </BoxOptions>
          )}
        </BoxFilter>
      </>
    );
  };

  const messageResult = () => {
    const totalResult = results.length;
    const s = totalResult > 1 ? `s` : '';
    const hasResult = appliedFilter === true && totalResult > 0;
    const noResult = appliedFilter === true && totalResult === 0;
    return (
      <>
        {hasResult && <h3>{`${results.length} aula${s} encontrada${s}`}</h3>}
        {noResult && <h3>Nenhuma aula encontrada!</h3>}
      </>
    );
  };

  return (
    <>
      <Main noBgColor={noBgColor}>
        {search()}
        {messageResult()}
        {loading && <Spinner />}
        <BoxResults>
          {results.map((result) => (
            <CardResult
              key={result.id}
              result={result}
              setModalIsOpen={setModalIsOpen}
            />
          ))}
        </BoxResults>
      </Main>
    </>
  );
};

export default MegaFilter;
