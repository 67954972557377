import styled from 'styled-components';

export const BoxCards = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
  h2 {
    color: #030303;
    font-family: 'Aller-Regular';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
`;
export const Option = styled.div`
  font-family: 'Aller-Regular';
  font-weight: 800;
  font-size: 14px;
  cursor: pointer;
  text-transform: uppercase;
  margin-bottom: 15px;
`;
