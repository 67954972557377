import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.div`
  font-family: 'Aller-Bold';
  font-size: 1.5rem;
  color: var(--purple84);
  padding: 20px 20px;
  box-shadow: 0px 0px 6px 0px lightgrey;
  max-width: 800px;
  text-align: right;
  margin-left: 0px;
  margin-top: 80px;

  @media (max-width: 560px) {
    text-align: center;
  }
`;

export const Answer = styled.div`
  font-family: 'Aller-Light';
  font-size: 1.3rem;
  text-align: justify;
  color: #6c6c6c;
  margin-top: 20px;
  max-width: 800px;

  @media (max-width: 560px) {
    text-align: center;
  }
`;
