import { useHistory } from 'react-router-dom';

import podcast from '../../../../../../assets/home/podcast.png';
import phone from '../../../../../../assets/home/phone.png';
import watch from '../../../../../../assets/home/watch.png';

import { Container, NameLatestPodcastSerie } from './styles';

const CardLatestPodcastSerieReleased = ({
  featuredPodcast,
  featuredPodcastLessons,
}) => {
  const history = useHistory();
  function renderDuration() {
    const { duration } = featuredPodcast;
    if (duration > 0) {
      if (duration < 60) {
        return `${duration} min`;
      } else {
        const hours = Math.trunc(duration / 60);
        const minutes = Math.trunc(duration - 60 * hours);
        if (hours === 1) {
          return `${hours} hora ${minutes > 0 ? ` e ${minutes} minutos` : ``}`;
        }
        return `${hours} horas ${minutes > 0 ? ` e ${minutes} minutos` : ``}`;
      }
    }
  }

  function renderNumberEpisodes() {
    if (featuredPodcastLessons.length > 1) {
      return `${featuredPodcastLessons.length} episódios`;
    } else if (featuredPodcastLessons.length === 1) {
      return `${featuredPodcastLessons.length} episódio`;
    } else {
      return 'Nenhum episódio';
    }
  }

  function renderComponent() {
    if (JSON.stringify(featuredPodcast) !== '{}') {
      return (
        <Container>
          <NameLatestPodcastSerie
            onClick={() =>
              history.push({
                pathname: `/podcast/${featuredPodcast.id}`,
              })
            }
          >
            {featuredPodcast.title}
          </NameLatestPodcastSerie>
          <img
            src={featuredPodcast.thumbnail_url}
            alt="Thumbnail da série do podcast"
          />
          <div
            onClick={() =>
              history.push({
                pathname: `/podcast/${featuredPodcast.id}`,
              })
            }
          >
            <ul>
              <li>
                <img src={phone} alt="Ícone de fone de ouvi" />
                {renderNumberEpisodes()}
              </li>
              <li>
                <img src={watch} alt="Ícone de relógio" />
                Duração: {renderDuration()}
              </li>
            </ul>
          </div>
        </Container>
      );
    }
  }

  return <>{renderComponent()}</>;
};

export default CardLatestPodcastSerieReleased;
