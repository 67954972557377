import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 420px;
  background-color: #61e171;
  box-shadow: 0px 0px 6px 0px lightgrey;
  border-radius: 4px;
  border: 0px;
  outline: 0;
  padding: 0px 10px;
  margin: 20px 0px;
  font-family: 'Aller-Bold';
  font-size: 2rem;
  color: #fff;
  cursor: pointer;

  transition: all 0.5s;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

export const IconLeft = styled.img`
  margin-right: 10px;
`;
