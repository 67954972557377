import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import api from '../../services/api';

import Header from '../../components/Header';
import Content from '../../components/Content';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Footer from '../../components/Footer';
import LoadModal from '../../components/LoadModal';
import MessageModal from '../../components/MessageModal';

import backIcon from '../../assets/choosePlan/backIcon.png';

import {
  Container,
  GradientBackground,
  FreeTrialMessage,
  Row,
  Tooltip,
} from './styles';

const ChangePassword = () => {
  let query = useQuery();
  const token = query.get('token');

  const dispatch = useDispatch();
  const history = useHistory();

  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });
  const [differentPasswords, setDifferentPasswords] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  function renderTooltip() {
    if (errorMessage !== '') {
      return <Tooltip>{errorMessage}</Tooltip>;
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!formData.password.trim() || !formData.confirmPassword.trim()) {
      setErrorMessage('Preencha a sua senha corretamente');
      return;
    }

    setErrorMessage('');
    setShowLoadModal(true);

    try {
      const response = await api.post('/girassol-user/migrate', {
        token,
        password: formData.password,
        password_confirmation: formData.confirmPassword,
      });

      setShowMessageModal(true);

      setTimeout(function () {
        history.push('/login');
      }, 3000);
    } catch (error) {
      const message = error.response.data?.message;
      setErrorMessage(message);
    }

    setShowLoadModal(false);
  };

  function comparePasswordAndPasswordConfirmation() {
    const password = formData.password;
    const confirmPassword = formData.confirmPassword;

    if (
      password.length > 0 &&
      confirmPassword.length > 0 &&
      password !== confirmPassword
    ) {
      setDifferentPasswords(true);
    } else {
      setDifferentPasswords(false);
    }
  }

  function renderMessageModal() {
    if (showMessageModal) {
      return (
        <MessageModal
          title="Parabéns"
          description="Você já tem acesso a plataforma do Yoga Co."
          type="success"
          iconColor="green"
          setVisible={setShowMessageModal}
        />
      );
    }
  }

  useEffect(() => {
    comparePasswordAndPasswordConfirmation();
  }, [formData.password, formData.confirmPassword]);

  return (
    <Container>
      <Header />
      <GradientBackground />
      <Content>
        <h1>Sou aluno Girassol</h1>
        <form onSubmit={(event) => handleSubmit(event)}>
          <Input
            type="password"
            placeholder="Senha"
            required
            onInput={(event) =>
              setFormData({ ...formData, password: event.target.value })
            }
            error={differentPasswords}
          />
          <Input
            type="password"
            placeholder="Confirmar Senha"
            required
            onInput={(event) =>
              setFormData({ ...formData, confirmPassword: event.target.value })
            }
            error={differentPasswords}
          />

          {renderTooltip()}
          <Button>Criar Senha</Button>
        </form>
        <img
          onClick={() => history.goBack()}
          src={backIcon}
          alt="Ícone de voltar para a página anterior"
        />
      </Content>
      <Footer />
      <LoadModal show={showLoadModal} />
      {renderMessageModal()}
    </Container>
  );
};

export default ChangePassword;
