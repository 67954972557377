import styled from 'styled-components';

export const Container = styled.div`
  /* height: ${(props) =>
    props.detailsOfScheduledLessons.length > 0 ? '560px' : '370px'}; */
  padding: 0px 20px 20px 20px;
  background-color: ${(props) =>
    props.backgroundColor != '' ? '#F2F4F7' : '#fff'};
  box-shadow: 0px 0px 6px 0px lightgrey;
  border-radius: 20px;
  max-width: 520px;
  transition: all 0.5s;

  > p {
    font-family: 'Aller-Regular';
    font-size: 1.5rem;
    color: var(--dark03);
  }

  > span {
    position: absolute;
    right: 40px;
    bottom: 20px;
    font-family: 'Aller-Bold';
    font-size: 2rem;
    color: var(--dark03);
    cursor: pointer;
  }

  @media (max-width: 596px) {
    width: 100% !important;
  }
`;

export const ClassDescription = styled.div`
  > img {
    width: 100%;
    height: 100px;
    object-fit: cover;
  }

  > div {
    display: flex;
  }
`;

export const Date = styled.div``;

export const Hour = styled.div``;
