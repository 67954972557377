import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';

import Description from './components/Description';

import './carousel.css';

import { ContainerCard, Card, CardSkeleton } from './styles';

const Slider3D = ({ route, lessons, loading, podcastOrMeditation = false }) => {
  const history = useHistory();

  const sliderSettings = {
    className: 'center',
    centerMode: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const onLoadCard = (item) => {
    let cards = document.querySelectorAll('img#card-' + item.id);
    for (let card of cards) {
      card.style = 'opacity: 1; height: auto;';
    }

    let items = document.querySelectorAll('div#skeleton-' + item.id);
    for (let card of items) {
      card.style = 'display: none !important';
    }
  };

  const getInformations = (item) => {
    let informations = {
      id: '',
      thumbnail_url: '',
      name: '',
      route,
    };

    const type = item.post.type;

    if (
      type === 'episode' ||
      type === 'podcast' ||
      type === 'meditacao' ||
      type === 'meditation'
    ) {
      informations.id = item.post.episode.id;
      informations.thumbnail_url = item.post.episode.thumbnail_url;
      informations.id = item.post.episode.id;
    } else if (type === 'lesson') {
      informations.id = item.post.lesson.id;
      informations.thumbnail_url = item.post.lesson.thumbnail_url;
      informations.id = item.post.lesson.id;
    } else if (type === 'podcast_series') {
      informations.id = item.post.podcast_series.id;
      informations.thumbnail_url = item.post.podcast_series.thumbnail_url;
      informations.id = item.post.podcast_series.id;
      informations.route = 'podcast';
    } else if (type === 'meditation_series') {
      informations.id = item.post.podcast_series.id;
      informations.thumbnail_url = item.post.podcast_series.thumbnail_url;
      informations.id = item.post.podcast_series.id;
      informations.route = 'meditacao';
    } else {
      informations.id = item.post.series.id;
      informations.thumbnail_url = item.post.series.thumbnail_url;
      informations.id = item.post.series.id;
    }

    return informations;
  };

  function renderSlider() {
    let listCards;

    if (loading) {
      listCards = Array(4)
        .fill()
        .map(() => <CardSkeleton />);
    } else {
      listCards = lessons.map((item) => {
        let informations = getInformations(item);

        return (
          <div>
            <ContainerCard>
              <Card
                podcastOrMeditation={podcastOrMeditation}
                id={'card-' + informations.id}
                onClick={() =>
                  history.push({
                    pathname: `/${informations.route}/${informations.id}`,
                  })
                }
                src={informations.thumbnail_url}
                alt={`${informations.name} - Thumbnail da ${route}`}
                onLoad={() => onLoadCard(informations)}
              />
              <Description
                data={item}
                podcastOrMeditation={podcastOrMeditation}
                route={`/${informations.route}/${informations.id}`}
              />
            </ContainerCard>
            <div id={`skeleton-${informations.id}`}>
              <CardSkeleton />
            </div>
          </div>
        );
      });
    }

    if (lessons.length > 3 || loading) {
      return <Slider {...sliderSettings}>{listCards}</Slider>;
    } else if (lessons.length > 1) {
      return (
        <Slider {...sliderSettings}>
          {listCards}
          {listCards}
        </Slider>
      );
    } else {
      return (
        <Slider {...sliderSettings}>
          {listCards}
          {listCards}
          {listCards}
          {listCards}
        </Slider>
      );
    }
  }

  return <>{renderSlider()}</>;
};

export default Slider3D;
