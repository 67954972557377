import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  > div:nth-of-type(3) {
    max-width: 800px;
    margin-top: 60px;
    margin-bottom: 60px;

    > form {
      > div {
        width: 100%;
      }

      > button {
        width: 100%;
        outline: 0;
      }
    }

    h1 {
      font-family: 'Bartolomeo-Regular';
      font-size: 4rem;
      color: var(--dark03);
      margin: 20px 0px 40px 0px;
    }

    span {
      display: block;
      font-family: 'Roboto-Regular';
      font-size: 1rem;
      color: #38bf5a;
      margin: 40px 0px 20px 0px;
      cursor: pointer;
    }

    label {
      font-family: 'Roboto-Regular';
      font-size: 1.5rem;
      color: #a2a2a2;
      margin-left: 6px;
    }

    input[type='checkbox'] {
      height: 100px;
    }

    > img {
      display: block;
      margin: 40px auto -40px auto;
      cursor: pointer;
    }
  }
`;

export const GradientBackground = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 500px;
  z-index: -1;
  background: rgb(97, 225, 113);
  background: linear-gradient(
    180deg,
    rgba(97, 225, 113, 1) 0%,
    rgba(248, 251, 246, 1) 70%,
    rgba(255, 255, 255, 1) 80%
  );
`;

export const FreeTrialMessage = styled.div`
  font-family: 'Aller-Regular';
  font-size: 1.3rem;
  color: var(--dark03);
  padding: 10px;
  max-width: 280px;
  box-shadow: 0px 0px 6px 0px lightgrey;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
`;

export const Row = styled.div`
  display: flex;
`;

export const Tooltip = styled.div`
  font-family: 'Aller-Regular';
  font-size: 1.2rem;
  margin-top: 20px;
  color: #f00;
  text-align: center;
`;
