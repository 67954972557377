import styled from 'styled-components';

export const BoxMain = styled.div`
  max-width: 1500px;
  min-height: 100vh;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 0.3fr 0.7fr;
  gap: 117px;
  font-family: 'Aller-Regular';
  font-style: normal;
  padding: 25px 0 50px 0;
  margin-bottom: -25px;
  background-color: var(--purpleD1);
  > :first-child {
    padding-left: 50px;
  }
  > :last-child {
    text-align: end;
    img {
      height: 600px;
      object-fit: contain;
    }
  }

  @media (max-width: 550px) {
    grid-template-rows: 2;
    grid-template-columns: 1fr;
    gap: 25px;
    > :last-child {
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  @media (max-width: 1024px) {
    > :last-child {
      img {
        width: 100%;
      }
    }
  }
`;

export const BoxWelcome = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 25px;
  align-items: flex-start;
  h2,
  h3 {
    margin: 0;
    font-size: 36px;
    line-height: 44px;
  }
  h3 {
    font-family: 'Bartolomeo-Regular';
    font-style: normal;
    font-weight: 400;
  }
  h2 {
    font-family: 'Aller-Regular';
    font-weight: 700;
  }
  div {
    width: 78px;
    height: 0px;
    border: 1px solid var(--purpleFF);
  }
  button {
    border: none;
    outline: none;
    background: var(--purple84);
    border-radius: 8px;
    padding: 20px;
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
  }
`;
