import React from 'react';

import Carousel from '../Carousel';
import { Box } from './styles';

function ExclusiveVideoTracks({ exclusiveVideoTracks = [] }) {
  return (
    <>
      {!!exclusiveVideoTracks.length && (
        <Box>
          <Carousel
            titleHightlight="TRILHAS"
            items={exclusiveVideoTracks}
            autoplay={true}
            showControlButtons={true}
          />
        </Box>
      )}
    </>
  );
}

export default ExclusiveVideoTracks;
