import styled from 'styled-components';

export const CardContainer = styled.div`
  font-family: 'Aller-Regular';
  display: flex;
  margin: 2em 0;
  animation: rendering 0.5s;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const Card = styled.div`
  margin: 0 auto;

  width: 300px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;

  @media (max-width: 400px) {
    width: 100%;
  }
`;

export const CardHeader = styled.div`
  cursor: pointer;
  img {
    width: 100%;
    height: auto;
    border-radius: 6px;
  }
`;

export const CardBody = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 15px;
  img {
    height: 18px;
    margin-right: 5px;
    border-radius: 6px;
  }
`;

export const Description = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 15px 2%;
  h1 {
    font-size: 2rem;
    color: var(--dark03);
    cursor: pointer;
  }
  .ant-progress {
    display: flex;
    flex-direction: column;
    padding: 15px 0;
    .ant-progress-outer {
      padding-right: 0px !important;
    }
    .ant-progress-inner {
      border-radius: 0;
      background-color: #eae8e8;
      .ant-progress-bg {
        height: 5px;
        border-radius: 0;
        background-color: #61e171;
      }
    }
    .ant-progress-text {
      color: #b3b1b1;
      margin-left: 0;
      font-size: 1.6rem;
      padding: 15px 0;
    }
  }

  @media (max-width: 900px) {
    text-align: center;
    margin-top: 10px;
  }
`;
