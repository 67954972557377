import styled from 'styled-components';

export const Main = styled.main`
  display: flex;
  gap: 50px;
  align-items: center;
  margin: 50px 0;
  flex-wrap: wrap;
  img {
    width: 156px;
    height: 156px;
    filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.05));
    border-radius: 50%;
    display: block;
    object-fit: cover;
    margin-bottom: 15px;
  }

  :first-child {
    text-align: center;
  }
  h2 {
    color: var(--dark03);
    font-family: 'Aller-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 59px;
    line-height: 71px;
  }

  @media (max-width: 720px) {
    gap: 25px;
    margin: 25px 0;
    h2 {
      font-size: 30px;
      line-height: 31px;
    }
  }
`;

export const UpdateUserPhoto = styled.div`
  font-family: 'Aller-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--grey92);
  cursor: pointer;
  > input[type='file'] {
    display: none;
  }
`;

export const Tooltip = styled.div`
  font-family: 'Aller-Regular';
  font-size: 12px;
  margin-top: 12px;
  color: #f00;
`;
