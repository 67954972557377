import React, { useState, useEffect } from 'react';

import Content from '../../../../components/Content';
import AddComment from './components/AddComment';
import Comment from './components/Comment';

import api from '../../../../services/api';

import { Container, Spinner, ShowMore } from './styles';

const ListComments = ({ lessonInformations, route }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const loadListComment = async (reloadListInTheSameState, addPage) => {
    let endpoint;

    if (reloadListInTheSameState) {
      endpoint = `${route}/${lessonInformations.id}/comments?limit=${
        page * 20
      }`;
    } else if (addPage) {
      endpoint = `${route}/${lessonInformations.id}/comments?page=${
        page + 1
      }&limit=20`;
    } else {
      endpoint = `${route}/${lessonInformations.id}/comments?page=${page}&limit=20`;
    }

    setLoading(true);
    await api
      .get(endpoint)
      .then((response) => {
        if (reloadListInTheSameState) {
          setData(response.data.comments);
        } else {
          setData([...data, ...response.data.comments]);
        }
        setTotal(response.data.total);
        setLoading(false);
      })
      .catch((error) => setLoading(false));
  };

  const renderListComment = () => {
    if (loading && data.length === 0) {
      return <Spinner />;
    } else if (data?.length > 0) {
      return data.map((item) => (
        <Comment key={item.id} data={item} loadListComment={loadListComment} />
      ));
    }
  };

  const handleLoadMoreComments = () => {
    setPage(page + 1);
    loadListComment(false, true);
  };

  const renderShowMoreComments = () => {
    if (loading && data.length > 0) {
      return <Spinner />;
    }

    if (page * 20 < total) {
      return (
        <ShowMore onClick={() => handleLoadMoreComments()}>
          Carregar mais comentários
        </ShowMore>
      );
    }
  };

  useEffect(() => {
    loadListComment();
  }, []);

  return (
    <Container>
      <Content>
        <AddComment
          post_id={lessonInformations.post_id}
          loadListComment={loadListComment}
        />
        {renderListComment()}
        {renderShowMoreComments()}
      </Content>
    </Container>
  );
};

export default ListComments;
