import { formatMoney } from '../../../../../../utils/formatMoney';
import PlanCard from '../PlanCard';
import success from './../../icons/success.svg';
import error from './../../icons/error2.png';
import { Box, SuccessBox } from './styles';
import ButtonLoading from '../../../../../../components/ButtonLoading';
import { MStatusToRenewPlan } from '../../../../../../mocks/MStatusToRenewPlan';
import PlanSubscriptionData from '../../../PlanSubscriptionData';

const UserWithPlan = ({
  subscription = null,
  startOfRegistration = false,
  setStep = null,
}) => {
  const labelSattus = {
    suspended: 'suspenso',
    canceled: 'cancelado',
    expired: 'expirado',
  };
  const controlScreens = () => {
    if (subscription?.status === 'authorized') {
      return <PlanSubscriptionData subscription={subscription} />;
    }
    // if (subscription?.status === 'authorized') {
    //   return (
    //     <Box>
    //       <PlanCard startOfRegistration={false} plan={subscription} />
    //       <SuccessBox>
    //         <h3>Pagamento realizado!</h3>
    //         <img src={success} alt="Pagamento efetuado com sucesso!" />
    //         <div>
    //           {startOfRegistration === true
    //             ? 'Seu pagamento foi realizado com sucesso.'
    //             : 'Seu plano está ativo!'}
    //         </div>
    //         <div>
    //           <b>Valor: </b>
    //           <span>{formatMoney(subscription?.plan_type?.price || '0')}</span>
    //         </div>
    //       </SuccessBox>
    //     </Box>
    //   );
    // }
    else if (MStatusToRenewPlan.includes(subscription?.status)) {
      return (
        <Box>
          {/* {JSON.stringify(subscription.status)} */}

          <PlanCard
            startOfRegistration={false}
            plan={subscription}
            showPlanDates={false}
          />
          <SuccessBox>
            <h3>Seu plano está {labelSattus[subscription?.status]}!</h3>
            <img
              className="img-suspended"
              src={error}
              alt={`Seu plano está suspenso ${
                labelSattus[subscription?.status]
              }`}
            />
            <div>
              <b>Valor: </b>
              <span>{formatMoney(subscription?.plan_type?.price || '0')}</span>
            </div>
            <span onClick={() => setStep(1)}>
              <ButtonLoading type="button" label="Comprar plano anual" />
            </span>
          </SuccessBox>
        </Box>
      );
    } else if (
      subscription?.status === 'pending' &&
      subscription?.payment_method === 'pix'
    ) {
      return (
        <>
          <div>
            <h2>Pagamento pendente!</h2>
            <div>Verifique seu e-mail e conclua o pagamento via PIX.</div>
          </div>
        </>
      );
    } else if (
      subscription?.status === 'pending' &&
      subscription?.payment_method === 'bank_slip'
    ) {
      return (
        <>
          <div>
            <h2>Pagamento pendente!</h2>
            <div>Verifique seu e-mail e conclua o pagamento via boleto.</div>
          </div>
        </>
      );
    }
  };

  return <>{controlScreens()}</>;
};

export default UserWithPlan;
