import styled from 'styled-components';

export const Box = styled.div`
  flex: 1;
  display: flex;
  gap: 50px;
  flex-wrap: wrap;
  @media (max-width: 720px) {
    flex-direction: column-reverse;
    h3 {
      font-size: 17px;
      line-height: 18px;
    }
  }
`;

export const SuccessBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    text-transform: uppercase;
  }
  span {
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
  }

  b {
    font-weight: 700;
  }

  .img-suspended {
    height: 200px;
    object-fit: contain;
  }

  @media (max-width: 720px) {
    flex: 1;
    h3 {
      font-size: 17px;
      line-height: 18px;
    }
  }
`;
