import { useEffect, useState } from 'react';

import { Spinner } from '../../../../components/LoadModal/styles';
import MegaCreditCard from '../../../../components/MegaCreditCard';
import { getCard } from '../../../../utils/getCard';
import CreditCardForm from './CreditCardForm';
import { BoxCards, Option } from './styles';

const MyCards = ({ setCreditCardToken = null }) => {
  const [card, setCard] = useState(null);
  const [loading, setLoading] = useState(false);
  const [registerNewCard, setRegisterNewCard] = useState(false);
  const isUpdate = card?.display_number ? true : false;
  const showForm = (registerNewCard || !isUpdate) && !loading;
  useEffect(() => {
    onGetCard();
  }, []);

  const onGetCard = async () => {
    setLoading(true);
    await getCard(setCard);
    setLoading(false);
  };

  useEffect(() => {
    if (setCreditCardToken && card?.external_id) {
      setCreditCardToken(card.external_id);
      setRegisterNewCard(false);
    } else if (card?.external_id) setRegisterNewCard(false);
  }, [card]);

  return (
    <>
      <BoxCards>
        {/* <pre>{JSON.stringify(card)}</pre> */}
        {loading && <Spinner />}
        <MegaCreditCard creditCard={card} />

        {!isUpdate && !loading && (
          <h2>Você ainda não possui nenhum cartão cadastrado</h2>
        )}

        {isUpdate && (
          <Option onClick={() => setRegisterNewCard(!registerNewCard)}>
            Novo cartão
          </Option>
        )}

        {showForm && <CreditCardForm setCurrentCard={setCard} oldCard={card} />}
      </BoxCards>
    </>
  );
};

export default MyCards;
