import React, { useState } from 'react';
import MessageModal from '../../../../../../components/MessageModal';

import { Container, Title, Description, Promotion } from './styles';

const Card = ({ plan }) => {
  const [showMessageModal, setShowMessageModal] = useState(false);

  function renderShowMessageModal() {
    if (showMessageModal) {
      return (
        <MessageModal
          setVisible={setShowMessageModal}
          title="Você escolheu o pagamento
      Mensal."
          description="Você escolheu o Plano Mensal,  este plano é recorrente,
      caso escolha pagar com cartão de crédito, esse pagamento
      será cobrado automaticamente todo mês.

      Mas não se preocupe, você poderá cancelar sua
      assinatura a qualquer momento!"
        />
      );
    }
  }

  function renderTitle() {
    if (plan.name === 'Plano Anual') {
      return <span>Anual</span>;
    } else {
      return <span>Mensal</span>;
    }
  }

  function renderPrice() {
    if (plan.name === 'Plano Anual') {
      return (
        <>
          <Description>
            <div>
              De <s>R$ 899,90 / Ano</s>
            </div>
          </Description>
          <Promotion>
            <div>Por apenas:</div>
            <div>R$ {plan.price.replace('.', ',')} / Ano</div>
          </Promotion>
        </>
      );
    } else {
      return (
        <>
          {' '}
          <Description>
            <div>
              De <s>R$ 79,90 / Mês</s>
            </div>
          </Description>
          <Promotion>
            <div>Por apenas:</div>
            <div>R$ {plan.price.replace('.', ',')} / Mês</div>
          </Promotion>
        </>
      );
    }
  }

  return (
    <Container onClick={() => setShowMessageModal(true)}>
      <Title>
        PLANO
        {renderTitle()}
      </Title>
      {renderPrice()}
      {renderShowMessageModal()}
    </Container>
  );
};

export default Card;
