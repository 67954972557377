import styled from 'styled-components';

export const Remove = styled.div`
  display: flex;
  justify-content: flex-end;
  span {
    cursor: pointer;
    text-transform: uppercase;
    font-size: 12px !important;
    padding: 2px 5px;
    border: 3px solid #fff;
    border-radius: 9px;
  }
`;

export const Container = styled.div`
  &.card {
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
      align-items: center;
    }

    > div:nth-of-type(1) {
      cursor: pointer;
    }

    border: 0;
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const Informations = styled.div`
  width: 310px;
  height: 200px;
  border-radius: 20px;
  background-color: #8430ca;
  margin-top: 40px;
  padding: 20px;
  transition: 1s all;

  span {
    font-family: 'Aller-Regular';
    font-size: 1.2rem;
    color: #fff;
  }

  > div:nth-of-type(1) {
    justify-content: flex-end;
  }

  > div:nth-of-type(2) {
    justify-content: space-between;
    margin-top: 40px;
  }

  > div:nth-of-type(3) {
    justify-content: space-between;
    margin-top: 10px;
  }

  img {
    height: 40px;
    margin-bottom: 40px;
  }
`;
