import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { HiCheck } from 'react-icons/hi';

import CheckIfClassCompleted from '../../../../components/CheckIfClassCompleted';
import FavoriteButton from '../../../../components/FavoriteButton';
import Scheduling from '../../../../components/Scheduling';

import thumbnailClass from '../../../../assets/serie/thumbnail-class.png';
import time from '../../../../assets/serie/time.png';
import favorite from '../../../../assets/serie/favorite.png';
import calendar from '../../../../assets/serie/calendar.png';

import {
  Container,
  Thumbnail,
  Informations,
  ClassAttendedIcon,
  TitleContainer,
  Description,
  Options,
  ContainerCheckedIconDesktop,
  ContainerCheckedIconMobile,
  MenuMobile,
} from './styles';

const Class = ({
  item,
  pathname,
  setShowLoadModal,
  setShowNoAccessModal,
  canAccessPaidContent,
}) => {
  const user = useSelector((state) => state.User);
  const history = useHistory();
  const [assistedClass, setAssistedClass] = useState(false);
  const [showSchedulingComponent, setShowSchedulingComponent] = useState(false);

  const handleSchedule = async () => {
    setShowSchedulingComponent(true);
  };

  function renderAssistedClassIcon() {
    return (
      <ContainerCheckedIconDesktop>
        <CheckIfClassCompleted
          completed={item.is_completed}
          post_id={item.post_id}
          setShowLoadModal={setShowLoadModal}
          canAccessPaidContent={canAccessPaidContent}
        />
      </ContainerCheckedIconDesktop>
    );
  }

  function renderDuration() {
    const { duration } = item;
    if (duration > 0) {
      if (duration < 60) {
        return (
          <div>
            <img src={time} alt="Ícone de tempo da aula" />
            <span>{duration} min</span>
          </div>
        );
      } else {
        const hours = Math.trunc(duration / 60);
        const minutes = Math.trunc(duration - 60 * hours);
        return (
          <div>
            <img src={time} alt="Ícone de tempo da aula" />
            <span>
              {hours} {hours === 1 ? 'hora' : 'horas'}
              {minutes > 0 && ` e ${minutes} minutos`}
            </span>
          </div>
        );
      }
    }
  }

  const handleCard = () => {
    if (pathname.includes('podcast')) {
      history.push({
        pathname: `/podcast/aula/${item.id}`,
      });
    } else if (pathname.includes('meditacao')) {
      history.push({
        pathname: `/meditacao/aula/${item.id}`,
      });
    } else {
      history.push({
        pathname: `/aula/${item.id}`,
      });
    }
  };

  function renderSchedulingComponent() {
    if (showSchedulingComponent) {
      return (
        <Scheduling
          type="aula"
          show={showSchedulingComponent}
          setShow={setShowSchedulingComponent}
          postId={item.post_id}
        />
      );
    }
  }

  return (
    <Container>
      <div>
        <Thumbnail
          onClick={() => handleCard()}
          src={item.thumbnail_url}
          alt="Thumbnail da aula"
        />
        <div>
          {renderAssistedClassIcon()}
          <Informations>
            <TitleContainer onClick={() => handleCard()}>
              <div>{item.name}</div>
            </TitleContainer>
            <MenuMobile>
              <CheckIfClassCompleted
                completed={item.is_completed}
                post_id={item.post_id}
                setShowLoadModal={setShowLoadModal}
                canAccessPaidContent={canAccessPaidContent}
              />
              <FavoriteButton
                post_id={item.post_id}
                is_favorite={item.is_favorite}
                favorite_id={item.favorite_id}
                setShowLoadModal={setShowLoadModal}
                canAccessPaidContent={canAccessPaidContent}
              />
              <img src={calendar} onClick={() => handleSchedule()} />
            </MenuMobile>
            <Description>
              {renderDuration()}
              <p>{item.description}</p>
            </Description>
          </Informations>
        </div>
      </div>
      <Options>
        <FavoriteButton
          post_id={item.post_id}
          is_favorite={item.is_favorite}
          favorite_id={item.favorite_id}
          setShowLoadModal={setShowLoadModal}
          canAccessPaidContent={canAccessPaidContent}
        />
        <img src={calendar} onClick={() => handleSchedule()} />
      </Options>
      {renderSchedulingComponent()}
    </Container>
  );
};

export default Class;
