import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.menu`
  display: ${(props) => (props.showFilter ? 'flex' : 'none')};
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  padding: 30px 60px 10px 0px;
  background-color: #fff;
  transition: all 1s;
  @media (max-width: 55.625rem) {
    width: 600px;
  }
  @media (max-width: 42.5rem) {
    width: 90vw;
    padding: 60px 20px 10px 20px;
  }
`;

export const LinkPage = styled.div`
  text-decoration: none;
  color: inherit;
`;
