import styled from 'styled-components';

export const Informations = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  p {
    margin: 0;
  }
  b {
    font-weight: 700;
    margin-bottom: 40px;
    display: block;
  }
  div {
    margin-bottom: 15px;
    img {
      margin-right: 10px;
    }
  }

  @media (max-width: 720px) {
    font-size: 13px;
    line-height: 16px;
  }
`;
