import { useEffect, useState } from 'react';

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebauncedValue] = useState(value);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebauncedValue(value);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [value]);

  return debouncedValue;
};
