import { useEffect } from 'react';
import PlanCard from '../PlanCard';
import { BoxCardTransactionFailure } from './styles';

const CardTransactionFailure = ({ setStep = () => {} }) => {
  const messageError = localStorage.getItem('messageError');

  useEffect(() => {
    return () => {
      localStorage.removeItem('messageError');
    };
  });

  return (
    <BoxCardTransactionFailure>
      <PlanCard startOfRegistration={true} />
      <div className="informations">
        <h1>Ops! Temos um problema...</h1>
        <p>
          O pagamento não foi processado corretamente.{' '}
          {messageError
            ? messageError
            : `Falha na comunicação com
          o cartão.`}
        </p>
        <button onClick={() => setStep(1)}>Tentar novamente</button>
      </div>
    </BoxCardTransactionFailure>
  );
};

export default CardTransactionFailure;
