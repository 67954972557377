import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;

  display: grid;
  place-content: center;

  > div:nth-of-type(2) {
    img {
      display: block;
      margin: 0 auto;
    }

    h1 {
      font-family: 'Aller-Bold';
      font-size: 2rem;
      color: var(--dark03);
      margin: 40px;
      text-align: center;
    }
    @media (max-width: 368px) {
      padding: 20px 5px;
    }
  }

  > div:nth-of-type(3) {
    position: absolute;
    bottom: 0px;
  }
`;
