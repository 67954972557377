function logoutUserAutomatically() {
  const yogaAdmUser = localStorage.getItem('YogaADM@User');
  const yogaCoUser = localStorage.getItem('YogaCo@User');
  const yogaCoWebUser = localStorage.getItem('YogaCoWeb@User');

  if (
    (yogaAdmUser !== null && yogaAdmUser !== undefined) ||
    (yogaCoUser !== null && yogaCoUser !== undefined) ||
    (yogaCoWebUser !== null && yogaCoWebUser !== undefined)
  ) {
    localStorage.removeItem('YogaADM@User');
    localStorage.removeItem('YogaCo@User');
    localStorage.removeItem('YogaCoWeb@User');
    return true;
  }

  return false;
}

export default logoutUserAutomatically;
