import axios from 'axios';

import { REACT_APP_API } from './../settings';

import Store from '../redux';

const api = axios.create({
  baseURL: REACT_APP_API,
});

api.interceptors.request.use(async (config) => {
  const token = Store.getState().User.accessToken;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
