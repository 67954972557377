import api from '../services/api';
import { apiErrorMessage } from './apiErrorMessage';
import { apiResponse } from './apiResponse';

export const getCard = async (setCard = null) => {
  try {
    const result = apiResponse(await api.get('cards'));
    const resultInArray = Object.values(result);
    if (resultInArray.length) {
      if (setCard) setCard(resultInArray[resultInArray.length - 1]);
      return resultInArray[resultInArray.length - 1];
    }
    return null;
  } catch (error) {
    apiErrorMessage(error);
  }
};
