import styled from 'styled-components';

export const Container = styled.div`
  background-color: #f8f8f8;
`;

export const Spinner = styled.div`
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #61e171;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1.2s linear infinite;
  margin: 0 auto;
`;

export const ShowMore = styled.p`
  font-family: 'Aller-Regular';
  font-size: 1.4rem;
  color: var(--dark03);
`;
