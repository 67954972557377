import { useHistory } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { Container, DateComponent, Note, Title, Description } from './styles';

const Card = ({ note }) => {
  const history = useHistory();

  return (
    <Container onClick={() => history.push(`/editar-meu-diario/${note.id}`)}>
      <DateComponent>
        {formatDistance(new Date(note.created_at), new Date(), {
          locale: ptBR,
        })}
      </DateComponent>
      <Note>
        <Title>{note.title}</Title>
        <Description>{note.description}</Description>
      </Note>
    </Container>
  );
};

export default Card;
