export const REACT_APP_API = 'https://api.yogaco.com.br';
// export const REACT_APP_API = 'http://fabricio-localhost.loca.lt';
// export const REACT_APP_API =
//   'https://ebea-2804-14c-155-9307-bbf2-8430-3b5-c57a.ngrok-free.app';
export const REACT_APP_SINGLE_MEDITATIONS =
  'b9052888-b4e3-4ad9-9042-fe3d9ad0cb1e';
export const REACT_APP_SINGLE_EXCLUSIVE_MEDITATIONS =
  '87c79ece-b48b-407b-8ad4-c5df1f421ad4';
export const REACT_APP_SINGLE_LESSONS_EXCLUSIVES =
  '4647caf1-fc96-4898-bad4-7f7c86327b84';
export const REACT_APP_SINGLE_LESSONS_CHANNEL =
  'b93a4bac-10dd-48af-ba15-585bcf894fd5';
export const REACT_APP_EXCLUSIVE_PODCAST_EXCLUSIVE =
  '84e70cc7-89fd-4b98-b40a-e61ec5b70e7c';
export const REACT_APP_IUGU_ACCOUNT = '016A7E73F06F778661CB254380B042DE';
// export const WHATSAPP_CONTACT_NUMBER = '5547991274063';
export const WHATSAPP_CONTACT_NUMBER = 'http://wa.link/lx4nb9';
export const CONTACT_EMAIL = 'ajuda@yogaco.com.br';
export const IUGU_TEST_MODE = false;
export const PRE_REGISTRATION_CAPTURE_LINK =
  'https://materiais.yogaco.com.br/yogaco365';
