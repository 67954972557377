export const MPaymentStatus = {
  pending: {
    label: 'Pendente',
    class: 'text-secondary font-italic font-weight-bold',
  },
  paid: {
    label: 'Pago',
    class: 'text-dark',
  },
  expired: {
    label: 'Expirada',
    class: 'text-warning',
  },
  canceled: {
    label: 'Cancelada',
    class: 'text-danger',
  },
  refunded: {
    label: 'Estornada',
    class: 'text-info',
  },
};

// pending = 'pending',
// paid = 'paid',
// expired = 'expired',
// canceled = 'canceled',
// refunded = 'refunded',
