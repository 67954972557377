import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 6px;
  max-width: 732px;
  background: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  margin-top: -20px;

  > div:nth-child(1) {
    background-color: ${(props) =>
      props.currentFilter === 'videolength' ? '#E9FFEC' : 'transparent'};
    color: ${(props) =>
      props.currentFilter === 'videolength'
        ? 'var(--dark03)'
        : 'var(--dark03)'};
  }
  > div:nth-child(2) {
    background-color: ${(props) =>
      props.currentFilter === 'level' ? '#F6EBFF' : 'transparent'};
    color: ${(props) =>
      props.currentFilter === 'level' ? '#8430CA' : 'var(--dark03)'};
  }
  > div:nth-child(3) {
    background-color: ${(props) =>
      props.currentFilter === 'focus' ? '#FDFBE9' : 'transparent'};
    color: ${(props) =>
      props.currentFilter === 'focus' ? '#F1B000' : 'var(--dark03)'};
  }
  > div:nth-child(4) {
    background-color: ${(props) =>
      props.currentFilter === 'category' ? '#FFEBEB' : 'transparent'};
    color: ${(props) =>
      props.currentFilter === 'category' ? '#F85C5C' : 'var(--dark03)'};
  }
  > div:nth-child(5) {
    background-color: ${(props) =>
      props.currentFilter === 'teacher' ? '#E9FFEC' : 'transparent'};
    color: ${(props) =>
      props.currentFilter === 'teacher' ? 'var(--dark03)' : 'var(--dark03)'};
  }

  @media (max-width: 810px) {
    margin-top: 20px;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: 'Aller-Light';
  font-size: 1rem;
  padding: 8px;
  margin: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #bdbaba;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.5s;

  > img {
    margin-left: 20px;
    height: 8px;
  }
`;
