import styled from 'styled-components';

export const Container = styled.div`
  > img {
    display: block;
    margin: 10px auto;
  }

  > button {
    font-family: 'Aller-Regular';
    font-size: 1.6rem;
    color: #939393;
    background-color: #fff;
  }

  @media (max-width: 1024px) {
    margin-top: 40px;
  }

  @media (max-width: 560px) {
    width: 100%;
    > button {
      margin-top: 40px;
      width: 100%;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const AddButton = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #8430ca;
  text-align: center;

  > span {
    display: block;
    font-family: 'Aller-Light';
    font-size: 3rem;
    color: #fff;
    margin-left: 0px;
    margin-top: -8px;
  }
`;

export const AddCard = styled.span`
  font-family: 'Aller-Regular';
  font-size: 1.4rem;
  color: var(--dark03);
  margin-left: 10px;
`;

export const Tooltip = styled.div`
  font-family: 'Aller-Regular';
  font-size: 1.2rem;
  margin-top: 20px;
  color: #f00;
  text-align: center;
`;
