import styled from 'styled-components';

export const Box = styled.div`
  font-family: 'Aller-Regular';
  box-shadow: 1px 2px 4px rgb(0 0 0 / 5%);
  & div {
    background-color: var(--lightFB);
    padding: 10px;
    border-radius: 9px;
    margin-bottom: 10px;
  }
`;
