import React, { useEffect, useState } from 'react';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';

import Class from '../Class';

import { Container, Pagination, NumberOfPage } from './styles';

const ListClass = ({
  lessons,
  pathname,
  setShowLoadModal,
  setShowNoAccessModal,
  canAccessPaidContent,
}) => {
  function renderLessons() {
    return lessons.map((item) => (
      <Class
        item={item}
        pathname={pathname}
        setShowLoadModal={setShowLoadModal}
        setShowNoAccessModal={setShowNoAccessModal}
        canAccessPaidContent={canAccessPaidContent}
      />
    ));
  }

  return <Container>{renderLessons()}</Container>;
};

export default ListClass;
