function onlyNumbers(value) {
  if (value) {
    value = value.replace(/\D/g, '');
    return value;
  }
  return '';
}

export function cep(e) {
  e.currentTarget.maxLength = 9;
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{5})(\d)/, '$1-$2');
  e.currentTarget.value = value;
  return e;
}

export function creditCard(e) {
  e.currentTarget.maxLength = 19;
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{4})(\d)/, '$1-$2');
  value = value.replace(/(\d{4})(\d)/, '$1-$2');
  value = value.replace(/(\d{4})(\d)/, '$1-$2');
  e.target.rawValue = onlyNumbers(value);
  e.currentTarget.value = value;
  return e;
}

export function cardExpiration(e) {
  e.currentTarget.maxLength = 7;
  let value = e.currentTarget.value;
  if (!value.match(/^(\d{2})-(\d{4})$/)) {
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{2})(\d)/, '$1/$2');
    value = value.replace(/(\d{4})(\d)/, '$1-$2');
    e.target.rawValue = onlyNumbers(value);
    e.currentTarget.value = value;
  }
  return e;
}

// export function cardExpiration(e) {
//   let value = e.currentTarget.value;
//   value.replace(/\D/g, '');
//   value = value.slice(0, 5);
//   value = value.replace(/(\d{2})(\d)/, '$1/$2');
//   value = value.replace(/(\d{2})(\d)/, '$1-2');
//   e.currentTarget.value = value;
//   if (!e.currentTarget.value.match(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/))
//     e.currentTarget.isValid = false;
//   else e.currentTarget.isValid = true;
//   e.currentTarget.rawValue = onlyNumbers(e.currentTarget.value);
//   return e;
// }

export function cvv(e) {
  e.currentTarget.maxLength = 3;
  let value = e.currentTarget.value;
  if (!value.match(/^(\d{3})$/)) {
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{3})(\d)/, '$1/$2');
    e.target.rawValue = onlyNumbers(value);
    e.currentTarget.value = value;
  }
  return e;
}

export function currency(e) {
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d)(\d{2})$/, '$1,$2');
  value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');

  e.currentTarget.value = value;
  return e;
}

export function cpf(e) {
  e.currentTarget.maxLength = 14;
  let value = e.currentTarget.value;
  if (!value.match(/^(\d{3}).(\d{3}).(\d{3})-(\d{2})$/)) {
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d{2})$/, '$1-$2');
    e.target.rawValue = onlyNumbers(value);
    e.currentTarget.value = value;
  }
  return e;
}

export function birthDate(e) {
  e.currentTarget.maxLength = 10;
  let value = e.currentTarget.value;
  if (!value.match(/^(\d{2}).(\d{2}).(\d{4})$/)) {
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{2})(\d)/, '$1.$2');
    value = value.replace(/(\d{4})(\d)/, '$1.$2');
    value = value.replace(/(\d{4})(\d)/, '$1-$2');
    e.currentTarget.value = value;
  }
  return e;
}
