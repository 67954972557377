import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import api from '../../services/api';

import LoadModal from '../../components/LoadModal';

import logo from '../../assets/header/logo.png';
import profile from '../../assets/sideBar/profile.png';
import favorite from '../../assets/sideBar/favorite.png';
import diary from '../../assets/sideBar/diary.png';
import calendar from '../../assets/sideBar/calendar.png';
import settings from '../../assets/sideBar/settings.png';
import payment from '../../assets/sideBar/payment.png';
import lessons from '../../assets/sideBar/lessons.png';
import gift from '../../assets/sideBar/gift.png';
import exit from '../../assets/sideBar/exit.png';
import contact from '../../assets/sideBar/contact.png';
import commonQuestions from '../../assets/sideBar/common-questions.png';
import about from '../../assets/sideBar/about.png';
import notifications from '../../assets/sideBar/notifications.png';

import { Container, Row, MenuIcon, Logo } from './styles';

const SideBar = ({ visible, setVisible }) => {
  const dispatch = useDispatch();
  const componentRef = useRef();
  const history = useHistory();
  const user = useSelector((state) => state.User);
  const [showLoadModal, setShowLoadModal] = useState(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleClick = (e) => {
    if (!componentRef.current.contains(e.target)) {
      setVisible(false);
      return;
    }
  };

  const fetchIfUserHasDiaryColor = async () => {
    const userHasDiaryColor = await api
      .get(`/users/${user.id}`)
      .then((response) => {
        if (response.data.diary_color) {
          return true;
        }

        return false;
      })
      .catch((error) => {
        return false;
      });

    return userHasDiaryColor;
  };

  const handleClickMyDiary = async () => {
    if (user.id.trim() === '' || user.accessToken.trim() === '') {
      history.push('/login');
      return;
    }

    setShowLoadModal(true);
    const userHasDiaryColor = await fetchIfUserHasDiaryColor();
    setShowLoadModal(false);

    if (userHasDiaryColor) {
      history.push('/meu-diario');
    } else {
      history.push('/criar-meu-diario');
    }
  };

  const logout = () => {
    dispatch({ type: 'yoga-adm/user/logout' });
    history.push('/login');
  };

  const renderLoadModal = () => {
    if (showLoadModal) {
      return <LoadModal show={showLoadModal} />;
    }
  };

  return (
    <Container visible={visible} ref={componentRef}>
      <Row>
        <MenuIcon onClick={() => setVisible(false)}>
          <div></div>
          <div></div>
          <div></div>
        </MenuIcon>
        <Logo
          onClick={() => history.push('/')}
          src={logo}
          alt="Logo do Yoga CO."
        />
      </Row>
      <ul>
        <li>
          <Link to="/perfil">
            <img src={profile} alt="Ícone da tela de perfil" />
            <span>Perfil</span>
          </Link>
        </li>
        <li>
          <Link to="/minhas-aulas">
            <img src={lessons} alt="Ícone da tela de aulas" />
            <span>Minhas aulas</span>
          </Link>
        </li>
        <li>
          <Link to="/favoritos">
            <img src={favorite} alt="Ícone da tela de favoritos" />
            <span>Favoritos</span>
          </Link>
        </li>
        <li>
          <img
            onClick={() => handleClickMyDiary()}
            src={diary}
            alt="Ícone da tela de diário"
          />
          <span onClick={() => handleClickMyDiary()}>Meu diário</span>
        </li>
        <li>
          <Link to="/calendario">
            <img src={calendar} alt="Ícone da tela de calendário" />
            <span>Calendário</span>
          </Link>
        </li>
        <li>
          <Link to="/configuracao">
            <img src={settings} alt="Ícone da tela de configurações" />
            <span>Configurações</span>
          </Link>
        </li>
        <li>
          <Link to="/notificacoes">
            <img src={notifications} alt="Ícone da tela de notificações" />
            <span>Notificações</span>
          </Link>
        </li>
        <li>
          <Link to="/perfil">
            <img src={payment} alt="Ícone da tela de pagamento" />
            <span>Pagamento</span>
          </Link>
        </li>
        <li>
          <Link to="/presenteie-um-amigo">
            <img src={gift} alt="Ícone da tela de presentear" />
            <span>Presentear</span>
          </Link>
        </li>
        <li>
          <Link to="/sobre-nos">
            <img src={about} alt="Ícone da tela de informações do Yoga CO." />
            <span>Sobre</span>
          </Link>
        </li>
        <li>
          <Link to="/perguntas-frequentes">
            <img
              src={commonQuestions}
              alt="Ícone da tela de perguntas frequentes"
            />
            <span>Perguntas frequentes</span>
          </Link>
        </li>
        <li>
          <Link to="/contato">
            <img src={contact} alt="Ícone da tela de fale conosco" />
            <span>Fale conosco</span>
          </Link>
        </li>
        <li onClick={() => logout()}>
          <img src={exit} alt="Ícone de sair do Yoga CO." />
          <span>Sair</span>
        </li>
      </ul>
      {renderLoadModal()}
    </Container>
  );
};

export default SideBar;
