/**
 * Returns the value of a specified key in the given data object.
 *
 * @param {object} data - The data object to search: const exampleData = {
  pending: {
    label: 'Pendente',
    class: 'text-warning',
  },
}.
 * @param {string} key - The key to search for in the data object.
 * @param {boolean} [resolveClass=false] - Optional. If true, returns the class value of the key, otherwise returns the label value.
 * @return {string} - The value of the specified key in the data object, or an empty string if the key is not found.
 */
export const resolverStatusOrClass = (data, key, resolveClass = false) => {
  if (key in data) return resolveClass ? data[key].class : data[key].label;
  return '';
};
