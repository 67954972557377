import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';

import Header from '../../components/Header';
import Content from '../../components/Content';
import ListPlans from '../../components/ListPlans';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Footer from '../../components/Footer';
import LoadModal from '../../components/LoadModal';
import MessageModal from '../../components/MessageModal';

import check from '../../assets/changePlan/check.png';
import gift from '../../assets/choosePlan/gift.png';
import backIcon from '../../assets/choosePlan/backIcon.png';

import {
  Container,
  GradientBackground,
  FreeTrialMessage,
  Title,
  Description,
  Benefits,
  Gift,
  GiftForFriend,
  IWasPresented,
  Row,
  Tooltip,
} from './styles';

const ChoosePlan = () => {
  const user = useSelector((state) => state.User);
  const history = useHistory();
  const [errorMensage, setErrorMensage] = useState('');
  const [code, setCode] = useState('');
  const [showLoadModal, setShowLoadModal] = useState();
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [userHasUsedTheFree7Days, setUserHasUsedTheFree7Days] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    checkIfUserHasUsedTheFree7Days();
  }, []);

  const checkIfUserHasUsedTheFree7Days = async () => {
    if (!user.accessToken.trim()) {
      setUserHasUsedTheFree7Days(false);
      return;
    }

    try {
      const response = await api.get(`/users/plans`);
      setUserHasUsedTheFree7Days(true);
    } catch (error) {
      setUserHasUsedTheFree7Days(false);
    }
  };

  const iWasPresented = async () => {
    if (user.accessToken.trim() === '' || user.id.trim() === '') {
      setErrorMensage(
        'Para usar um código gerado, você deve primeiro estar logado',
      );
      return;
    }

    if (code.trim() === '') {
      setErrorMensage('Digite o código corretamente');
      return;
    }

    setErrorMensage('');
    setShowLoadModal(true);

    await api
      .post('/gift-code/apply', {
        code,
      })
      .then((response) => {
        setShowLoadModal(false);
        setShowMessageModal(true);

        setTimeout(function () {
          setShowLoadModal(false);
          history.push('/');
        }, 8000);
      })
      .catch((error) => handleError(error));
  };

  const handleError = async (error) => {
    let message;
    if (error.response.data?.message) {
      message = error.response.data.message;
    } else {
      message = error.toString();
    }

    setShowLoadModal(false);
    setErrorMensage(message);
  };

  const handleClickButton = () => {
    const plan = localStorage.getItem('YogaCO@ChoosedPlan');

    if (plan === null || plan === '') {
      setErrorMensage('Escolha um plano para poder prosseguir');
    } else if (
      user.id === undefined ||
      user.accessToken === undefined ||
      user.externalID === undefined ||
      user.id === '' ||
      user.accessToken === '' ||
      user.externalID === ''
    ) {
      history.push('/cadastro');
    } else {
      history.push('/pagamento');
    }
  };

  function renderErrorMensage() {
    if (errorMensage) {
      return <Tooltip>{errorMensage}</Tooltip>;
    }
  }

  const renderLoadModal = () => {
    if (showLoadModal) {
      return <LoadModal show={showLoadModal} />;
    }
  };

  const renderMessageModal = () => {
    if (showMessageModal) {
      return (
        <MessageModal
          title="Parabéns"
          subtitle=" Você acabou de receber o seu presente. Pedimos que aguarde um momento até recebermos a confirmação do pagamento, e assim você poderá desfrutar da Yoga Co"
          setVisible={setShowMessageModal}
          iconColor="green"
        />
      );
    }
  };

  const renderFreeTrialMessage = () => {
    // if (!userHasUsedTheFree7Days) {
    //   return <FreeTrialMessage>TESTE GRÁTIS POR 7 DIAS</FreeTrialMessage>;
    // }
  };

  const renderTextButton = () => {
    if (!userHasUsedTheFree7Days) {
      return 'Experimente Grátis e assine';
    }

    return 'Assinar';
  };

  return (
    <Container>
      <GradientBackground />

      <Header />

      <Content>
        {/* {renderFreeTrialMessage()} */}

        <Title>
          <h1>Escolha um plano</h1>
          <span>E ACESSE AGORA</span>
        </Title>

        <Description>
          <div>Apoie o canal da Pri e tenha acesso a:</div>
          <Benefits>
            <div>
              <img src={check} alt="Ícone de Ok" />
              <span>+ de 300 aulas do canal da Pri</span>
            </div>
            <div>
              <img src={check} alt="Ícone de Ok" />
              <span>+ de 50 aulas exclusivas</span>
            </div>
          </Benefits>

          <Benefits>
            <div>
              <img src={check} alt="Ícone de Ok" />
              <span>+ 30 Podcasts</span>
            </div>
            <div>
              <img src={check} alt="Ícone de Ok" />
              <span>+ 10 meditações</span>
            </div>
          </Benefits>
        </Description>

        <ListPlans />

        <Gift>
          <div>
            Você pode comprar um plano e presentear <br />
            alguém especial clicando no botão abaixo
          </div>
          <Row>
            <GiftForFriend onClick={() => history.push('/presenteie-um-amigo')}>
              <img src={gift} alt="Ícone de presente" />
              <div>
                Presenteie
                <br />
                <span>UM AMIGO</span>
              </div>
            </GiftForFriend>

            <IWasPresented>
              <img src={gift} alt="Ícone de presente" />
              <div>
                FUI PRESENTADO
                <Row>
                  <Input
                    value={code}
                    onInput={(event) => setCode(event.target.value)}
                  />
                  <button onClick={() => iWasPresented()}>ok</button>
                </Row>
              </div>
            </IWasPresented>
          </Row>
        </Gift>

        {renderErrorMensage()}

        <Button onClick={() => handleClickButton()}>
          {renderTextButton()}
        </Button>

        <img
          onClick={() => history.goBack()}
          src={backIcon}
          alt="Ícone de voltar para a página anterior"
        />
      </Content>
      <Footer />
      {renderLoadModal()}

      {renderMessageModal()}
    </Container>
  );
};

export default ChoosePlan;
