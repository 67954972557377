import { useState } from 'react';
import Slider from 'react-slick';

import CardResult from '../../components/CardResult';
import TitleHightlight from '../../components/TitleHightlight';
import { Main, ControlButtons, Button } from './styles';

import next from './icons/next.svg';

export default function Carousel({
  titleHightlight = '',
  items = [],
  autoplay = false,
  showControlButtons = true,
  dots = false,
}) {
  const [sliderRef, setSliderRef] = useState({});

  const settings = {
    className: 'slider variable-width',
    infinite: true,
    // slidesToShow: 2,
    slidesToScroll: 2,
    variableWidth: true,
    arrows: false,
    lazyLoad: false,
    autoplay,
    dots,
    // centerMode: true,
  };

  return (
    <>
      <Main>
        {titleHightlight.length > 0 && (
          <TitleHightlight title={titleHightlight} />
        )}
        {showControlButtons === true && (
          <ControlButtons>
            <Button onClick={sliderRef?.slickPrev} prev={true}>
              <img src={next} alt="Anterior" />
            </Button>
            <Button onClick={sliderRef?.slickNext}>
              <img src={next} alt="Próximo" />
            </Button>
          </ControlButtons>
        )}
        <Slider ref={setSliderRef} {...settings}>
          {items.map((item) => (
            <CardResult style={{ width: 370 }} key={item.id} result={item} />
          ))}
        </Slider>
      </Main>
    </>
  );
}
