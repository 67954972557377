import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 200px;
  height: 260px;
  border-radius: 10px;
  box-shadow: 0px 0px 6px 0px lightgrey;
  margin: 40px 20px;
  cursor: pointer;
  background-color: #fff;
  transition: 0.5s all;

  ${(props) =>
    props.id === props.selectedPlan &&
    css`
      transform: scale(1.1);
      box-shadow: 0px 0px 45px 0px lightgrey;
    `}

  &:hover {
    transform: ${(props) =>
      props.selectedPlan != props.id ? css`scale(1.08)` : css`scale(1.1)`};
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Aller-Bold';
  color: #fff;
  font-size: 1.4rem;
  width: 100%;
  height: 50%;
  background-color: ${(props) =>
    props.type.toLowerCase().includes('anual') ? '#48E25B' : '#A16CCD'};
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  > span {
    font-family: 'Bartolomeo-Bold';
    display: block;
  }
`;

export const Description = styled.div`
  padding: 20px;
  margin-bottom: -30px;

  > div {
    font-family: 'Aller-Regular';
    color: #9a9a9a;
  }

  > div:nth-of-type(1) {
    font-size: 1rem;
  }

  > img {
    margin: 0px 0px 0px auto;
    display: block;
    margin-top: 10px;
  }

  > div:nth-of-type(2) {
    font-size: 1rem;
    margin-top: 10px;

    span {
      font-family: 'Aller-Bold';
      font-size: 1.2rem;
    }
  }
`;

export const Promotion = styled.div`
  padding: 20px;

  > div {
    font-family: 'Aller-Regular';
    color: #9a9a9a;
  }

  > div:nth-of-type(1) {
    font-size: 1rem;
  }

  > div:nth-of-type(2) {
    font-family: 'Aller-Bold';
    font-size: 1.2rem;
    margin-top: 10px;
  }
`;
