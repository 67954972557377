import styled from 'styled-components';

export const Container = styled.form`
  > div {
    height: 50px;
    background-color: #eef0f1;

    > input {
      background-color: #eef0f1;
    }
  }

  > button {
    font-family: 'Aller-Regular';
    font-size: 1.4rem;
    background-color: #8430ca;
  }

  @media (max-width: 560px) {
    width: 100%;

    > div {
      width: 100%;
    }

    > button {
      width: 100%;
    }
  }
`;
