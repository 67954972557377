import styled from 'styled-components';

export const Box = styled.div`
  font-family: 'Aller-Regular';
  max-width: 540px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 20px;
  h2 {
    font-size: 18px;
    font-weight: 700;
  }
  form {
    label {
      cursor: pointer;
      font-weight: 500;
    }
    div {
      margin-bottom: 10px;
      span {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 2px;
        font-weight: 800;
      }
    }
    .button-box {
      text-align: end;
    }
    input {
      width: 100%;
      background: var(--greyF5);
      box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.07);
      border-radius: 8px;
      outline: none;
      border: none;
      padding: 16px;
      ::placeholder {
        color: var(--grey92);
      }
      :disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
`;
