import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';

export const Container = styled.section``;

export const GradientBackground = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 500px;
  z-index: -1;
  background: linear-gradient(
    180deg,
    rgba(133, 50, 202, 1) 0%,
    rgba(248, 251, 246, 1) 70%,
    rgba(255, 255, 255, 1) 80%
  );
`;

export const ContainerSkeletonTitle = styled.div`
  > span > span {
    width: 400px;
    height: 30px;
  }
`;

export const ContainerSkeletonDescription = styled.div`
  display: flex;
  flex-direction: column;

  > span > span {
    width: 600px;
    height: 30px;
    margin: 10px 0px;
  }
`;

export const ScheduleClassButton = styled.div`
  font-family: 'Aller-Light';
  color: #5c5c5c;
  box-shadow: 0px 0px 6px 0px lightgrey;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  height: fit-content;
  border-radius: 10px;
  font-size: 1.4rem;
  cursor: pointer;

  img {
    /* height: auto; */
    height: 25px;
    width: 25px;
    margin: 0 0 6px auto;
    cursor: pointer;
  }
`;

export const SkeletonVideo = styled(Skeleton)``;

export const Video = styled.iframe`
  display: block;
  margin: 0 auto;
  width: 800px;
  height: ${(props) => {
    if (props.showSkeletonVideo) {
      return '0px';
    } else {
      if (props.onlyAudio) {
        return '200px';
      }
      return '460px';
    }
  }};
  border: 0;
  transition: all 1s;
  opacity: ${(props) => (props.showSkeletonVideo ? 0 : 1)};
  border-radius: 20px;

  @media (max-width: 1040px) {
    width: 600px;
    height: 346px;
  }

  @media (max-width: 640px) {
    width: 400px;
    height: 230px;
  }

  @media (max-width: 410px) {
    width: 340px;
    height: 202px;
  }

  @media (max-width: 350px) {
    width: 300px;
  }
`;

export const Thumbnail = styled.img`
  display: block;
  margin: 0 auto;
  width: auto;
  height: ${(props) => (props.showSkeletonVideo ? 0 : 300)}px;
  border: 0;
  transition: all 1s;
  opacity: ${(props) => (props.showSkeletonVideo ? 0 : 1)};
  cursor: pointer;
  border-radius: 20px;

  @media (max-width: 560px) {
    height: 200px;
  }

  @media (max-width: 375px) {
    height: 180px;
  }

  @media (max-width: 360px) {
    height: 160px;
  }

  @media (max-width: 320px) {
    height: 140px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;

  > p {
    font-family: 'Aller-Light';
    font-size: 1.4rem;
    color: #5c5c5c;
    width: 60%;
    margin-top: 20px;
  }

  > img {
    height: 150px;
    width: 190px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    text-align: center;

    > p {
      width: 100%;
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;

  google-cast-launcher {
    max-width: 50px;
  }

  ul {
    display: flex;
    list-style: none;
    margin-top: 20px;

    li {
      margin-right: 20px;

      img {
        height: 40px;
      }
    }

    li:nth-of-type(1) {
      position: relative;
      cursor: pointer;
    }

    li:nth-of-type(3) {
      display: flex;
      align-items: center;
      margin-left: 20px;

      img {
        height: 25px;
      }

      span {
        font-family: 'Aller-Regular';
        font-size: 1.6rem;
        color: #5c5c5c;
        margin-left: 10px;
      }
    }

    @media (max-width: 520px) {
      li:nth-of-type(1) {
        display: none;
      }
      li:nth-of-type(2) {
        display: none;
      }
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;

    ul {
      justify-content: center;
      margin-bottom: 20px;
    }
  }
`;

export const Description = styled.div`
  margin: 20px 0px;
`;

export const IconTV = styled.img`
  width: 80px;
  height: 80px;
`;

export const Divider = styled.div`
  height: 2px;
  width: 40%;
  background-color: #b86ef6;
  margin: 20px 0px;

  @media (max-width: 1024px) {
    margin: 20px auto;
  }
`;

export const DataAboutLesson = styled.div`
  width: 60%;

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      margin: 0px 40px 0px 0px;

      span {
        font-family: 'Aller-Regular';
        font-size: 1.4rem;
        color: #3c3c3c;
        margin-left: 10px;
      }

      img {
        height: 26px;
      }
    }
  }

  @media (max-width: 1024px) {
    width: 100%;

    ul {
      justify-content: center;

      li {
        margin: 20px 10px;
      }
    }
  }
`;

export const RowTitle = styled.div`
  display: flex;

  h1 {
    font-family: 'Aller-Regular';
    font-size: 2rem;
    color: var(--dark03);
    font-weight: normal;
    margin-left: 20px;
  }

  @media (max-width: 1024px) {
    h1 {
      text-align: center;
    }
  }

  @media (max-width: 520px) {
    div {
      display: none;
    }
  }
`;

export const MenuLessonsAndComments = styled.nav`
  display: flex;
  margin-top: 50px;

  > span:nth-of-type(2) {
    margin-left: 20px;
  }
`;

export const Item = styled.span`
  font-family: 'Aller-Regular';
  font-size: 1.4rem;
  color: var(--dark03);
  text-align: center;
  transform: ${(props) => (props.chosenMenu ? 'scale(1.1)' : 'scale(1.0)')};
  transition: all 0.5s;
  cursor: pointer;

  > div {
    height: 2px;
    width: 80px;
    background-color: ${(props) => (props.chosenMenu ? '#A16CCD' : '#BDBABA')};
    margin: 0 auto;
    transition: all 0.5s;
  }
`;

export const ContainerAudioPlayer = styled.div`
  audio {
    margin: 40px auto;
    display: block;
    box-shadow: 0px 0px 6px 0px lightgrey;
    border-radius: 26px;
    outline: 0;
  }

  @media (max-width: 319px) {
    audio {
      width: 260px;
    }
  }
`;

export const ContainerVideoPlayer = styled.div`
  video {
    width: 800px;
    height: 460px;
    border: 0;
    transition: all 1s;
    margin: 0 auto;
    display: block;

    @media (max-width: 1040px) {
      width: 600px;
      height: 346px;
    }

    @media (max-width: 640px) {
      width: 400px;
      height: 230px;
    }

    @media (max-width: 410px) {
      width: 340px;
      height: 202px;
    }

    @media (max-width: 350px) {
      width: 300px;
    }

    @media (max-width: 319px) {
      width: 260px;
    }
  }
`;

export const MenuMobile = styled.div`
  display: none;

  @media (max-width: 520px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    margin-top: 10px;

    img {
      height: 33px;
    }

    > div:nth-of-type(1) {
      margin-right: 0px;
    }

    li {
      display: flex;
      align-items: center;

      position: relative;

      img {
        height: 25px;
      }

      span {
        font-family: 'Aller-Regular';
        font-size: 1.6rem;
        color: #5c5c5c;
        margin-left: 10px;
      }
    }
  }
`;

export const Spinner = styled.div`
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #61e171;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1.2s linear infinite;
  margin: 0 auto 40px auto;
`;

export const ShowMoreLessons = styled.p`
  font-family: 'Aller-Regular';
  font-size: 1.4rem;
  color: var(--dark03);
  margin: 0 auto;
  cursor: pointer;
  text-align: center;
`;
