import React, { useEffect, useRef } from 'react';

import success from '../../assets/messageModal/success.png';

import successGreen from '../../assets/messageModal/success-green.png';

import { Container, OkButton } from './styles';

const Modal = ({
  title,
  subtitle,
  description,
  type,
  setVisible,
  iconColor,
  showOkButton = false,
}) => {
  const componentRef = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleClick = (e) => {
    if (!componentRef.current.contains(e.target)) {
      setVisible(false);
      return;
    }
  };

  function renderIcon() {
    if (type === 'success') {
      if (iconColor === 'green') {
        return <img src={successGreen} alt="Ícone de sucesso" />;
      }
      return <img src={success} alt="Ícone de sucesso" />;
    }
  }

  function renderOkButton() {
    if (showOkButton) {
      return <OkButton onClick={() => setVisible(false)}>Ok</OkButton>;
    }
  }

  return (
    <Container ref={componentRef} type={type}>
      {renderIcon()}
      <span>{title}</span>
      <span>{subtitle}</span>
      <span>{description}</span>
      {renderOkButton()}
    </Container>
  );
};

export default Modal;
