import { months } from '../../../../../../mocks/months';
import planCard from './../../icons/planCard.svg';
import { BoxCard } from './styles';

const PlanCard = ({
  plan,
  startOfRegistration = false,
  showPlanDates = true,
}) => {
  const getFormatedDate = (endDate = false) => {
    let created_at = null;
    if (startOfRegistration === true) created_at = new Date().toISOString();
    else if (startOfRegistration === false) created_at = plan?.created_at;
    let startDate = new Date(created_at);
    if (endDate === true) {
      startDate.setFullYear(startDate.getFullYear() + 1);
    }
    const formtatedDate = `${startDate.getDate()} ${
      months[startDate.getMonth()]
    } ${startDate.getFullYear()}`;

    return formtatedDate;
  };

  return (
    <>
      <BoxCard>
        <h3>Plano</h3>
        <img src={planCard} alt="Cartão do plano" />
        {showPlanDates && (
          <>
            <div>
              <span>Início do plano previsto para:</span>
              <b>{getFormatedDate()}</b>
            </div>
          </>
        )}
      </BoxCard>
    </>
  );
};

export default PlanCard;
