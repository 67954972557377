import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  > img {
    position: absolute;
    right: 20px;
    top: 10px;
    width: 20px;
    cursor: pointer;
  }

  @media (max-width: 585px) {
    > img {
      width: 14px;
    }
  }
`;

export const Title = styled.p`
  font-family: 'Aller-Regular';
  color: #5c5c5c;
  font-size: 1.2rem;
`;

export const Row = styled.div`
  display: flex;

  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const Form = styled.form`
  margin: 0 auto;
  margin-left: 20px;

  > p {
    font-family: 'Aller-Light';
    font-size: 1.2rem;
    color: #5c5c5c;
  }

  > button {
    font-family: 'Aller-Regular';
    font-size: 1.4rem;
    margin: 40px auto 0px auto;
  }

  @media (max-width: 1100px) {
    margin: 0 auto;
    margin-top: 20px;
    text-align: center;

    > button {
      margin: 40px auto 0px auto;
    }
  }

  @media (max-width: 585px) {
    > button {
      width: 100%;
    }
  }

  @media (max-width: 480px) {
    > button {
      height: 50px;
    }
  }
`;
