import styled from 'styled-components';

export const Container = styled.div`
  > div:nth-of-type(2) {
    font-family: 'Aller-Regular';
    font-size: 1.6rem;
    color: var(--dark03);
    cursor: pointer;
    text-align: center;

    > button {
      font-family: 'Aller-Regular';
      font-size: 1.6rem;
      margin: 20px auto;
    }

    > a {
      padding: 10px 0px;
      border-style: solid;
      border-top-width: 0px;
      border-right-width: 0px;
      border-bottom-width: 2px;
      border-left-width: 0px;
      border-color: #b3b1b1;
    }
  }

  @media (max-width: 640px) {
    width: 100%;

    > div:nth-of-type(2) {
      width: 100%;
      > button {
        width: 100%;
      }
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Tooltip = styled.div`
  font-family: 'Aller-Regular';
  font-size: 1.2rem;
  margin-top: 20px;
  color: #f00;
  text-align: center;
`;
