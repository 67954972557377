import { message } from 'antd';
import { useEffect } from 'react';
import { useState } from 'react';

import ButtonLoading from '../../../../components/ButtonLoading';
import MegaAddressForm from '../../../../components/MegaAddressForm';
import MegaPersonalDataForm from '../../../../components/MegaPersonalDataForm';
import { MUserForm } from '../../../../mocks/MUserForm';
import api from '../../../../services/api';
import { apiErrorMessage } from '../../../../utils/apiErrorMessage';
import { apiResponse } from '../../../../utils/apiResponse';
import { birthdayToAPI } from '../../../../utils/birthdayToAPI';
import { birthdayToInput } from '../../../../utils/birthdayToInput';
import { onlyNumbers } from '../../../../utils/onlyNumbers';
import { validCPF } from '../../../../utils/validCPF';
import { Box } from './styles';

export const MegaPersonalData = ({
  userData,
  onSetUser,
  setBirthdayRegistered = null,
}) => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({ ...MUserForm });

  useEffect(() => {
    setUser({ ...userData });
  }, []);

  const getBody = () => {
    let formattedData = {
      name: user.name.trim(),
      email: user.email?.trim()?.toLocaleLowerCase(),
      birthday: birthdayToAPI(user.birthday),
    };
    if (user.cpf) formattedData.cpf = onlyNumbers(user.cpf);
    return formattedData;
  };

  const CPFIsValid = () => (user?.cpf ? validCPF(user.cpf) : true);

  const updateUser = async (event) => {
    try {
      event.preventDefault();
      if (loading) return null;
      setLoading(true);
      const body = getBody();
      const response = apiResponse(await api.put(`users/${user.id}`, body));
      const { id, name, email, birthday, cpf } = response;
      if (setBirthdayRegistered) setBirthdayRegistered(true);
      onSetUser({ id, name, email, birthday: birthdayToInput(birthday), cpf });
      message.success('Dados pessoais alterados com sucesso!');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      apiErrorMessage(error, true);
    }
  };

  return (
    <>
      {/* <Main>{JSON.stringify(user)}</Main> */}
      <Box>
        <form onSubmit={updateUser}>
          <MegaPersonalDataForm
            setUser={setUser}
            user={user}
            CPFIsValid={CPFIsValid}
          />

          <div className="button-box">
            <ButtonLoading
              label="Alterar cadastro"
              type="submit"
              loading={loading}
            />
          </div>
        </form>
      </Box>
    </>
  );
};

export const MegaAddressData = ({ addressData, onSetAddress }) => {
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState(addressData);

  useEffect(() => {
    // setAddress({ ...addressData });
  }, []);

  const action = address?.id ? 'update' : 'store';

  const main = async (event) => {
    event.preventDefault();
    if (loading) return null;
    if (action === 'store') await storeAddress();
    else await updateAddress();
  };

  const storeAddress = async () => {
    try {
      setLoading(true);
      const body = getBody();
      const response = apiResponse(await api.post(`addresses/`, body));
      message.success('Endereço cadastrado com sucesso!');
      onSetAddress({ ...response });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      apiErrorMessage(error, true);
    }
  };

  const getBody = () => {
    let body = { ...address };
    delete body.id;
    delete body.name;
    delete body.user_id;
    delete body.created_at;
    delete body.updated_at;
    return body;
  };

  const updateAddress = async () => {
    try {
      setLoading(true);
      const id = address.id;
      const body = getBody();
      const response = apiResponse(await api.put(`addresses/${id}`, body));
      onSetAddress({ ...response });
      message.success('Endereço alterado com sucesso!');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      apiErrorMessage(error, true);
    }
  };

  return (
    <>
      {/* <Main>{JSON.stringify(address)}</Main> */}
      <Box>
        <form onSubmit={main}>
          <h2>Endereço</h2>
          <MegaAddressForm address={address} setAddress={setAddress} />

          <div className="button-box">
            <ButtonLoading
              label={
                action === 'store' ? 'cadastrar endereço' : 'Alterar endereço'
              }
              type="submit"
              loading={loading}
            />
          </div>
        </form>
      </Box>
    </>
  );
};

const UserDataContainer = ({
  userData,
  onSetUser,
  addressData,
  onSetAddress,
}) => {
  return (
    <>
      <MegaPersonalData userData={userData} onSetUser={onSetUser} />
      <MegaAddressData addressData={addressData} onSetAddress={onSetAddress} />
    </>
  );
};

export default UserDataContainer;
