import { formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { Container } from './styles';

const ResponseTime = ({ created_at }) => {
  const renderTime = () => {
    return formatDistance(new Date(created_at), new Date(), { locale: ptBR });
  };

  return <Container>{renderTime()}</Container>;
};

export default ResponseTime;
