import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import LoadModal from '../LoadModal';
import api from '../../services/api';
import { apiErrorMessage } from '../../utils/apiErrorMessage';
import { apiResponse } from '../../utils/apiResponse';
import { formatUserData } from '../../utils/formatUserData';
import { BoxLogin } from './styles';
import { togglePasswordDisplay } from '../../utils/togglePasswordDisplay';
import { useEffect } from 'react';

const FormLogin = ({ openDoors = false }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showLoadModal, setShowLoadModal] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    togglePasswordDisplay('password');
  }, []);

  const onChangeEmail = ({ target }) => {
    const email = target.value;
    setEmail(email);
  };

  const onChangePassword = ({ target }) => {
    const password = target.value;
    setPassword(password);
  };

  const logIn = async (event) => {
    try {
      event.preventDefault();
      setShowLoadModal(true);
      const userData = { email, password };
      const response = apiResponse(await api.post('/sessions', userData));
      const payload = formatUserData(response);
      setShowLoadModal(false);
      dispatch({ type: 'yoga-adm/user/get-user-data', payload });
      history.push('/home');
    } catch (error) {
      setShowLoadModal(false);
      apiErrorMessage(error, true);
    }
  };

  return (
    <BoxLogin openDoors={openDoors}>
      <h2>Já possui uma conta?</h2>
      <form onSubmit={logIn}>
        <div>
          <input
            value={email}
            onInput={onChangeEmail}
            type="email"
            placeholder="E-mail"
            required
          />
        </div>
        <div className="box-password">
          <div>
            <input
              value={password}
              id="password"
              onInput={onChangePassword}
              type="password"
              placeholder="Senha"
              required
            />
          </div>
        </div>
        <button type="submit">Entrar</button>
        <div className="links">
          <div>
            Esqueci minha senha{' '}
            <Link to="/solicitar-nova-senha">
              <span>recuperar</span>
            </Link>
          </div>
          {!openDoors && (
            <>
              <div>
                Não tenho acesso{' '}
                <Link to="/cadastro">
                  <span>criar conta</span>
                </Link>
              </div>
            </>
          )}
        </div>
      </form>
      <LoadModal show={showLoadModal} />
    </BoxLogin>
  );
};

export default FormLogin;
