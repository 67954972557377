import React from 'react';

import Carousel from '../Carousel';
import { Box } from './styles';

function ChannelVideos({ channelVideos = [] }) {
  return (
    <>
      {!!channelVideos.length && (
        <Box>
          <Carousel
            titleHightlight="COLEÇÕES"
            items={channelVideos}
            autoplay={true}
            showControlButtons={true}
          />
        </Box>
      )}
    </>
  );
}

export default ChannelVideos;
