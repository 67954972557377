import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  margin: 10px 0px;
  min-height: ${(props) => (props.showAddComment ? 240 : 120)}px;
  transition: all 0.5s;
  margin-bottom: ${(props) => (props.showAddComment ? 20 : 0)}px;
`;

export const ProfilePhoto = styled.img`
  height: 70px;
  width: 70px;
  border-radius: 50%;

  @media (max-width: 1040px) {
    height: 50px;
    width: 50px;
  }
`;

export const Informations = styled.div`
  width: 100%;
  margin-left: 20px;
`;

export const Name = styled.div`
  font-family: 'Aller-Bold';
  font-size: 1.4rem;
  color: var(--dark03);
`;

export const Text = styled.p`
  font-family: 'Aller-Light';
  font-size: 1.2rem;
  color: #5c5c5c;
  margin-top: 10px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: -10px;

  span {
    font-family: 'Aller-Regular';
    font-size: 1rem;
    color: var(--dark03);
    cursor: pointer;
  }

  > div {
    margin-top: 0px;
  }

  @media (max-width: 1040px) {
    flex-direction: column;
    align-items: baseline;
  }

  @media (max-width: 375px) {
    span {
      font-size: 1.2rem;
    }
  }
`;

export const Row2 = styled(Row)`
  @media (max-width: 1040px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const Divider = styled.div`
  height: 3px;
  width: 3px;
  border-radius: 50%;
  background-color: var(--dark03);
  margin: 0px 12px;

  @media (max-width: 1040px) {
    margin: 0 8px;
  }
`;

export const NumberOfLikes = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Aller-Regular';
  font-size: 1rem;
  color: #8430ca;
  cursor: pointer;
`;

export const AdminPhotoContainer = styled.div`
  position: relative;
  > img {
    width: 34px;
    border-radius: 50%;
    margin-right: 10px;
  }

  > div {
    display: flex;
    position: absolute;
    background-color: #fff;
    padding: 4px;
    width: fit-content;
    border-radius: 50%;
    bottom: -2px;
    right: 2px;
    box-shadow: 0px 0px 6px 0px lightgrey;
  }
`;

export const ReponseAndNumberOfLikesContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1040px) {
    > div:nth-of-type(3) {
      display: none;
    }
  }
`;
