import styled from 'styled-components';

export const Tooltip = styled.div`
  font-family: 'Aller-Regular';
  font-size: 1.2rem;
  margin-top: 20px;
  color: #f00;
  text-align: center;
`;

export const Box = styled.div`
  height: 100vh;
  display: grid;
  place-content: center;
  font-family: 'Aller-Regular';
  form {
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: 400px;
    max-width: 100%;
    h2 {
      font-family: Bartolomeo-Regular;
    }
    label {
      cursor: pointer;
      font-weight: 500;
    }
    small {
      color: var(--redFE);
    }
    img {
      cursor: pointer;
    }
    div {
      width: 100%;
      input {
        width: 100%;
        background: var(--greyF5);
        box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.07);
        border-radius: 8px;
        outline: none;
        border: none;
        padding: 16px;
        ::placeholder {
          color: var(--grey92);
        }
      }
    }
  }
`;
