import styled from 'styled-components';

export const FormBox = styled.div`
  font-family: 'Aller-Regular';
  max-width: 540px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 20px;
  form {
    div {
      margin-bottom: 10px;
      span {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 2px;
        font-weight: 800;
      }
    }

    .col {
      text-align: right;
    }
    small {
      color: var(--redFE);
    }
  }
`;
