import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadModal from '../../../../components/LoadModal';
import api from '../../../../services/api';
import { Main, Tooltip, UpdateUserPhoto } from './styles';

const Resume = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.User);
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [userData, setUserData] = useState({
    avatar_url: '',
  });
  const [errorMensage, setErrorMensage] = useState('');
  let inputUpdateAvatarPhoto = useRef();

  function checkIfTheFileIsAnImage() {
    const file = inputUpdateAvatarPhoto.files[0];
    return file.type.includes('image');
  }

  function renderErrorMensage() {
    if (errorMensage) {
      return <Tooltip>{errorMensage}</Tooltip>;
    }
  }

  async function uploadProfilePhoto(event) {
    if (!checkIfTheFileIsAnImage()) {
      setErrorMensage('Selecione um arquivo do tipo imagem');
      return;
    }

    setErrorMensage('');
    setShowLoadModal(true);

    const formData = new FormData();
    formData.append('avatar', inputUpdateAvatarPhoto.files[0]);

    try {
      const response = await api.patch(`users/${user.id}/avatar`, formData, {
        headers: {
          'Content-Type': `multipart/form-data;`,
        },
      });
      dispatch({
        type: 'yoga-web/user/get-user-profile-photo',
        payload: response.data,
      });
      const newAvatarURL = response.data.avatar_url;
      setUserData({ ...userData, avatar_url: newAvatarURL });
    } catch (error) {}

    setShowLoadModal(false);
  }

  return (
    <>
      <Main>
        <div>
          <img src={user.avatar_url} alt={user.name} />
          <UpdateUserPhoto onClick={() => inputUpdateAvatarPhoto.click()}>
            Alterar Foto
            <input
              type="file"
              accept="image/*"
              ref={(input) => (inputUpdateAvatarPhoto = input)}
              onInput={(event) => uploadProfilePhoto(event)}
            />
            {renderErrorMensage()}
          </UpdateUserPhoto>
        </div>
        <h2>Meu Perfil</h2>
      </Main>
      <LoadModal show={showLoadModal} />
    </>
  );
};

export default Resume;
