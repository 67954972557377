import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NumberOfPage = styled.div`
  font-family: 'Aller-Light';
  font-size: 2rem;
  color: #a8a9a8;
  margin: 0px 20px;

  ${(props) =>
    props.currentPage &&
    css`
      font-size: 2.4rem;
      color: #24b424;
      border-style: solid;
      padding: 10px;
      border-top-width: 0px;
      border-right-width: 0px;
      border-bottom-width: 1px;
      border-left-width: 0px;
    `}
`;
