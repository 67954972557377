export function birthdayToAPI(dateSTR) {
  if (!dateSTR) return null;

  const [year, month, day] = dateSTR.split('-');
  const birthDateIsoString = new Date(
    Number(year),
    Number(month) - 1,
    Number(day),
  ).toISOString();
  return birthDateIsoString;
}
