import styled from 'styled-components';

export const Hightlight = styled.h1`
  display: inline-block;
  font-family: 'Aller-Regular';
  font-style: normal;
  font-weight: 700;
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '39px')};
  line-height: ${(props) =>
    props.lineHeight ? `${props.lineHeight}px` : '50px'};
  color: var(--dark03);
  margin: 0;
  padding: 0;
  ::after {
    content: '';
    display: block;
    border: 4px solid var(--green80);
    background: var(--green80);
  }

  @media (max-width: 520px) {
    font-size: 30px;
    line-height: 40px;
    display: ${(props) => (props.desktopOnly ? 'none' : 'block')};
  }
`;
