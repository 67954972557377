import styled from 'styled-components';

export const BoxMain = styled.div`
  min-height: calc(100vh - 202px);
  background: var(--greyF5);
  display: grid;
  place-content: center;
  padding: 15px;
`;

export const BoxLogin = styled.div`
  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }
  width: 440px;
  max-width: 100%;
  padding: 40px;
  background: #ffffff;
  border-radius: 20px;
  form {
    buttom {
      width: 100%;
    }
  }

  @media (max-width: 500px) {
    width: calc(100vw - 50px);
    max-width: 100%;
  }
`;
