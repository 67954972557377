import { useCallback } from 'react';
import {
  cep,
  cpf,
  currency,
  creditCard,
  cardExpiration,
  cvv,
  birthDate,
} from './masks';

const InputMask = ({ mask, ...props }) => {
  const handleKeyUp = useCallback(
    (e) => {
      if (mask === 'cep') {
        cep(e);
      }
      if (mask === 'currency') {
        currency(e);
      }
      if (mask === 'cpf') {
        cpf(e);
      }
      if (mask === 'creditCard') {
        creditCard(e);
      }
      if (mask === 'cardExpiration') {
        cardExpiration(e);
      }
      if (mask === 'cvv') {
        cvv(e);
      }
      if (mask === 'birthDate') {
        birthDate(e);
      }
    },
    [mask],
  );

  return (
    <>
      <input {...props} onKeyUp={handleKeyUp} />
    </>
  );
};

export default InputMask;
