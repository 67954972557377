import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 300px;

  > img {
    width: 100%;
    height: 300px;
    border-radius: 10px;
  }

  > div {
    padding: 20px;
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    > ul {
      font-family: 'Aller-Regular';
      font-size: 1.2rem;
      color: #3c3c3c;
      list-style: none;

      img {
        height: 25px;
        margin-right: 10px;
      }

      li:nth-of-type(1) {
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 1220px) {
    margin: 20px auto;
  }
`;

export const NameLatestPodcastSerie = styled.span`
  font-family: 'Aller-Bold';
  font-size: 2.2rem;
  color: #fff;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
`;
