import React, { useEffect, useState } from 'react';

import Content from '../../components/Content';
import api from '../../services/api';
import { Container, Spinner, Text, Title } from './styles';

const FrequentlyAskedQuestions = () => {
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchTermsAndConditions();
  }, []);

  const fetchTermsAndConditions = async () => {
    setLoading(true);
    await api
      .get('/terms-of-use')
      .then((response) => {
        setData(response.data.content);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setData(error.response?.data?.message || error.toString());
      });
  };

  const renderText = () => {
    if (loading) {
      return <Spinner />;
    }

    return <Text>{data}</Text>;
  };

  return (
    <Container>
      <Content>
        <Title>Termos de Uso e Privacidade</Title>
        {renderText()}
      </Content>
    </Container>
  );
};

export default FrequentlyAskedQuestions;
