import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  background-color: #fff;
  margin: 0 auto;
  box-shadow: 0px 0px 6px 0px lightgrey;

  @media (max-width: 1024px) {
    width: auto;
    margin: 0px auto 0px 0px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;

    > div {
      > div:nth-of-type(1) {
        margin-top: 10px;
      }
      > div:nth-of-type(3) {
        display: none;
      }
    }
  }
`;

export const Options = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Item = styled.div`
  cursor: pointer;
  transition: 0.5s all;
  margin-right: 40px;

  > a {
    font-family: 'Aller-Bold';
    font-size: 1rem;
    color: #060606;
  }
`;

export const MenuIcon = styled.div`
  margin-top: 10px;
  margin-left: 20px;
  cursor: pointer;
  > div {
    width: 40px;
    height: 6px;
    margin-bottom: 4px;
    background-color: var(--purple84);
  }

  @media (max-width: 1024px) {
    > div {
      width: 32px;
      height: 4px;
      margin-bottom: 3px;
    }
  }
`;

export const Logo = styled.img`
  cursor: pointer;
  width: 156px;
  height: 25px;
  margin-top: 10px;
  margin-left: 20px;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Button = styled.button`
  font-family: 'Aller-Regular';
  font-size: 1rem;
  color: #fff;
  padding: 10px;
  border-radius: 40px;
  background-color: #a16ccd;
  border: 0;
  outline: 0;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const UserPhoto = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-left: 40px;

  @media (max-width: 1024px) {
    margin-left: 0px;
    display: none;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const ContainerOnlyAppearsVersionMobile = styled.div`
  display: none;
  > img {
    height: 25px;
  }

  span {
    font-family: 'Aller-Regular';
    color: var(--purple84);
    margin-left: 10px;
    font-size: 2.2rem;
  }

  @media (max-width: 1024px) {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }
`;
