import api from '../services/api';
import { apiResponse } from './apiResponse';
import { onlyNumbers } from './onlyNumbers';
import { apiErrorMessage } from './apiErrorMessage';

export async function consultZipCode(value) {
  try {
    const {
      cep,
      uf,
      localidade: city,
      logradouro: street,
      bairro: neighborhood,
      complemento: complement,
    } = apiResponse(await api.get(`https://viacep.com.br/ws/${value}/json/`));

    const formattedData = {
      cep: onlyNumbers(cep),
      uf,
      city,
      street,
      neighborhood,
      complement,
    };

    return formattedData;
  } catch (error) {}
}
