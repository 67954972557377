import { useHistory } from 'react-router-dom';

import { Progress } from 'antd';

import podcast from '../../../../../../assets/home/podcast.png';
import phone from '../../../../../../assets/home/phone.png';
import watch from '../../../../../../assets/home/watch.png';
import download from '../../../../../../assets/myLessons/download-icon.png';

import {
  Container,
  NameLatestPodcastSerie,
  Details,
  PodcastWrapper,
} from './styles';

const PodCastListCard = ({ dataPodcastList }) => {
  const history = useHistory();
  function renderDuration() {
    const { duration } = dataPodcastList.post.podcast_series;
    if (duration > 0) {
      if (duration < 60) {
        return `${duration} min`;
      } else {
        const hours = Math.trunc(duration / 60);
        const minutes = Math.trunc(duration - 60 * hours);
        if (hours === 1) {
          return `${hours} hora ${minutes > 0 ? ` e ${minutes} minutos` : ``}`;
        }
        return `${hours} horas ${minutes > 0 ? ` e ${minutes} minutos` : ``}`;
      }
    }
  }

  function renderNumberEpisodes() {
    const { number_of_episodes } = dataPodcastList.post.podcast_series;

    if (number_of_episodes > 1) {
      return `${number_of_episodes} episódios`;
    } else if (number_of_episodes === 1) {
      return `${number_of_episodes} episódio`;
    } else {
      return 'Nenhum episódio';
    }
  }

  function renderComponent() {
    if (JSON.stringify(dataPodcastList) !== '{}') {
      return (
        <PodcastWrapper>
          <Container>
            <NameLatestPodcastSerie
              onClick={() =>
                history.push({
                  pathname: `/podcast/${dataPodcastList.post.podcast_series.id}`,
                })
              }
            ></NameLatestPodcastSerie>
            <img
              onClick={() =>
                history.push({
                  pathname: `/podcast/${dataPodcastList.post.podcast_series.id}`,
                })
              }
              src={dataPodcastList?.post?.podcast_series?.thumbnail_url}
              alt="Thumbnail da série do podcast"
            />
            <div
              onClick={() =>
                history.push({
                  pathname: `/podcast/${dataPodcastList.post.podcast_series.id}`,
                })
              }
            >
              <ul>
                <li>
                  <img src={phone} alt="Ícone de fone de ouvi" />
                  {renderNumberEpisodes()}
                </li>
                <li>
                  <img src={watch} alt="Ícone de relógio" />
                  Duração: {renderDuration()}
                </li>
              </ul>
            </div>
          </Container>
          <Details>
            <h1
              onClick={() =>
                history.push({
                  pathname: `/podcast/${dataPodcastList.post.podcast_series.id}`,
                })
              }
            >
              {dataPodcastList.post.podcast_series.title}
            </h1>
            <span>Pri Leite</span>

            <Progress
              percent={parseInt(dataPodcastList.percentage)}
              format={(percent) =>
                `${parseInt(dataPodcastList.percentage)}% concluído`
              }
            />
          </Details>
        </PodcastWrapper>
      );
    }
  }

  return <>{dataPodcastList?.post?.podcast_series && renderComponent()}</>;
};

export default PodCastListCard;
