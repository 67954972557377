import { useHistory } from 'react-router-dom';

import numberLessons from '../../../../../../assets/favorites/number-lessons.png';
import duration from '../../../../../../assets/favorites/duration.png';
import playerIcon from '../../../../../../assets/favorites/player-icon.png';

import { Container, Player } from './styles';

const Description = ({ podcastOrMeditation, route, data }) => {
  const history = useHistory();

  const renderNumberOfLessons = () => {
    const number = data.post.series?.number_of_lessons;

    if (number === 0) {
      return 'Nenhuma aula';
    } else if (number === 1) {
      return `${number} aula`;
    }

    return `${number} aulas`;
  };

  const renderDuration = () => {
    const duration = data.post.series?.duration;
    if (duration > 0) {
      if (duration < 60) {
        return (
          <li>
            <img src={duration} alt="Ícone da duração em horas da série" />
            Duração: {`${duration} min`}
          </li>
        );
      } else {
        const hours = Math.trunc(duration / 60);
        const minutes = Math.trunc(duration - 60 * hours);
        return (
          <li>
            <img src={duration} alt="Ícone da duração em horas da série" />
            Duração: {hours} {hours === 1 ? 'hora' : 'horas'}
            {minutes > 0 && ` e ${minutes} minutos`}
          </li>
        );
      }
    }
  };

  return (
    <>
      {podcastOrMeditation ? (
        <Player onClick={() => history.push(route)}>
          <img src={playerIcon} alt="Ícone de player" />
          Executar
        </Player>
      ) : (
        <Container>
          <li>
            <img src={numberLessons} alt="Ícone do número de aulas" />
            {renderNumberOfLessons()}
          </li>
          {renderDuration()}
        </Container>
      )}
    </>
  );
};

export default Description;
