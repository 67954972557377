import styled from 'styled-components';

export const Box = styled.div`
  margin-top: 50px;
  margin-bottom: 25px;
  padding: 50px;
  border-bottom: 6px solid #f5f5f5;

  h2 {
    margin-top: 25px;
    text-align: center;
    font-family: 'Bartolomeo-Bold';
  }
`;

export const Options = styled.div`
  margin: 50px 0px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 250px));
  gap: 25px;

  @media (max-width: 500px) {
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  }

  @media (min-width: 500px) and (max-width: 900px) {
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  }
`;

export const Button = styled.button`
  display: grid;
  place-content: center;
  width: 100%;
  background-color: ${(props) =>
    props.selected === true ? '#80F08C' : '#d1b8eb'};
  color: var(--dark03);
  font-family: 'Aller-Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  font-size: 1.7em;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  border: none;
  cursor: pointer;
  padding: 20px;
  @media (max-width: 500px) {
    font-size: 0.9em;
    border-radius: 9px;
  }

  @media (min-width: 500px) and (max-width: 900px) {
    font-size: 1em;
    border-radius: 9px;
  }
`;
