import { message } from 'antd';
import { useState } from 'react';
import { useEffect } from 'react';
import InputMask from 'react-input-mask';

import ButtonLoading from '../../../../../components/ButtonLoading';
// import InputMask from '../../../../../components/InputMask';
import { MCreditCardForm } from '../../../../../mocks/MCreditCardForm';
import api from '../../../../../services/api';
import {
  creditCardInfoIsNotValid,
  startScript,
  storeCard,
} from '../../../../../services/IUGU';
import { apiErrorMessage } from '../../../../../utils/apiErrorMessage';
import { apiResponse } from '../../../../../utils/apiResponse';
import { onlyNumbers } from '../../../../../utils/onlyNumbers';
import { FormBox } from './styles';
import InputOutline from '../../../../../components/InputOutline';

const CreditCardForm = ({ setCurrentCard = null, oldCard = null }) => {
  const [loading, setLoading] = useState(false);
  const [card, setCard] = useState(MCreditCardForm);
  const [dueDateInvalid, setDueDateInvalid] = useState(false);

  useEffect(() => {
    startScript();
  });

  const remove = async () => {
    try {
      if (!oldCard?.id || loading) return null;
      setLoading(true);
      await api.delete(`cards/${oldCard.id}`);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      apiErrorMessage(error, true);
    }
  };

  const saveCreditCard = async (token) => {
    try {
      await remove();
      if (loading) return null;
      setLoading(true);
      const response = apiResponse(
        await api.post('/cards', {
          description: 'Meu cartão de crédito',
          token,
          set_as_default: 'true',
        }),
      );
      message.success('Cartão cadastrado com sucesso!');
      if (setCurrentCard) setCurrentCard(response);
      setCard(MCreditCardForm);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      apiErrorMessage(error, true);
    }
  };

  const tokenizeCardAndSave = async (event) => {
    try {
      event.preventDefault();
      setLoading(true);
      await storeCard(card, saveCreditCard, setLoading);
    } catch (error) {
      setLoading(false);
    }
  };

  const validDuedate = (target) => {
    const duedate = target.value;
    let month = null;
    let year = null;
    let currentYear = new Date().getFullYear();
    if (duedate.length === 5) {
      month = duedate.slice(0, 2);
      year = Number(`20${duedate.slice(3)}`);
      if (Number(month) < 1 || month > 12 || Number(year) < currentYear)
        setDueDateInvalid(true);
      else setDueDateInvalid(false);
    }
  };

  const onSetCard = ({ currentTarget }) => {
    const maskedInputs = ['number', 'cvv'];
    if (maskedInputs.includes(currentTarget.name))
      setCard({
        ...card,
        [currentTarget.name]: onlyNumbers(currentTarget.value),
      });
    else setCard({ ...card, [currentTarget.name]: currentTarget.value });
    if (currentTarget.name === 'duedate') validDuedate(currentTarget);
  };

  return (
    <>
      <FormBox>
        <form onSubmit={tokenizeCardAndSave}>
          {/* <div>{JSON.stringify(card)}</div> */}
          <InputOutline>
            <div className="row">
              <div className="col-12">
                <input
                  required
                  type="text"
                  name="name"
                  value={card.name}
                  onChange={onSetCard}
                />
                <span className="borda"></span>
                <label>Nome</label>
              </div>
              <div className="col-12"></div>
              <div className="col-12">
                <InputMask
                  required
                  mask="9999 9999 9999 9999"
                  name="number"
                  value={card.number}
                  onChange={onSetCard}
                  type="text"
                />
                <span className="borda"></span>
                <label>Número do cartão</label>
              </div>
              <div className="col-md-8">
                <InputMask
                  required
                  type="text"
                  name="duedate"
                  mask="99/99"
                  value={card.duedate}
                  onChange={onSetCard}
                  placeholder="mm/aa"
                />
                <span className="borda"></span>
                <label>Vencimento do cartão</label>
                <small>{dueDateInvalid ? 'Data inválida' : ''}</small>
              </div>
              <div className="col-md-4">
                <InputMask
                  required
                  type="text"
                  name="cvv"
                  mask="999"
                  value={card.cvv}
                  onChange={onSetCard}
                />
                <span className="borda"></span>
                <label>CVV</label>
              </div>

              <div className="col">
                <ButtonLoading
                  type="submit"
                  label="Adicionar cartão"
                  backgroundColor="#000000"
                  styles={{ width: '90%!important' }}
                  disabled={
                    creditCardInfoIsNotValid(card) ||
                    dueDateInvalid === true ||
                    loading
                  }
                  loading={loading}
                />
              </div>
            </div>
          </InputOutline>
        </form>
      </FormBox>
    </>
  );
};

export default CreditCardForm;
