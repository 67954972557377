import { Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import Content from '../../components/Content';
import LoadModal from '../../components/LoadModal';
import MessageModal from '../../components/MessageModal';
import NoAccessModal from '../../components/NoAccessModal';
import api from '../../services/api';
import {
  Container,
  Divisor,
  ExitButton,
  GradientBackground,
  MissionCard,
  Title,
} from './styles';

const AboutUs = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.User);
  const history = useHistory();

  const [nextLesson, setNextLesson] = useState(false);
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showNoAccessModal, setShowNoAccessModal] = useState(false);
  const [error, setError] = useState('');

  const getAutoPlayStatusUser = async () => {
    setShowLoadModal(true);
    const status = await api
      .get(`/users/${user.id}`)
      .then((response) => setNextLesson(response.data.autoplay))
      .catch((error) => handleError(error));
    setShowLoadModal(false);
  };

  const fetchIfUserHasPlanActive = async () => {
    const userHasPlanActive = await api
      .get(`/users/plans`)
      .then((response) => {
        if (
          response.data.status === 'pending' ||
          response.data.status === 'suspended'
        ) {
          return false;
        }
        return true;
      })
      .catch((error) => {
        return false;
      });

    return userHasPlanActive;
  };

  const handleUserAcess = async () => {
    setShowLoadModal(true);

    const userHasAcess = await fetchIfUserHasPlanActive();

    if (!userHasAcess) {
      setShowLoadModal(false);
      setShowNoAccessModal(true);
    } else {
      getAutoPlayStatusUser();
    }

    setShowLoadModal(false);
  };

  const handleError = async (error) => {
    let message;
    if (error.response.data?.message) {
      message = error.response.data.message;
    } else {
      message = error.toString();
    }

    setShowLoadModal(false);

    setError(message);
    setShowMessageModal(true);
  };

  const updateAutoPlayStatus = async () => {
    setShowLoadModal(true);
    await api
      .patch(`/users/autoplay`, {
        autoplay: !nextLesson,
      })
      .then((response) => setNextLesson(!nextLesson))
      .catch((error) => handleError(error));
    setShowLoadModal(false);
  };

  const handleOnChangeNextLesson = (value) => {
    updateAutoPlayStatus(value);
  };

  const logout = () => {
    dispatch({ type: 'yoga-adm/user/logout' });
    history.push('/');
  };

  useEffect(() => {
    // handleUserAcess();
    getAutoPlayStatusUser();
  }, []);

  const renderShowLoadModal = () => {
    if (showLoadModal) {
      return <LoadModal show={showLoadModal} />;
    }
  };

  const renderMessageModal = () => {
    if (showMessageModal) {
      return (
        <MessageModal
          title="Ocorreu um erro!"
          description={error}
          setVisible={setShowMessageModal}
        />
      );
    }
  };

  const renderNoAccessModal = () => {
    if (showNoAccessModal) {
      return <NoAccessModal show={showNoAccessModal} blockedPage={true} />;
    }
  };

  return (
    <>
      <Container>
        {/* <GradientBackground /> */}
        <Content>
          <Title>Configurações</Title>

          <MissionCard>
            <div>
              Próxima aula automaticamente
              <Switch
                checked={nextLesson}
                onChange={(value) => handleOnChangeNextLesson(value)}
              />
            </div>
            {/* <Link to="/alterar-plano">
              <div>Cancelamento</div>
            </Link> */}
          </MissionCard>

          <ExitButton onClick={logout}>Sair</ExitButton>
        </Content>
        <Divisor />
        {renderShowLoadModal()}
        {renderMessageModal()}
        {/* {renderNoAccessModal()} */}
      </Container>
    </>
  );
};

export default AboutUs;
