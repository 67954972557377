import { useEffect, useState } from 'react';
import { CardPlanTimer } from './styles';

const PlanTimer = ({ validUntil = null }) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [timeIsOver, setTimeIsOver] = useState(0);

  useEffect(() => {
    if (!validUntil) return;

    function updateWeather() {
      const futureDate = new Date(validUntil);
      futureDate.setHours(23, 59, 59);

      futureDate.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' });
      const now = new Date();
      now.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' });
      const difference = futureDate - now;

      if (difference <= 0) {
        setTimeIsOver(true);
        clearInterval(interval);
      } else {
        const secondsRemaining = Math.floor(difference / 1000);
        setDays(Math.floor(secondsRemaining / 86400));
        setHours(Math.floor((secondsRemaining % 86400) / 3600));
        setMinutes(Math.floor((secondsRemaining % 3600) / 60));
        setSeconds(secondsRemaining % 60);
      }
    }

    const interval = setInterval(updateWeather, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <CardPlanTimer>
      <div className="description">
        {timeIsOver ? 'Promoção expirada' : 'Promoção encerra em'}
      </div>
      <div className="boxTime">
        <div className="item">
          <div className="value">{days}</div>
          <div className="label">dia{days > 1 ? 's' : ''}</div>
        </div>

        <div className="item">
          <div className="value">{hours}</div>
          <div className="label">hora{hours > 1 ? 's' : ''}</div>
        </div>

        <div className="item">
          <div className="value">{minutes}</div>
          <div className="label">min</div>
        </div>

        <div className="item">
          <div className="value">{seconds}</div>
          <div className="label">seg</div>
        </div>
      </div>
    </CardPlanTimer>
  );
};

export default PlanTimer;
