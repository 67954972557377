import { BoxAuthorizedPayment } from './styles';
import welcomeImg from './icons/welcome.png';
import successImg from './icons/success.svg';
import { Link } from 'react-router-dom';

const AuthorizedPayment = () => {
  return (
    <BoxAuthorizedPayment>
      <img src={welcomeImg} alt="Bem-vindo" className="welcomeImg" />
      <div>
        <img
          src={successImg}
          alt="Pagamento efetuado com sucesso!"
          className="successImg"
        />
        <span>Pagamento realizado!</span>
      </div>
      <h1>Seja bem vindo(a) ao Yoga Co.</h1>
      <p>
        Seja muito bem-vindo ao nosso espaço dedicado à prática do yoga. Aqui,
        você encontrará um refúgio de serenidade e equilíbrio, onde a jornada do
        autoconhecimento e bem-estar se desdobrará a cada respiração e postura.
        Juntos, exploraremos os caminhos da mente, do corpo e do espírito, em
        busca de harmonia e paz interior. Namaste! 🧘‍♀️🕉️
      </p>

      <div>
        Começar minha jornada{' '}
        <Link to="/home">
          <span>aqui</span>
        </Link>
      </div>
    </BoxAuthorizedPayment>
  );
};

export default AuthorizedPayment;
