import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { MAddressForm } from '../../mocks/MAddressForm';
import { MUserForm } from '../../mocks/MUserForm';
import api from '../../services/api';
import { apiErrorMessage } from '../../utils/apiErrorMessage';
import { birthdayToInput } from '../../utils/birthdayToInput';
import Menu from './components/Menu';
import Payments from './components/Payments';
import Plan from './components/Plan';
import Resume from './components/Resume';
import UserDataContainer, {
  MegaPersonalData,
} from './components/UserDataContainer';
import { Main } from './styles';

const MegaProfile = () => {
  const [selectedScreenIndex, setSelectedScreenIndex] =
    useState('personalData');
  const [subscription, setSubscription] = useState(null);
  const [user, setUser] = useState({ ...MUserForm, id: '' });
  const [address, setAddress] = useState({ ...MAddressForm, id: '' });

  const onSetAddress = (addresses = []) => {
    if (addresses.length) setAddress(addresses[0]);
  };
  const [birthdayRegistered, setBirthdayRegistered] = useState(true);
  const userLogged = useSelector((state) => state.User);

  const onSetBirthdayRegistered = (dateRegistered) => {
    setBirthdayRegistered(dateRegistered);
    if (dateRegistered) fetchSubscriptionData();
  };

  const menu = [
    {
      label: 'Dados Pessoais',
      key: 'personalData',
      screen: (
        <UserDataContainer
          userData={user}
          onSetUser={setUser}
          addressData={address}
          onSetAddress={setAddress}
        />
      ),
    },
    // {
    //   label: 'Pagamentos',
    //   key: 'payments',
    //   screen: <Payments info={subscription} />,
    // },
    {
      label: 'Plano',
      key: 'planRegistration',
      screen: <Plan user={user} subscription={subscription} />,
    },
  ];

  useEffect(() => {
    getUser();
  }, []);

  /**
   * Sets the selected screen based on the given key.
   *
   * @param {string} screenKey - The key to identify the screen: 'personalData', 'payments', or 'planRegistration'.
   * @return {undefined} - Does not return a value.
   */
  const setSelectedScreen = (screenKey = 'personalData') => {
    setSelectedScreenIndex(screenKey);
  };

  const ControlScreen = () => {
    if (!user.id) return null;

    const selectedOption = menu.find(
      (option) => option.key === selectedScreenIndex,
    );
    return selectedOption?.screen || null;
  };

  /**
   * Fetches user data from the API and updates the user state.
   */
  const getUser = async () => {
    try {
      // Make API request to get user data
      const { data } = await api.get(`/users/${userLogged.id}?relations=true`);

      // Destructure necessary user data from the response
      const { name, email, birthday, cpf, id, addresses } = data;

      // Update user state with the fetched data
      setUser({ id, name, email, birthday: birthdayToInput(birthday), cpf });

      // Set address state with the fetched addresses
      onSetAddress(addresses);

      // Check if birthday is not set and update state accordingly
      if (!birthday) {
        setBirthdayRegistered(false);
      } else fetchSubscriptionData();

      // fetchSubscriptionData();
    } catch (error) {
      // Handle API error and display error message
      apiErrorMessage(error, true);
    }
  };

  const fetchSubscriptionData = async () => {
    try {
      const { data: currentPlan } = await api.get(
        'users/plans?checkStatus=true',
      );
      setSubscription({ ...currentPlan });
      if (currentPlan.status !== 'authorized' && birthdayRegistered)
        setSelectedScreen('planRegistration');
    } catch (error) {
      if (error?.response) {
        if (error?.response?.status === 422 && birthdayRegistered)
          setSelectedScreen('planRegistration');
      }
    }
  };

  return (
    <>
      <Main>
        <Resume />
        <Menu
          menu={menu}
          selectedScreenIndex={selectedScreenIndex}
          onSetSelectedScreenIndex={setSelectedScreen}
        />
        <ControlScreen />
        <Modal
          closable={false}
          title="Finalizar cadastro"
          visible={!birthdayRegistered}
          footer={false}
        >
          <MegaPersonalData
            userData={{
              id: user?.id,
              name: user?.name,
              email: user?.email,
              birthday: user?.birthday,
            }}
            onSetUser={setUser}
            setBirthdayRegistered={onSetBirthdayRegistered}
          />
        </Modal>
      </Main>
    </>
  );
};

export default MegaProfile;
