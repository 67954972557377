import { formatMoney } from '../../../../../../utils/formatMoney';
import { BoxPlanAdvertisement } from './styles';

import PlanInformation from './../PlanInformation';
import PlanSubscriptionData from '../../../PlanSubscriptionData';
import PlanTimer from './../PlanTimer';
import ClosedDoors from '../../../ClosedDoors';

const PlanAdvertisement = ({
  planOnOffer = null,
  setStep = () => {},
  hadAnActivePlan = false,
  subscription = null,
}) => {
  const openDoors = planOnOffer ? true : false;

  const Render = () => {
    if (openDoors) {
      return (
        <>
          <BoxPlanAdvertisement>
            <h3>Yoga Co. 365</h3>
            <p>
              Um programa como nenhum outro pra você ter mais constância,
              evolução e leveza na prática de yoga.
            </p>
            <div className="price-card">
              <div className="price">
                <h3>{formatMoney(planOnOffer?.price || '0')}</h3>
                <span> / 1 ano</span>
              </div>
              {planOnOffer?.valid_until && (
                <PlanTimer validUntil={planOnOffer?.valid_until} />
              )}
              <div>
                <button onClick={() => setStep(1)}>Comprar agora!</button>
              </div>
            </div>

            <PlanInformation plan={planOnOffer} />

            {hadAnActivePlan && (
              <PlanSubscriptionData
                subscription={subscription}
                title="Planos Anteriores"
              />
            )}
          </BoxPlanAdvertisement>
        </>
      );
    } else {
      return (
        <>
          <ClosedDoors />
          {planOnOffer?.only_former_student && <PlanSubscriptionData />}
        </>
      );
    }
  };

  return <Render />;
};

export default PlanAdvertisement;
