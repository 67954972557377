import styled from 'styled-components';

export const BoxQuestionsViaWhatsApp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  h2 {
    color: rgba(0, 0, 0, 0.8);
    font-family: Aller-Regular;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -0.33px;
    text-transform: uppercase;
  }

  button {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 16px;

    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.8);
    font-family: Aller-Regular;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.22px;

    border-radius: 8px;
    border: 1px solid var(--Dark-20, rgba(0, 0, 0, 0.2));
  }
`;
