import styled from 'styled-components';

export const Box = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 25px;
`;

export const BoxPIX = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  font-family: 'Aller-Regular';
  font-style: normal;
  color: var(--dark03);
  h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    margin: 0;
    padding: 0;
  }
  img {
    width: 206px;
    height: 206px;
  }
  button {
    padding: 16px;
    gap: 10px;
    width: 133px;
    height: 51px;
    background: var(--purpleD1);
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    border: none;
    cursor: pointer;
    :last-child {
      background-color: var(--dark03);
      color: #ffffff;
    }
  }
  div {
    font-weight: 400;
    font-size: 17px;
    line-height: 18px;

    div {
      display: flex;
      justify-content: center;
      b {
        margin-right: 10px;
      }
    }
  }
`;

export const BoxBankSlip = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  font-family: 'Aller-Regular';
  font-style: normal;
  color: var(--dark03);
  h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    margin: 0;
    padding: 0;
  }
  img {
    width: 100%;
    object-fit: contain;
  }
  button {
    padding: 16px;
    gap: 10px;
    width: 133px;
    height: 51px;
    background: var(--purpleD1);
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    border: none;
    cursor: pointer;
    :last-child {
      background-color: var(--dark03);
      color: #ffffff;
    }
  }
  div {
    font-weight: 400;
    font-size: 17px;
    line-height: 140%;

    div {
      display: flex;
      justify-content: center;
      b {
        margin-right: 10px;
      }
    }
  }
`;
