import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';
import { apiErrorMessage } from '../../utils/apiErrorMessage';
import { apiResponse } from '../../utils/apiResponse';
import { birthdayToAPI } from '../../utils/birthdayToAPI';
import { formatUserData } from '../../utils/formatUserData';
import ButtonLoading from '../ButtonLoading';
import Checkbox from '../Checkbox';
import MegaPersonalDataForm from '../MegaPersonalDataForm';
import TermsAndConditions from './../TermsAndConditions';
import { Box, BoxMegaSignUp } from './styles';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { togglePasswordDisplay } from '../../utils/togglePasswordDisplay';

const MegaSignUp = () => {
  const [user, setUser] = useState({
    name: '',
    email: '',
    birthday: '',
    password: '',
    confirmPassword: '',
  });

  const [loading, setLoading] = useState(false);
  const [visibleTermsAndConditions, setVisibleTermsAndConditions] =
    useState(false);
  const [checkedTermsOfUse, setCheckedTermsOfUse] = useState(false);

  const samePasswords = user.password === user.confirmPassword;
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    togglePasswordDisplay('password');
    togglePasswordDisplay('confirmPassword');
  });

  const onSetUser = ({ target }) => {
    setUser({ ...user, [target.name]: target.value });
  };

  const RenderTermsAndConditions = () => {
    if (visibleTermsAndConditions) {
      return (
        <TermsAndConditions
          setChecked={setCheckedTermsOfUse}
          checked={checkedTermsOfUse}
          setVisible={setVisibleTermsAndConditions}
        />
      );
    }
    return null;
  };

  const getBody = () => {
    const formattedData = {
      name: user.name.trim(),
      password: user.password.trim(),
      email: user.email?.trim()?.toLocaleLowerCase(),
      birthday: birthdayToAPI(user.birthday),
    };
    return formattedData;
  };

  const formIsValid = () => {
    return checkedTermsOfUse && samePasswords;
  };

  async function signUp(event) {
    try {
      event.preventDefault();
      if (loading || !formIsValid()) return null;
      setLoading(true);
      const body = getBody();
      const response = apiResponse(await api.post('/users', body));
      if (response) {
        const payload = formatUserData(response);
        dispatch({ type: 'yoga-adm/user/get-user-data', payload });
        setLoading(false);
        history.push('/perfil');
      }
    } catch (error) {
      apiErrorMessage(error, true);
      setLoading(false);
    }
  }

  return (
    <>
      <BoxMegaSignUp>
        <Box>
          <h1>Cadastro</h1>
          <form onSubmit={signUp}>
            <MegaPersonalDataForm setUser={setUser} user={user} />

            <div className="row">
              <div className="col-md-6">
                <label htmlFor="password">Senha</label>
                <div className="box-password">
                  <input
                    id="password"
                    required
                    name="password"
                    placeholder="Senha"
                    type="password"
                    onInput={onSetUser}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="confirmPassword">Confirmação de senha</label>
                <div className="box-password">
                  <input
                    id="confirmPassword"
                    required
                    name="confirmPassword"
                    placeholder="Confirmar senha"
                    type="password"
                    onInput={onSetUser}
                  />
                </div>
                {user.password && !samePasswords && (
                  <small className="text-danger">
                    as senhas não são iguais
                  </small>
                )}
              </div>
            </div>
            <div>
              <span>
                <Checkbox
                  checked={checkedTermsOfUse}
                  setChecked={setCheckedTermsOfUse}
                />
                <span onClick={() => setVisibleTermsAndConditions(true)}>
                  Ler termos de uso
                </span>
              </span>
              <div>
                {!checkedTermsOfUse && (
                  <small className="text-danger">aceite os termos e uso</small>
                )}
              </div>
            </div>
            <ButtonLoading
              label="Finalizar cadastro"
              type="submit"
              loading={loading}
              width="100%"
            />
            <div className="to-sign">
              Se você já tem uma conta,{' '}
              <Link to="/login">
                {' '}
                <span>faça login</span>{' '}
              </Link>
            </div>
          </form>
        </Box>
      </BoxMegaSignUp>
      <RenderTermsAndConditions />
    </>
  );
};

export default MegaSignUp;
