import styled from 'styled-components';

export const Ellipse = styled.img`
  position: fixed;
  width: 26%;
  left: calc(100vw - 26%);
  bottom: -26px;
`;

export const Logo = styled.img`
  display: block;
  cursor: pointer;
  width: 229px;
  height: 36px;
  object-fit: contain;
`;

export const Arrow = styled.img``;

export const SuccesMessage = styled.div`
  text-align: right;
`;

export const LoginText = styled.div`
  text-align: center;
`;

export const ContainerEmail = styled.div`
  div {
    box-shadow: none !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
    width: 100%;
  }
`;

export const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
`;

export const BoxInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 32px;
  gap: 32px;
  width: 666px;
  /* min-height: 561px; */
  max-width: 100%;

  h1 {
    font-family: 'Bartolomeo-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 25px;
    color: #8430ca;
  }

  h2,
  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 25px;
  }

  h2 {
    font-family: 'Aller-Regular';
    color: #8430ca;
  }

  h3 {
    font-family: 'Bartolomeo-Regular';
    color: #ff9999;
    transform: rotate(-6deg);
  }

  p {
    font-family: 'Aller-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.6);
  }

  span {
    font-family: 'Aller-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.6);
  }

  span:nth-of-type(1) {
    cursor: pointer;
  }

  > div > span:nth-of-type(2) {
    color: rgba(255, 153, 153, 1);
    margin-left: 4px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  input[type='email' i] {
    box-sizing: border-box;
    gap: 8px;
    margin: 0;
    padding: 14px 16px;
    max-width: 602px;
    width: 100%;
    height: 58px;
    background: #ffffff;
    border: 2px solid #80f08c;
    border-radius: 8px;
  }

  > div:nth-of-type(2) {
    margin-top: 4px;
  }

  span {
    font-family: 'Aller-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
  }

  button {
    width: 64px;
    height: 64px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    background: #80f08c;
    border-radius: 100px;
  }
`;

export const Tooltip = styled.div`
  font-family: 'Aller-Regular';
  font-size: 1.2rem;
  margin-top: 20px;
  color: #f00;
  text-align: center;
`;
