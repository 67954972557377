import styled from 'styled-components';

export const Form = styled.form`
  margin: 0 auto;
  font-family: 'Aller-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--dark03);

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;

  span {
    color: rgba(0, 0, 0, 0.8);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.176px;
    margin-bottom: 16px;
  }

  .box-input {
    height: 71px;
    width: 263px;
    max-width: 100%;
    padding: 10px;
    background-color: ${(props) =>
      props.couponApplied ? 'var(--green80)' : 'var(--purpleD1)'};
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 8px;

    input {
      background: ${(props) => (props.couponApplied ? '#ffffff' : '#f5f5f5')};
      box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.07);
      border-radius: 8px;
      padding: 16px;
      width: 100%;
      border: none;
      height: 51px;
      outline: none;
      ::placeholder {
        color: #929191;
        font-family: 'Aller-Regular';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    img {
      :last-child {
        width: 30px;
        object-fit: contain;
        cursor: pointer;
      }
    }
  }
`;

export const Img = styled.img``;

export const Coupon = styled.div`
  margin-bottom: 16px;
  div {
    text-align: center;
    font-size: 14px;
    span {
      font-weight: bold;
    }
  }
`;
