import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  cursor: pointer;

  @media (max-width: 596px) {
    width: 100%;
  }
`;

export const Description = styled.div`
  display: flex;
  justify-content: space-around;
  width: 400px;
  padding: 20px;

  &:nth-child(odd) {
    background-color: #f2f4f7;
  }
`;

export const Information = styled.div`
  font-family: 'Roboto-Regular';
  color: #bfbfbf;
  display: flex;
  align-items: center;
`;

export const Circle = styled.div`
  background-color: #a16ccd;
  height: 10px;
  width: 10px;
  border-radius: 50%50%;
  margin-right: 20px;
`;

export const Hour = styled.div`
  font-family: 'Roboto-Regular';
  color: #fff;
  background-color: #a16ccd;
  padding: 4px 12px;
  border-radius: 8px;
`;
