import styled from 'styled-components';

export const BoxMegaSignUp = styled.div`
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px 0;
`;

export const Box = styled.div`
  font-family: 'Aller-Regular';
  max-width: 540px;
  margin: 0 auto;
  padding: 40px;
  background: #ffffff;
  border-radius: 20px;
  h1,
  h2 {
    font-weight: 700;
  }
  h1 {
    line-height: 29px;
    font-size: 24px;
  }
  h2 {
    font-size: 18px;
  }
  form {
    label {
      cursor: pointer;
      font-weight: 500;
    }
    div {
      margin-bottom: 10px;
      span {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 2px;
        font-weight: 800;
      }
    }
    input {
      width: 100%;
      background: var(--greyF5);
      box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.07);
      border-radius: 8px;
      outline: none;
      border: none;
      padding: 16px;
      ::placeholder {
        color: var(--grey92);
      }
      :disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
    .to-sign {
      display: flex;
      gap: 7px;
      margin-top: 16px;
      font-size: 14px;
      span {
        font-weight: 600;
      }
    }
    .box-password {
      text-align: end;
      img {
        margin-top: -80px;
        margin-right: 15px;
        cursor: pointer;
      }
    }
  }
`;
