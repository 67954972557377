import React, { useEffect } from 'react';

import { Container, Box } from './styles';

const ChoosenFilters = ({ selectedFilters, setSelectedFilters }) => {
  function deleteFilter(id) {
    setSelectedFilters(selectedFilters.filter((item) => item.id !== id));
  }

  function renderSelectedFilters() {
    return selectedFilters.map((filter) => {
      return (
        <Box onClick={() => deleteFilter(filter.id)} key={filter.id}>
          {filter.filter}
        </Box>
      );
    });
  }

  useEffect(() => {
    renderSelectedFilters();
  }, [selectedFilters, setSelectedFilters]);

  return <Container>{renderSelectedFilters()}</Container>;
};

export default ChoosenFilters;
