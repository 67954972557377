import { Link } from 'react-router-dom';

import Card from './components/Card';

import { Container } from './styles';

const CurrentPlan = ({ plan }) => {
  return (
    <Container>
      <div>Plano atual</div>
      <Card plan={plan} />
      <div>
        <Link to="/alterar-plano">
          <span>Alterar </span>
          plano
        </Link>
      </div>
    </Container>
  );
};

export default CurrentPlan;
<Container></Container>;
