import Carousel from '../Carousel';

import { Title } from './styles';

const FavoriteSeries = ({ series, loading, search }) => {
  const formatString = (value) => {
    let newValue = value.toUpperCase();
    newValue = newValue.replace(/[ÀÁÂÃÄÅ]/, 'A');
    newValue = newValue.replace(/[ÈÉÊË]/, 'E');
    newValue = newValue.replace(/[ÌÍÎÏ]/, 'I');
    newValue = newValue.replace(/[ÒÓÕÔÖ]/, 'O');
    newValue = newValue.replace(/[ÙÚÛÜ]/, 'U');
    newValue = newValue.replace(/[Ç]/, 'C');
    return newValue;
  };

  const renderSeries = () => {
    let seriesFiltered = [];
    if (search.trim() !== '') {
      let title;
      let searchFormatted = formatString(search);
      series.map((item) => {
        title = formatString(item.post.series.title);
        if (title.indexOf(searchFormatted) >= 0) {
          seriesFiltered.push(item);
        }
      });
    } else {
      seriesFiltered = series;
    }

    if (seriesFiltered.length > 0 || loading) {
      return (
        <>
          <Title>Suas séries favoritas</Title>
          <Carousel route="serie" lessons={seriesFiltered} loading={loading} />
        </>
      );
    }
  };

  return <>{renderSeries()}</>;
};

export default FavoriteSeries;
