import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';
import { handleError } from '../../services/handleError';

import Header from '../../components/Header';
import Content from '../../components/Content';

import Input from '../../components/Input';
import Footer from '../../components/Footer';
import AddCreditCard from './components/AddCreditCard/AddCreditCard';
import LoadModal from '../../components/LoadModal';
import MessageModal from '../../components/MessageModal';
import TermsAndConditions from '../../components/TermsAndConditions';
import Checkbox from '../../components/Checkbox';
import Button from '../../components/Button';

import { CreditCardFormData } from './components/creditCardFormData/CreditCardFormData';
import { ProductCard } from './components/ProductCard/ProductCard';

import {
  Container,
  PlanContainer,
  SubscriptionForm,
} from './_mentorshipPayment';
import emailValidation from '../../utils/emailValidation';
import { BankSlipFormData } from './components/BankSlipFormData/BankSlipFormData';

export const MentorshipPayment = () => {
  const history = useHistory();

  const [showFormDataSection, setShowFormDataSection] = useState('register');
  const [showModalSuccessPayment, setShowModalSuccessPayment] = useState(false);
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [informations, setInformations] = useState({
    number: '',
    name: '',
    cvv: '',
    duedate: '',
  });

  const [userInfo, setUserInfo] = useState({
    name: '',
    email: '',
    birthdate: '',
    installmentsOption: '',
    cpf_cnpj: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    zip_code: '',
  });
  const [checkedTermsOfUse, setCheckedTermsOfUse] = useState(false);
  const [visibleTermsAndConditions, setVisibleTermsAndConditions] =
    useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handleNumberCreditCard(event) {
    const number = event.target.value;
    if (number.length <= 16) {
      setInformations({
        ...informations,
        number,
      });
    }
  }

  function renderModalSuccessPayment() {
    if (showModalSuccessPayment) {
      return (
        <MessageModal
          type="success"
          iconColor="green"
          title="Prontinho!"
          subtitle="Seu pagamento foi aprovado"
          setVisible={setShowModalSuccessPayment}
        />
      );
    }
  }

  const renderTermsAndConditions = () => {
    if (visibleTermsAndConditions) {
      return (
        <TermsAndConditions
          setChecked={setCheckedTermsOfUse}
          checked={checkedTermsOfUse}
          setVisible={setVisibleTermsAndConditions}
        />
      );
    }
  };

  const formDataisNotValid = () => {
    const { name, email, birthdate } = userInfo;
    if (
      !name.length ||
      !name.includes(' ') ||
      !emailValidation(email) ||
      // birthdate.length !== 10 ||
      !checkedTermsOfUse
    ) {
      return true;
    }

    return false;
  };

  const handleDirectPayment = async (
    token,
    installments,
    bankSlipOption = false,
  ) => {
    setLoading(true);

    bankSlipOption
      ? handleDirectPaymentWithBankslip()
      : handleDirectPaymentWithCreditCard(token, installments);
  };

  const handleDirectPaymentWithCreditCard = async (token, installments) => {
    const data = {
      token,
      name: userInfo.name,
      email: userInfo.email,
    };

    if (installments !== 1) {
      data['months'] = installments;
    }

    try {
      const response = await api.post(`mentorship`, data);

      if (response) {
        setShowModalSuccessPayment(true);

        setTimeout(() => {
          history.push('/parabens-pelo-grande-passo');
        }, 3000);
      }
    } catch (error) {
      handleError(error.response?.data?.message || error.toString());
    } finally {
      setLoading(false);
    }
  };

  const handleDirectPaymentWithBankslip = async () => {
    const {
      name,
      email,
      cpf_cnpj,
      street,
      number,
      district,
      city,
      state,
      zip_code,
    } = userInfo;

    const data = {
      name,
      email,
      cpf_cnpj,
      address: {
        street,
        number,
        district,
        city,
        state,
        zip_code,
      },
    };

    try {
      const response = await api.post(`mentorship/bankslip`, data);

      if (response) {
        setShowModalSuccessPayment(true);

        setTimeout(() => {
          history.push('/parabens-pelo-grande-passo');
        }, 3000);
      }
    } catch (error) {
      handleError(error.response?.data?.message || error.toString());
    } finally {
      setLoading(false);
    }
  };

  const subscriptionFormSection = (
    <SubscriptionForm className="subscription-form">
      <Input
        placeholder="Nome completo"
        value={userInfo.name}
        onChange={(e) => setUserInfo({ ...userInfo, name: e.target.value })}
      />

      <Input
        placeholder="E-mail"
        value={userInfo.email}
        onChange={(e) => setUserInfo({ ...userInfo, email: e.target.value })}
      />

      {/* <Input
    placeholder="Data de nascimento"
    value={userInfo.birthdate}
    onChange={(e) =>
      setUserInfo({
        ...userInfo,
        birthdate: birthDateMask(e.target.value),
      })
    }
  /> */}

      <span
        className="subscription-form__terms-anchor"
        onClick={() => setVisibleTermsAndConditions(!visibleTermsAndConditions)}
      >
        Ler termos de uso
      </span>

      <div className="subscription-form__row">
        <Checkbox
          checked={checkedTermsOfUse}
          setChecked={setCheckedTermsOfUse}
        />
        <label>Li e concordo com os termos de uso</label>
      </div>

      <Button
        onClick={() => setShowFormDataSection('credit-card')}
        disabled={formDataisNotValid()}
      >
        Finalizar cadastro e pagar
      </Button>
    </SubscriptionForm>
  );

  const creditCardSection = (
    <CreditCardFormData
      setShowFormDataSection={setShowFormDataSection}
      setShowLoadModal={setShowLoadModal}
      setInformations={setInformations}
      informations={informations}
      handleNumberCreditCard={handleNumberCreditCard}
      handleDirectPayment={handleDirectPayment}
      loading={loading}
    />
  );

  const bankSlipSection = (
    <BankSlipFormData
      setShowFormDataSection={setShowFormDataSection}
      userInfo={userInfo}
      setUserInfo={setUserInfo}
      loading={loading}
      handleDirectPayment={handleDirectPayment}
    />
  );

  const renderSection = () => {
    const section = {
      register: subscriptionFormSection,
      'credit-card': creditCardSection,
      bankslip: bankSlipSection,
    };

    return section[showFormDataSection];
  };

  return (
    <Container>
      <Header />

      <Content>
        <PlanContainer>
          <ProductCard />
        </PlanContainer>

        {renderSection()}

        <AddCreditCard
          informations={informations}
          showFormDataSection={showFormDataSection}
          setShowFormDataSection={setShowFormDataSection}
        />
      </Content>

      <Footer />

      {renderModalSuccessPayment()}

      {renderTermsAndConditions()}

      <LoadModal show={showLoadModal} />
    </Container>
  );
};
