import {
  REACT_APP_SINGLE_LESSONS_CHANNEL,
  REACT_APP_SINGLE_LESSONS_EXCLUSIVES,
} from '../settings';
import { apiErrorMessage } from '../utils/apiErrorMessage';
import { apiResponse } from '../utils/apiResponse';
import api from './api';

const exclusiveVideoTracksId = REACT_APP_SINGLE_LESSONS_EXCLUSIVES;
const channelVideosId = REACT_APP_SINGLE_LESSONS_CHANNEL;

// export const fecthExclusiveVideoTracks = async (
//   setData = null,
//   setLoading = null,
// ) => {
//   try {
//     if (setLoading) setLoading(true);
//     const response = apiResponse(
//       await api.get(
//         `series/${exclusiveVideoTracksId}/lessons/published?limit=1000`,
//       ),
//     );
//     if (setData) setData(response.lessons);
//     if (setLoading) setLoading(false);
//   } catch (error) {
//     if (setLoading) setLoading(false);
//     apiErrorMessage(error, true);
//   }
// };

export const fecthExclusiveVideoTracks = async (
  setData = null,
  setLoading = null,
) => {
  try {
    if (setLoading) setLoading(true);
    const response = apiResponse(
      await api.get(`series/published?is_exclusive=true`),
    );
    const resultInArray = Object.values(response);
    if (setData) setData(resultInArray);
    if (setLoading) setLoading(false);
  } catch (error) {
    if (setLoading) setLoading(false);
    apiErrorMessage(error, true);
  }
};

export const fecthChannelLessons = async (
  setData = null,
  setLoading = null,
) => {
  try {
    if (setLoading) setLoading(true);
    const response = apiResponse(
      await api.get(
        `series/${channelVideosId}/lessons/published?limit=18&is_from_the_channel=true`,
      ),
    );
    if (setData) setData(response.lessons);
    if (setLoading) setLoading(false);
  } catch (error) {
    if (setLoading) setLoading(false);
    apiErrorMessage(error, true);
  }
};

export const fecthChannelSeries = async (setData = null, setLoading = null) => {
  try {
    if (setLoading) setLoading(true);
    const response = apiResponse(
      await api.get('/series/published?is_from_the_channel=true'),
    );
    const resultInArray = Object.values(response);
    if (setData) setData(resultInArray);
    if (setLoading) setLoading(false);
  } catch (error) {
    if (setLoading) setLoading(false);
    apiErrorMessage(error, true);
  }
};
