import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;

  p {
    font-family: 'Aller-Light';
    font-size: 1.9rem;
    color: #5c5c5c;
  }
`;

export const Title = styled.h1`
  font-family: 'Aller-Regular';
  font-size: 2rem;
  color: var(--dark03);
`;

export const Divisor = styled.div`
  margin-bottom: auto;
`;

export const MissionCard = styled.a`
  max-width: fit-content;
  padding: 57px 34px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    max-width: 37.3px;
  }

  span {
    font-family: 'Aller-Light';
    font-size: 2.1rem;
    color: #5c5c5c;
    margin-left: 16px;
  }

  @media (max-width: 590px) {
    flex-direction: column;
    text-align: center;

    span {
      font-size: 1.95rem;
      margin-top: 20px;
      margin-left: 0px;
    }
  }
`;
