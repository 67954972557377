import { MUserForm } from '../../mocks/MUserForm';
import InputMask from 'react-input-mask';

const MegaPersonalDataForm = ({
  setUser = null,
  user = MUserForm,
  CPFIsValid = null,
}) => {
  const onSetUser = ({ target }) => {
    setUser({ ...user, [target.name]: target.value });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <label htmlFor="name">Nome</label>
          <input
            id="name"
            required
            mask="name"
            name="name"
            placeholder="Nome completo"
            type="text"
            value={user.name}
            onChange={onSetUser}
          />
        </div>

        <div className="col-md-6">
          <label htmlFor="email">E-mail</label>
          <input
            id="email"
            required
            name="email"
            placeholder="Informe seu melhor e-mail"
            type="email"
            value={user.email}
            onChange={onSetUser}
          />
        </div>

        <div className="col-md-6">
          <label htmlFor="birthday">Data de nascimento</label>
          <input
            id="birthday"
            required
            name="birthday"
            placeholder="Informe seu melhor e-mail"
            type="date"
            value={user.birthday}
            onChange={onSetUser}
          />
        </div>

        {'cpf' in user && (
          <div className="col-md-6">
            <label htmlFor="cpf">CPF</label>
            <InputMask
              mask="999.999.999-99"
              id="cpf"
              name="cpf"
              placeholder="Informe seu CPF"
              type="text"
              value={user.cpf}
              onChange={onSetUser}
            />
            {!CPFIsValid() && (
              <small className="text-danger">Preencha o CPF corretamente</small>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default MegaPersonalDataForm;
