import React, { useState, useRef, useEffect } from 'react';

import Checkbox from '../../components/Checkbox';

import api from '../../services/api';

import { Container, Title, Text, Row, Spinner } from './styles';

const Modal = ({ setChecked, checked, setVisible }) => {
  const componentRef = useRef();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState('');

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  useEffect(() => {
    fetchTermsAndConditions();
  }, []);

  const handleClick = (e) => {
    if (!componentRef.current.contains(e.target)) {
      setVisible(false);
      return;
    }
  };

  const fetchTermsAndConditions = async () => {
    setLoading(true);
    await api
      .get('/terms-of-use')
      .then((response) => {
        setData(response.data.content);
        setLoading(false);
      })
      .catch((error) => handleError(error));
  };

  const handleError = async (error) => {
    let message;
    if (error.response.data?.message) {
      message = error.response.data.message;
    } else {
      message = error.toString();
    }

    setLoading(false);
    setData(message);
  };

  const renderText = () => {
    if (loading) {
      return <Spinner />;
    }
    return <Text>{data}</Text>;
  };

  return (
    <Container ref={componentRef}>
      <Title>Termos e Condições</Title>
      {renderText()}
      <Row>
        <Checkbox checked={checked} setChecked={setChecked} />
        <label>Li e concordo com os termos de uso</label>
      </Row>
    </Container>
  );
};

export default Modal;
