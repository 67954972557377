import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import api from '../../../../services/api';

import Card from './components/Card';
import Button from '../../../../components/Button';
import MessageModal from '../../../../components/MessageModal';
import ConfirmationModal from '../../../../components/ConfirmationModal';

import { Container, Row, Tooltip } from './styles';

const Plans = ({
  setShowLoadModal,
  plans,
  userPlan,
  checkIfUserHasAnActivePlan,
}) => {
  const history = useHistory();
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [errorMensage, setErrorMensage] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [messageConfirmationModal, setMessageConfirmationModal] = useState('');
  const [confirmAction, setConfirmAction] = useState({});
  const [invertedButtonColorModal, setInvertedButtonColorModal] = useState(
    false,
  );

  function renderShowMessageModal() {
    if (showMessageModal) {
      return (
        <MessageModal
          setVisible={setShowMessageModal}
          type="success"
          iconColor="green"
          title={title}
          description={description}
        />
      );
    }
  }

  function renderConfirmationModal() {
    if (showConfirmationModal) {
      return (
        <ConfirmationModal
          show={showConfirmationModal}
          setShow={setShowConfirmationModal}
          message={messageConfirmationModal}
          confirmAction={confirmAction}
          invertedButtonColorModal={invertedButtonColorModal}
        />
      );
    }
  }

  function renderErrorMensage() {
    if (errorMensage) {
      return <Tooltip>{errorMensage}</Tooltip>;
    }
  }

  const choosePlanClick = () => {
    setMessageConfirmationModal(
      `Deseja realmente trocar seu plano para: ${plans[0].name} ?`,
    );

    setInvertedButtonColorModal(false);
    setConfirmAction(() => choosePlan);
    setShowConfirmationModal(true);
  };

  const choosePlan = async () => {
    setShowLoadModal(true);
    try {
      const response = await api.patch(`/plans/${userPlan.id}/change-plan`, {
        plan_type_id: plans[0].id,
      });

      const newPlan = response.data;

      checkIfUserHasAnActivePlan();

      setTitle('Plano alterado com sucesso');
      setDescription(`O seu novo plano agora é: ${newPlan.plan_type.name}`);
      setShowMessageModal(true);
    } catch (error) {
      setErrorMensage(error.response.data?.message);
    }

    setShowLoadModal(false);
  };

  const cancelPlanClick = () => {
    setMessageConfirmationModal(`Deseja realmente cancelar seu plano atual?`);

    setInvertedButtonColorModal(true);
    setConfirmAction(() => cancelPlan);
    setShowConfirmationModal(true);
  };

  const cancelPlan = async () => {
    setShowLoadModal(true);
    try {
      const response = await api.patch(`/plans/${userPlan.id}/cancel`);

      setTitle('Plano cancelado com sucesso');
      setDescription('Você será redirecionado para a tela inicial');
      setShowMessageModal(true);
      setTimeout(function () {
        history.push('/');
      }, 3000);
    } catch (error) {
      setErrorMensage(error.response.data?.message);
    }

    setShowLoadModal(false);
  };

  function renderPlansOptions() {
    return plans.map((plan) => <Card plan={plan} />);
  }

  const renderCancelPlanButton = () => {
    const { status, is_active } = userPlan;

    if (status === 'authorized' && !is_active) {
      const expirationDate = new Date(userPlan.expiration_date);

      let day = expirationDate.getDate();
      if (day < 10) {
        day = '0' + day;
      }

      let month = expirationDate.getMonth() + 1;
      if (month < 10) {
        month = '0' + month;
      }

      const year = expirationDate.getFullYear();

      return (
        <div
          style={{ maxWidth: '400px', textAlign: 'center', margin: '0 auto' }}
        >
          Você já cancelou o seu plano, apenas espere o tempo que já foi pago,
          até o dia {day + '/' + month + '/' + year}
        </div>
      );
    }

    return <div onClick={() => cancelPlanClick()}>Cancelar Plano</div>;
  };

  return (
    <Container>
      <Row>{renderPlansOptions()}</Row>
      <div>
        <Button onClick={() => choosePlanClick()}>Alterar Plano</Button>
        {renderCancelPlanButton()}
      </div>
      {renderErrorMensage()}
      {renderShowMessageModal()}
      {renderConfirmationModal()}
    </Container>
  );
};

export default Plans;
