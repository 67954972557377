import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { showHeaderAndFooter } from '../../../../utils/showHeaderAndFooter';

import Menu from './../Menu';
import srcBell from './icons/bell.svg';
import srcFavorite from './icons/favorite.svg';
import srcLogo from './icons/logo.svg';
import MiniFilter from './MiniFilter';
import { Header, OptionsBox } from './styles';

const MegaHeader = () => {
  if (!showHeaderAndFooter()) return null;
  return (
    <Header>
      <Menu />
      <Link to="/">
        <img
          className="logo"
          title="Ir para tela inicial"
          alt="tela inicial"
          src={srcLogo}
        />
      </Link>
      <OptionsBox>
        <MiniFilter />
        <Tooltip title="Ver minhas notificações">
          <Link to="notificacoes">
            <img alt="minhas notificações" src={srcBell} />
          </Link>
        </Tooltip>
        <Tooltip title="Ir para os favoritos">
          <Link to="favoritos">
            <img alt="favoritos" src={srcFavorite} />
          </Link>
        </Tooltip>
      </OptionsBox>
    </Header>
  );
};
export default MegaHeader;
