import React, { useEffect, useState, memo } from 'react';

import Content from '../../../../components/Content';
import Slider3D from '../../../../components/Slider3D';

import { Container, Spinner, Title } from './styles';

const LatestLessons = ({ allSeries, showSpinner }) => {
  return (
    <Container>
      <Content>
        <Title>SÉRIES DO CANAL DA PRI</Title>
        <Slider3D route="serie" lessons={allSeries} loading={showSpinner} />
      </Content>
    </Container>
  );
};

export default memo(LatestLessons);
