import { Tooltip } from 'antd';
import React from 'react';

import LoadModal from '../../../../../../../components/LoadModal';
import api from '../../../../../../../services/api';
import { apiErrorMessage } from '../../../../../../../utils/apiErrorMessage';
import { apiResponse } from '../../../../../../../utils/apiResponse';
import { formatMoney } from '../../../../../../../utils/formatMoney';
import { removeSpacesSpecialCharacters } from '../../../../../../../utils/removeSpacesSpecialCharacters';
import lupa from './icon/lupa.svg';
import { Form, Coupon } from './styles';

class ApplyCoupon extends React.Component {
  state = {
    couponCode: '',
    coupon: '',
    loading: false,
  };

  componentDidMount() {}

  async applyCoupon(event) {
    try {
      event.preventDefault();
      const code = this.state.couponCode;
      if (code) {
        this.setState({ ...this.state, loading: true });
        const coupon = apiResponse(await api.get(`coupons/search/${code}`));
        this.setState({ ...this.state, coupon });
        this.setState({ ...this.state, loading: false });
        this.props.setCouponId(this.state.coupon.id);
      }
    } catch (error) {
      this.setState({ ...this.state, loading: false });
      apiErrorMessage(error, true);
    }
  }

  setCouponCode({ target }) {
    const couponCode = removeSpacesSpecialCharacters(
      target.value,
    ).toUpperCase();
    this.setState({ ...this.state, couponCode });
  }

  render() {
    return (
      <>
        <Form
          onSubmit={(event) => this.applyCoupon(event)}
          couponApplied={this.props.couponApplied}
        >
          <span>
            Você possui código de desconto? Insira abaixo e clique na lupa.
          </span>

          <div className="box-input">
            <input
              value={this.state.couponCode}
              onInput={(event) => this.setCouponCode(event)}
              type="text"
              name="name"
              placeholder="Código de desconto"
            />
            <Tooltip title={this.state.couponCode ? 'Aplicar cupom' : ''}>
              <img
                onClick={(event) => this.applyCoupon(event)}
                src={lupa}
                alt="Pesquisar cupom"
              />
            </Tooltip>
          </div>
        </Form>
        {this.props.couponApplied && (
          <Coupon>
            <div>
              <span>valor do cupom: </span>
              {formatMoney(this.state.coupon.value)}
            </div>
            <LoadModal show={this.state.loading} />
          </Coupon>
        )}
      </>
    );
  }
}

export default ApplyCoupon;
