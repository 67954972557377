import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  width: 420px;
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px lightgrey;
  border-radius: 6px;
  padding: 0px 20px;
  margin: 20px 0px;

  ${(props) =>
    props.error &&
    css`
      border: 1px;
      border-style: solid;
      border-color: red;
    `}
`;

export const FieldInput = styled.input`
  font-family: 'Aller-Regular';
  font-size: 1.5rem;
  color: #a2a2a2;
  border: 0;
  outline: 0;
  width: 100%;
`;

export const IconLeft = styled.img`
  margin-right: 20px;
`;

export const IconRight = styled.img`
  margin-left: 20px;
  cursor: pointer;
`;
