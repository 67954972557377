/**
 * Calculates the difference in days between a given date and the current date.
 *
 * @param {string} createdDate - The date to calculate the difference from in the format "YYYY-MM-DD".
 * @return {number} The difference in days between the given date and the current date.
 */
export const calculateDifferenceOfDays = (createdDate) => {
  const pastDate = new Date(createdDate);
  const now = new Date();
  const difference = now - pastDate;
  const numberOfDays = Math.ceil(difference / (1000 * 60 * 60 * 24));
  return numberOfDays;
};
