import styled from 'styled-components';

export const Main = styled.main`
  h1 {
    margin-bottom: 20px;
  }
`;

export const ControlButtons = styled.main`
  display: flex;
  justify-content: space-between;
`;

export const ControlContainer = styled.div`
  display: flex;
`;

export const Button = styled.button`
  outline: none;
  background-color: transparent;
  cursor: pointer;
  border: none;
  width: 48px;
  margin: 16px 0;
  transform: rotate(${(props) => (props.prev ? '180deg' : '0deg')});
`;
