import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.h1`
  font-family: 'Aller-Regular';
  font-size: 2rem;
  color: var(--dark03);
`;

export const Spinner = styled.div`
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #48e25b;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1.2s linear infinite;
  margin: 0 auto;
`;

export const Text = styled.div`
  font-family: 'Roboto-Regular';
  font-size: 1.6rem;
  color: #202125;
  margin: 40px 0px;
  width: 100%;
  overflow: auto;
  text-align: left;
  white-space: break-spaces;
`;
