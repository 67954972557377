import { Link } from 'react-router-dom';
import { showHeaderAndFooter } from '../../../../utils/showHeaderAndFooter';

import srcLogo from './icons/logo.svg';
import { Footer } from './styles';

const MegaFooter = () => {
  if (!showHeaderAndFooter()) return null;
  return (
    <>
      <Footer>
        <div>
          <div>
            <Link to="/termos-de-uso-e-privacidade">
              Termos de uso e privacidade
            </Link>
          </div>
          <div>
            <Link to="/perguntas-frequentes">perguntas frequentes</Link>
          </div>
        </div>

        <Link to="/">
          <img alt="Home" title="Voltar para tela inicial" src={srcLogo} />
        </Link>
      </Footer>
    </>
  );
};
export default MegaFooter;
