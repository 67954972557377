import styled from 'styled-components';

export const BoxLogin = styled.div`
  font-family: 'Aller-Regular';
  font-style: normal;
  h2 {
    font-size: ${(props) => (props.openDoors ? '16px' : '24px')};
    font-weight: ${(props) => (props.openDoors ? '400' : '700')};
    line-height: 29px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    div {
      width: ${(props) => (props.openDoors ? '210px' : '100%')};
    }
    input {
      width: 100%;
      background: var(--greyF5);
      box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.07);
      border-radius: 8px;
      outline: none;
      border: none;
      /* padding: ${(props) => (props.openDoors ? '16px' : '20px')}; */
      padding: 19px;
      ::placeholder {
        color: var(--grey92);
      }
    }
    .box-password {
      div {
        text-align: end;
        img {
          margin-top: -80px;
          margin-right: 15px;
          cursor: pointer;
        }
      }
    }
    button {
      border: none;
      outline: none;
      background: var(--purple84);
      border-radius: 8px;
      padding: ${(props) => (props.openDoors ? '16px 20px' : '20px')};
      color: #ffffff;
      font-weight: ${(props) => (props.openDoors ? '400' : '700')};
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
      width: ${(props) => (props.openDoors ? 'auto' : '100%')};
    }

    .links {
      flex: 1;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      flex-wrap: wrap;
      div {
        display: inline-block;
        width: auto;
        font-size: 12px;
        span {
          font-weight: 700;
        }
      }
    }
  }
`;
