import styled from 'styled-components';

export const CardPlanTimer = styled.div`
  display: flex;
  padding: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  .description {
    color: var(--Dark, #000);
    font-family: 'Aller-Regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .boxTime {
    display: flex;
    gap: 4px;
    .item {
      display: flex;
      width: 43px;
      /* padding: 4px; */
      flex-direction: column;
      align-items: center;
      background: #d1b8eb;
      background: #80f08c;
      .value {
        color: #000;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
      }
      .label {
        color: #000;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
      }
      :last-child {
        border-radius: 0 4px 4px 0;
      }
      :first-child {
        border-radius: 4px 0 0 4px;
      }
    }
  }
`;
