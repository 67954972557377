import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';

export const CardSkeleton = styled(Skeleton)`
  width: 300px;
  height: 200px;
  margin: 0px 0px;
  border-radius: 20px;
  cursor: pointer;

  @media (max-width: 400px) {
    height: 140px;
  }
`;

export const ContainerCard = styled.div``;

export const Card = styled.img`
  display: block;
  width: ${(props) => (props.podcastOrMeditation ? '200px' : '100%')};
  height: 0px;
  margin: 0px 0px;
  cursor: pointer;
  transition: all 1s;
  opacity: 0;
  border-radius: ${(props) => (props.podcastOrMeditation ? 10 : 0)}px;

  @media (max-width: 350px) {
    width: ${(props) => (props.podcastOrMeditation ? '160px' : '100%')};
  }
`;

export const ListCardsWithouSlider = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  margin: 0 20px;
  > img {
    margin: 0 20px;
  }

  @media (max-width: 1060px) {
    > img {
      margin: 20px 20px;
    }
  }

  @media (max-width: 720px) {
    > img {
      width: 270px;
    }
    margin: 0px;
  }
`;
