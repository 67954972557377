import { Tooltip } from 'antd';
import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import bookIcon from './icons/bookIcon.svg';
import clockIcon from './icons/clockIcon.svg';
import { Card, BoxFooter, BoxTitle, Live } from './styles';

const CardResult = ({ result, setModalIsOpen }) => {
  const history = useHistory();
  const [itsSerie, setItsSerie] = useState(false);
  const [route, setRoute] = useState('aula');

  useEffect(() => {
    function checkIfTheItemIsSeries() {
      if (result.hasOwnProperty('number_of_lessons')) {
        setRoute('serie');
        setItsSerie(true);
      }
      if (result.hasOwnProperty('type') && result.type === 'podcast') {
        setRoute('podcast');
        setItsSerie(false);
      }
    }
    checkIfTheItemIsSeries();
  }, []);

  const RenderSerieInformations = () => {
    if (itsSerie) {
      const cardInfo = () => (
        <div>
          <img src={bookIcon} alt="Agenda" />
          {result.number_of_lessons === 0 ? (
            <small>Sem aula</small>
          ) : (
            <span>{result.number_of_lessons} aulas</span>
          )}
        </div>
      );

      return cardInfo();
    }
    return null;
  };

  const goToDetails = () => {
    if (setModalIsOpen) setModalIsOpen(false);
    history.push(`/${route}/${result.id}`);
  };

  return (
    <>
      <Tooltip
        title={`Assistir: ${result.name || result.title}`}
        color="#A16CCD"
      >
        <Card
          bgImage={encodeURI(result.thumbnail_url)}
          mobileThumbnail={encodeURI(result.mobile_thumbnail_url)}
        >
          <div className="box-header"></div>
          <BoxFooter>
            {result.is_live ? (
              <a rel="noopener noreferrer" href={result?.url} target="_blank">
                <h5>{result.name || result.title}</h5>
              </a>
            ) : (
              <BoxTitle>
                <h5 onClick={goToDetails}>{result.name || result.title}</h5>
              </BoxTitle>
            )}

            <div>
              {result.is_live ? (
                <Live>Ao vivo</Live>
              ) : (
                <div>
                  <img src={clockIcon} alt="Duração" /> {result.duration} min.
                </div>
              )}

              <RenderSerieInformations />
            </div>
          </BoxFooter>
        </Card>
      </Tooltip>
    </>
  );
};

export default CardResult;
