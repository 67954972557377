import { message } from 'antd';
import { Link } from 'react-router-dom';

import PlanCard from '../PlanCard';
import { Box, BoxPIX, BoxBankSlip } from './styles';
import { formatMoney } from '../../../../../../utils/formatMoney';

const Checkout = ({ paymentInformation }) => {
  const copyQRCode = () => {
    if (
      paymentInformation?.hasOwnProperty('pix') &&
      paymentInformation.pix.qrcode
    ) {
      navigator.clipboard.writeText(paymentInformation.pix.qrcode_text);
      message.success('Código copiado com sucesso!');
    }
  };

  const copyCodeBankSlip = () => {
    if (
      paymentInformation?.hasOwnProperty('bank_slip') &&
      paymentInformation.bank_slip.digitable_line
    ) {
      navigator.clipboard.writeText(
        paymentInformation.bank_slip.digitable_line,
      );
      message.success('Código de barras copiado com sucesso!');
    }
  };

  const controlScreens = () => {
    if (
      paymentInformation?.hasOwnProperty('pix') &&
      paymentInformation.pix.qrcode
    ) {
      return (
        <>
          <BoxPIX>
            <h2>Faça seu pagamento PIX</h2>
            <img src={paymentInformation.pix.qrcode} alt="QR Code" />
            <div>
              Escaneie o QR code com o aplicativo do seu banco para efetuar o
              seu pagamento.
            </div>

            <button onClick={() => copyQRCode()}>Copiar código</button>

            <div>
              <div>
                <b>Titular: </b> <span>Yoa.co</span>
              </div>
              <div>
                <b>Pagamento em: </b>{' '}
                <span>{new Date().toLocaleDateString()}</span>
              </div>
              <div>
                <b>Valor: </b>{' '}
                <span>{formatMoney(paymentInformation.price)}</span>
              </div>
            </div>
            <Link to={'/home'}>
              <button>Já paguei</button>
            </Link>
          </BoxPIX>
        </>
      );
    } else if (
      paymentInformation?.hasOwnProperty('bank_slip') &&
      paymentInformation.bank_slip.digitable_line
    ) {
      return (
        <>
          <BoxBankSlip>
            <h2>Faça seu pagamento por boleto</h2>
            <img src={paymentInformation.bank_slip.barcode} alt="QR Code" />
            <div>
              Escaneie o código de barras com o aplicativo do seu banco para
              efetuar o seu pagamento.
            </div>

            <button onClick={() => copyCodeBankSlip()}>Copiar código</button>

            <div>
              <div>
                <b>Titular: </b> <span>Yoa.co</span>
              </div>
              <div>
                <b>Pagamento em: </b>{' '}
                <span>{new Date().toLocaleDateString()}</span>
              </div>
              <div>
                <b>Valor: </b>{' '}
                <span>{formatMoney(paymentInformation.price)}</span>
              </div>
            </div>
            <Link to={'/home'}>
              <button>Já paguei</button>
            </Link>
          </BoxBankSlip>
        </>
      );
    }
  };

  return (
    <>
      <Box>
        <PlanCard startOfRegistration={true} />
        {controlScreens()}
      </Box>
    </>
  );
};

export default Checkout;
