import styled from 'styled-components';

export const BoxPlanSubscriptionData = styled.div`
  margin-top: 48px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 15px;

  font-family: Aller-Regular;
  color: #030303;
  font-style: normal;
  line-height: 140%;
  font-weight: 400;

  h1 {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: normal;
  }

  h5 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 16px;
  }

  .cursor {
    cursor: pointer;
  }

  .bold {
    color: #030303;
    font-family: Aller-Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }

  .informations {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    span {
      margin-right: 16px;
    }
  }
`;

export const BoxPlanSubscriptionDataContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;

  table {
    width: 100%;
    thead {
      th {
        padding: 6px 0px;
      }
    }
    tbody {
      tr {
        td {
          padding: 6px 0px;
        }
      }
    }
  }
`;

export const BoxPaymentMethod = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
