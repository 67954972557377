import { useHistory } from 'react-router-dom';

import thumbnail1 from '../../../../../../assets/carousel/thumbnail1.png';
import numberLessons from '../../../../../../assets/favorites/number-lessons.png';
import durationIcon from '../../../../../../assets/favorites/duration.png';

import { Progress } from 'antd';

import {
  CardContainer,
  Card,
  CardHeader,
  CardBody,
  Description,
} from './styles';

const SerieCard = ({ serie }) => {
  const history = useHistory();

  function renderNumberLessons() {
    let { number_of_lessons } = serie.post.series;

    if (number_of_lessons === 1) {
      return <span>1 aula</span>;
    } else if (number_of_lessons > 1) {
      return <span>{number_of_lessons} aulas</span>;
    }
  }

  function renderDuration() {
    const { duration } = serie.post.series;
    if (duration > 0) {
      if (duration < 60) {
        return (
          <span>
            <img src={durationIcon} alt="Ícone de tempo da aula" />
            <span>{duration} min</span>
          </span>
        );
      } else {
        const hours = Math.trunc(duration / 60);
        const minutes = Math.trunc(duration - 60 * hours);
        return (
          <span>
            <img src={durationIcon} alt="Ícone de tempo da aula" />
            {hours} {hours === 1 ? 'hora' : 'horas'}
            {minutes > 0 && ` e ${minutes} minutos`}
          </span>
        );
      }
    }
  }

  return (
    <CardContainer>
      <Card>
        <CardHeader
          onClick={() => history.push(`/serie/${serie.post.series.id}`)}
        >
          <img src={serie.post.series.thumbnail_url} />
        </CardHeader>
        <CardBody>
          <span>
            <img src={numberLessons} alt="Ícone do número de aulas" />
            {renderNumberLessons()}
          </span>
          {renderDuration()}
        </CardBody>
      </Card>

      <Description>
        <h1 onClick={() => history.push(`/serie/${serie.post.series.id}`)}>
          {serie.post.series.title}
        </h1>
        <Progress
          percent={parseInt(serie.percentage)}
          format={(percent) => `${parseInt(percent)}% concluído`}
        />
      </Description>
    </CardContainer>
  );
};

export default SerieCard;
