import styled from 'styled-components';

export const PodcastWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  animation: rendering 0.5s;
`;

export const Container = styled.div`
  position: relative;
  width: 300px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  cursor: pointer;

  > img {
    width: 100%;
    height: 300px;
    border-radius: 10px;
  }

  > div {
    padding: 20px;
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    > ul {
      font-family: 'Aller-Regular';
      font-size: 1.2rem;
      color: #3c3c3c;
      list-style: none;

      img {
        height: 25px;
        margin-right: 10px;
      }

      li:nth-of-type(1) {
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 1220px) {
    margin: 20px auto;
  }
`;

export const NameLatestPodcastSerie = styled.span`
  font-family: 'Aller-Bold';
  font-size: 2.2rem;
  color: #fff;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 3%;
  font-family: 'Aller-Regular';
  color: #b3b1b1;
  font-size: 1.8rem;
  h1 {
    color: var(--dark03);
    font-size: 2.5rem;
    cursor: pointer;
  }
  span {
    color: #5c5c5c;
  }
  p {
    img {
      margin-right: 15px;
      width: 22px;
      height: auto;
    }
  }

  .ant-progress {
    display: flex;
    flex-direction: column;
    padding: 15px 0;
    .ant-progress-inner {
      border-radius: 0;
      background-color: #eae8e8;
      .ant-progress-bg {
        height: 5px;
        border-radius: 0;
        background-color: #61e171;
      }
    }
    .ant-progress-text {
      color: #b3b1b1;
      margin-left: 0;
      font-size: 23px;
      padding: 15px 0;
    }
  }
`;
