import styled from 'styled-components';

export const Container = styled.section``;

export const GradientBackground = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 500px;
  z-index: -1;
  background: rgb(97, 225, 113);
  background: linear-gradient(
    180deg,
    rgba(97, 225, 113, 1) 0%,
    rgba(248, 251, 246, 1) 70%,
    rgba(255, 255, 255, 1) 80%
  );
`;

export const Thumbnail = styled.img`
  display: block;
  margin: 0 auto;
  width: auto;
  height: ${(props) => (props.show ? 300 : 0)}px;
  border-radius: 20px;
  transition: all 1s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  cursor: pointer;

  @media (max-width: 560px) {
    height: 200px;
  }

  @media (max-width: 375px) {
    height: 180px;
  }

  @media (max-width: 360px) {
    height: 160px;
  }

  @media (max-width: 320px) {
    height: 140px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;

  > p {
    font-family: 'Aller-Light';
    font-size: 1.4rem;
    color: #5c5c5c;
    width: 60%;
    margin-top: 20px;
  }

  > img {
    height: 150px;
    width: 190px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    text-align: center;

    > p {
      width: 100%;
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;

  > div > h1 {
    font-family: 'Aller-Regular';
    font-size: 2rem;
    color: var(--dark03);
    font-weight: normal;
  }

  ul {
    display: flex;
    list-style: none;
    margin-top: 20px;

    li {
      margin-right: 20px;

      img {
        height: 25px;
      }
    }

    li:nth-of-type(1) {
      position: relative;
      cursor: pointer;
    }

    li:nth-of-type(3) {
      display: flex;
      align-items: center;
      margin-left: 20px;

      img {
        height: 25px;
      }

      span {
        font-family: 'Aller-Regular';
        font-size: 1.6rem;
        color: #5c5c5c;
        margin-left: 10px;
      }
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;

    ul {
      justify-content: center;
      margin-bottom: 20px;
    }
  }
`;

export const Description = styled.div`
  margin: 20px 0px;
`;

export const IconTV = styled.img`
  width: 80px;
  height: 80px;
`;

export const Divider = styled.div`
  height: 2px;
  width: 40%;
  background-color: #b86ef6;
  margin: 20px 0px;

  @media (max-width: 1024px) {
    margin: 20px auto;
  }
`;

export const DataAboutSerie = styled.div`
  width: 60%;

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      margin: 0px 40px 0px 0px;

      span {
        font-family: 'Aller-Regular';
        font-size: 1.4rem;
        color: #3c3c3c;
        margin-left: 10px;
      }

      img {
        height: 25px;
      }
    }

    li:nth-of-type(1) {
      img {
        height: 25px;
      }
    }
  }

  @media (max-width: 1024px) {
    width: 100%;

    ul {
      justify-content: center;

      li {
        margin: 20px 10px;
      }
    }
  }
`;

export const ScheduleSerieButton = styled.div`
  font-family: 'Aller-Light';
  color: #5c5c5c;
  box-shadow: 0px 0px 6px 0px lightgrey;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  height: fit-content;
  border-radius: 10px;
  font-size: 1.1rem;
  cursor: pointer;

  img {
    height: auto;
    width: 40px;
    margin: 0 0 6px auto;
  }
`;

export const Spinner = styled.div`
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #61e171;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1.2s linear infinite;
  margin: 40px auto -40px auto;
`;

export const ShowMoreLessons = styled.p`
  font-family: 'Aller-Regular';
  font-size: 1.4rem;
  color: var(--dark03);
  margin: 0 auto;
  cursor: pointer;
  text-align: center;
`;
