import styled from 'styled-components';

export const Container = styled.form`
  animation: rendering 0.5s;
  display: flex;
  align-items: center;

  img {
    border-radius: 50%;
    height: 70px;
    width: 70px;
  }

  > div:nth-of-type(1) {
    margin-left: 20px;
    height: 80px;
    width: 500px;
    border-radius: 14px;

    > input {
      font-family: 'Aller-Light';
      color: #acaaaa;
    }
  }

  button {
    width: 200px;
    margin-left: 20px;
  }

  @media (max-width: 1040px) {
    img {
      height: 50px;
      width: 50px;
    }

    button {
      display: none;
    }

    > div:nth-of-type(1) {
      width: 100%;
      height: 60px;
    }
  }

  @media (max-width: 500px) {
    margin-left: -120px;

    > div:nth-of-type(1) {
      margin-left: 10px;
    }
  }
`;
