import { Tooltip } from 'antd';
import { BoxQuestionsViaWhatsApp } from './styles';
import { WHATSAPP_CONTACT_NUMBER } from '../../../../settings';
import whatsAppIcon from './icon/whatsApp.svg';

const QuestionsViaWhatsApp = () => {
  const title = 'Preciso de ajuda';
  const message = encodeURI(
    'Preciso de ajuda, quando as turmas serão abertas novamente?',
  );

  return (
    <BoxQuestionsViaWhatsApp>
      <h2>Alguma dúvida?</h2>
      <Tooltip title={title} color={'#A16CCD'}>
        {/* href={`https://api.whatsapp.com/send?phone=${WHATSAPP_CONTACT_NUMBER}&text=${message}`} */}
        <a
          href={WHATSAPP_CONTACT_NUMBER}
          target="_blank"
          without
          rel="noreferrer"
        >
          <button>
            <img src={whatsAppIcon} alt="whatsapp" />
            <span>Vem conversar com a gente pelo WhatsApp</span>
          </button>
        </a>
      </Tooltip>
    </BoxQuestionsViaWhatsApp>
  );
};

export default QuestionsViaWhatsApp;
