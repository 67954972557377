import React from 'react';

import api from '../../../../services/api';
import Carousels from '../../components/Carousel';
import { Box } from './styles';

class HighlightsClasses extends React.Component {
  state = {
    classes: [],
  };

  async getHighlightsClasses() {
    const orderBy = (a, b) => {
      return Number(b.is_live) - Number(a.is_live);
    };

    const seriesParams = 'series/published?is_highlight=true';
    const lessonsParams = 'lessons/published?is_highlight=true';
    const { data: series } = await api.get(seriesParams);
    const { data: lessons } = await api.get(lessonsParams);
    const classes = [...series, ...lessons].sort(orderBy);
    this.setState({ classes, ...classes });
  }

  componentDidMount() {
    this.getHighlightsClasses();
  }

  render() {
    return (
      <>
        <Box>
          {!!this.state.classes.length && (
            <Carousels
              titleHightlight="DESTAQUES"
              items={this.state.classes}
              autoplay={true}
              showControlButtons={this.state.classes.length > 4 ? true : false}
            />
          )}
        </Box>
      </>
    );
  }
}

export default HighlightsClasses;
