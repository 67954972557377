import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import backIcon from '../../assets/choosePlan/backIcon.png';
import ButtonLoading from '../../components/ButtonLoading';
import MessageModal from '../../components/MessageModal';
import api from '../../services/api';
import { apiErrorMessage } from '../../utils/apiErrorMessage';
import { Box } from './styles';

const ChangePassword = () => {
  let query = useQuery();
  const token = query.get('token');

  const history = useHistory();

  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });
  const [differentPasswords, setDifferentPasswords] = useState(false);
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowLoadModal(true);
    try {
      await api.post('/password/reset', {
        token,
        password: formData.password,
        password_confirmation: formData.confirmPassword,
      });
      setShowMessageModal(true);
      setTimeout(function () {
        history.push('/login');
      }, 3000);
    } catch (error) {
      apiErrorMessage(error, true);
    }
    setShowLoadModal(false);
  };

  function comparePasswordAndPasswordConfirmation() {
    const password = formData.password;
    const confirmPassword = formData.confirmPassword;

    if (
      password.length > 0 &&
      confirmPassword.length > 0 &&
      password !== confirmPassword
    ) {
      setDifferentPasswords(true);
    } else {
      setDifferentPasswords(false);
    }
  }

  function renderMessageModal() {
    if (showMessageModal) {
      return (
        <MessageModal
          title="Senha alterada com sucesso"
          description="Redirecionaremos você para a tela de login"
          type="success"
          iconColor="green"
          setVisible={setShowMessageModal}
        />
      );
    }
  }

  useEffect(() => {
    comparePasswordAndPasswordConfirmation();
  }, [formData.password, formData.confirmPassword]);

  return (
    <Box>
      <form onSubmit={(event) => handleSubmit(event)}>
        <h2>Alterar senha</h2>
        <div>
          <input
            type="password"
            placeholder="Senha"
            required
            onInput={(event) =>
              setFormData({ ...formData, password: event.target.value })
            }
          />
        </div>
        <div>
          <input
            type="password"
            placeholder="Confirmar Senha"
            required
            onInput={(event) =>
              setFormData({
                ...formData,
                confirmPassword: event.target.value,
              })
            }
          />
          {differentPasswords && <small>As senhas não são iguais</small>}
        </div>
        {renderMessageModal()}
        <img
          onClick={() => history.goBack()}
          src={backIcon}
          alt="Ícone de voltar para a página anterior"
        />
        <ButtonLoading
          loading={showLoadModal}
          type="submit"
          disabled={showLoadModal || differentPasswords}
          label="Alterar"
          width="102.25px"
        />
      </form>
    </Box>
  );
};

export default ChangePassword;
