export const MPlanStatus = {
  pending: {
    label: 'Pendente',
    class: 'text-warning',
  },
  authorized: {
    label: 'Autorizado',
    class: 'text-success',
  },
  suspended: {
    label: 'Suspenso',
    class: 'text-danger',
  },
  canceled: {
    label: 'Cancelado',
    class: 'text-danger',
  },
  expired: {
    label: 'Expirado',
    class: 'text-danger',
  },
};
