import React from 'react';

import LetterIcon from '../../assets/ContactUs/letter.png';
import Content from '../../components/Content';
import { CONTACT_EMAIL } from '../../settings';
import { Container, Divisor, MissionCard, Title } from './styles';

const AboutUs = () => {
  return (
    <>
      <Container>
        <Content>
          <Title>Fale conosco</Title>
          <p>Estamos aqui sempre para você!</p>

          <MissionCard href={`mailto:${CONTACT_EMAIL}`}>
            <img src={LetterIcon} alt="ícone de carta" />
            <span>E-mail: {CONTACT_EMAIL}</span>
          </MissionCard>
        </Content>
        <Divisor />
      </Container>
    </>
  );
};

export default AboutUs;
