import React from 'react';

import {
  Card,
  Content
} from './styles';

const Plans = ({
  img,
  imgAlt,
  title,
  content,
}) => {
  return (
    <>
      <Card>
        <h1>{title}</h1>
        <img src={img} alt={imgAlt}/>
      </Card>

      <Content>
        <h1>{title}:</h1>
        <p>{content}</p>
      </Content>
    </>
  );
};

export default Plans;
