import { Informations } from './styles';
import QuestionsViaWhatsApp from '../../../QuestionsViaWhatsApp';

const PlanInformation = ({ plan }) => {
  if (plan?.type === 'anual')
    return (
      <Informations>
        <p>Validade do plano:</p>
        <b>1 ano</b>
        <QuestionsViaWhatsApp />
      </Informations>
    );
  return null;
};

export default PlanInformation;
