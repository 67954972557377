import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import LoadModal from '../../../../components/LoadModal';
import api from '../../../../services/api';
import { apiErrorMessage } from '../../../../utils/apiErrorMessage';
import { apiResponse } from '../../../../utils/apiResponse';
import Checkout from './components/Checkout';
import UserWithPlan from './components/UserWithPlan';
import PaymentOptions from './components/PaymentOptions';
import PlanAdvertisement from './components/PlanAdvertisement';
import { Main } from './styles';
import { MStatusToRenewPlan } from '../../../../mocks/MStatusToRenewPlan';
import AuthorizedPayment from './components/AuthorizedPayment';
import CardTransactionFailure from './components/CardTransactionFailure';

const Plan = ({ subscription = null }) => {
  const userLogged = useSelector((state) => state.User);
  const [currentStepIndex, setCurrentStepIndex] = useState('');
  // const [subscription, setSubscription] = useState(null);
  const [paymentInformation, setPaymentInformation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [planOnOffer, setPlanOnOffer] = useState(null);
  const [hadAnActivePlan, setHadAnActivePlan] = useState(false);

  // useEffect(() => {
  //   setCurrentStepIndex(4);
  // });

  useEffect(() => {
    const completedOrAwaitingPayment = ['authorized', 'pending'];
    const hasPlanRegistration = subscription ? true : false;
    if (
      hasPlanRegistration &&
      completedOrAwaitingPayment.includes(subscription.status)
    )
      setCurrentStepIndex(5);
    else
      getPlanOnOffer().then((planTypes) => {
        const thereIsAPlanAvailable = planTypes?.length;
        const formerStudent = MStatusToRenewPlan.includes(subscription?.status);
        setHadAnActivePlan(formerStudent);
        if (!thereIsAPlanAvailable) setCurrentStepIndex(0);
        else if (thereIsAPlanAvailable) {
          setPlanOnOffer(planTypes[0]);
          setCurrentStepIndex(0);
        }
      });
  }, []);

  const getPlanOnOffer = async () => {
    try {
      setLoading(true);
      const { data: planTypes } = await api.get('plan-types?isVisible=true');
      setLoading(false);
      return planTypes;
    } catch (error) {
      setLoading(false);
      apiErrorMessage(error, true);
    }
  };

  const onSetStep = (indexScreen) => {
    setCurrentStepIndex(indexScreen);
  };

  const onSetPaymentInformation = (value) => {
    setPaymentInformation(value);
  };

  const ControlScreen = () => {
    const steps = [
      {
        name: 'showPlanOffer',
        render: (
          <PlanAdvertisement
            planOnOffer={planOnOffer}
            setStep={onSetStep}
            hadAnActivePlan={hadAnActivePlan}
            subscription={subscription}
          />
        ),
      },
      {
        name: 'choosePaymentMethod',
        render: (
          <PaymentOptions
            user={userLogged}
            setStep={onSetStep}
            planOnOffer={planOnOffer}
            setPaymentInformation={onSetPaymentInformation}
          />
        ),
      },
      {
        name: 'makeThePayment',
        render: <Checkout paymentInformation={paymentInformation} />,
      },
      {
        name: 'activePlanSuccessfully',
        render: <AuthorizedPayment></AuthorizedPayment>,
      },
      {
        name: 'failedToActivateThePlan',
        render: (
          <CardTransactionFailure setStep={onSetStep}></CardTransactionFailure>
        ),
      },
      {
        name: 'activePlanSuccessfully',
        render: (
          <UserWithPlan
            setStep={onSetStep}
            startOfRegistration={false}
            subscription={subscription}
            planOnOffer={planOnOffer}
          ></UserWithPlan>
        ),
      },
    ];

    return currentStepIndex !== '' ? steps[currentStepIndex].render : null;
  };

  return (
    <>
      <Main>
        <ControlScreen />
        <LoadModal show={loading} />
      </Main>
    </>
  );
};

export default Plan;
