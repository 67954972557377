import { Main, Option } from './styles';

const Menu = ({
  menu = [],
  selectedScreenIndex,
  onSetSelectedScreenIndex = () => {},
}) => {
  return (
    <>
      <Main>
        {menu.map((item) => (
          <div
            key={item.key}
            onClick={() => onSetSelectedScreenIndex(item.key)}
          >
            <div className="profile">{item.label}</div>
            <Option selected={selectedScreenIndex === item.key} />
          </div>
        ))}
      </Main>
    </>
  );
};

export default Menu;
