import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import Content from '../../components/Content';
import Footer from '../../components/Footer';
import Button from '../../components/Button';
import CardSection from '../AboutUs/components/CardSection';

import MountainIcon from '../../assets/aboutUs/montanha.png';
import BackgroundImg from '../../assets/aboutUs/backgroundImg.png';
import Eye from '../../assets/aboutUs/eye.png';
import Hand from '../../assets/aboutUs/hand.png';
import Group from '../../assets/aboutUs/group.png';
import Star from '../../assets/aboutUs/star.png';
import YogaMind from '../../assets/aboutUs/yogaMind.png';
import Brain from '../../assets/aboutUs/brain.png';
import TwoHands from '../../assets/aboutUs/twoHands.png';
import Up from '../../assets/aboutUs/up.png';
import Logo from '../../assets/login/logo-with-legend@2x.png';

import {
  Container,
  GradientBackground,
  Title,
  MissionCard,
  IconTitle,
  ImageContainer,
  Paragraph,
  Promote
} from './styles';

const AboutUs = () => {

  return (
    <Container>
      <GradientBackground />
      <Header />
      <Content>
        <Title>Sobre nós</Title>
      </Content>

      <MissionCard>
        <div>
          <img src={MountainIcon} alt="ícone de montanha"/>
          <span>Missão</span>
        </div>

        <p>
          A missão do Yoga CO. é ser parte ativa<br />
          na criação de um mundo mais justo, livre e saudável<br />
          através da acessibilização de práticas de yoga.
        </p>
      </MissionCard>
      <ImageContainer>
        <img src={BackgroundImg} alt="Príscila praticando yoga"/>
      </ ImageContainer>

      <Content>
        <Paragraph>
          <p>
            Ele foi criado para manter o <a href="https://www.youtube.com/channel/UCehH0gfC-ivKz_htoMHxHtA" target="blank_">canal Pri Leite Yoga</a> vivo, forte e saudável.
          </ p>
          <p>
            Cada assinatura é um gesto de apoio ao nosso trabalho de oferecer <b>aulas de yoga online gratuitas</b> de qualidade para todos. Em troca, nossa comunidade apoiadora recebe acesso a conteúdos exclusivos como o meu podcast: De Peito Aberto e diversas aulas feitas especialmente para Yoga Co.
          </ p>
          <p>
            Todo o nosso conteúdo busca facilitar uma mudança positiva na sua vida e no mundo. Somos uma empresa que foca em bem-estar, qualidade e consciência.
          </ p>
        </Paragraph>

        <IconTitle>
          <img src={Eye} alt="ícone de olho"/>
          <span>Visão</span>
        </IconTitle>

        <Paragraph>
          <p>- Ser reconhecida pelo nosso compromisso com a essência do yoga, tornando acessível a prática de <b>Yoga no Brasil</b>;</ p>
          <p>- Deixar um legado de apoio à saúde, bem-estar físico, emocional, intelectual e comunitário.</ p>
          <p>- Tornar a nossa comunidade mais empoderada, acolhida e feliz.</ p>
        </Paragraph>

        <IconTitle>
          <img src={Hand} alt="ícone duas mãos segurando um coração"/>
          <span>Valores</span>
        </IconTitle>

        <Paragraph>
          <p>No Yoga Co., nossos valores essenciais conduzem nossa missão e capacitam nosso processo de tomada de decisão:</ p>
        </Paragraph>

        <CardSection
          img={Group}
          imgAlt={'Ícone grupo de pessoas'}
          title={'Comunidade'} content={'A nossa força motriz é a nossa comunidade. Ela está sempre por trás de qualquer decisão.'}>
        </CardSection>

        <CardSection
          img={Star}
          imgAlt={'Ícone de acessibilidade'}
          title={'Acessibilidade'} content={'Acreditamos que a prática do Yoga melhora a saúde e o bem-estar, e que todas as pessoas devem ter direito a esse benefício sem discriminação. Por isso, nos dedicamos para garantir a criação de um ambiente acolhedor e acessível para todos.'}>
        </CardSection>

        <CardSection
          img={YogaMind}
          imgAlt={'Ícone yoga e bem-estar'}
          title={'Bem-estar'} content={'Através da prática torna-se possível entrar em um estado de consciência corporal e mental para uma vida mais positiva. A Yoga nos ajuda a nos conectar com o momento presente e é capaz de cuidar do corpo e acalmar a mente. Desta forma, nosso propósito é que mais pessoas sejam agraciadas com esse presente. '}>
        </CardSection>

        <CardSection
          img={Brain}
          imgAlt={'Ícone autoconhecimento'}
          title={'Autoconhecimento'} content={'O Yoga é um convite a mergulhar dentro de si. A prática como ciência de autoconhecimento está disponível para qualquer pessoa que queira ser consciente do momento que vive . O Yoga Co. existe para te ajudar a despertar e potencializar a força que já existe dentro de você. A prática não promete resolver todos os seus problemas, mas sem dúvidas nos ajuda a ter a calma necessária e discernimento para resolvê-los. '}>
        </CardSection>

        <CardSection
          img={TwoHands}
          imgAlt={'Ícone de autocuidado: '}
          title={'Autocuidado'} content={'Olhar para si com cuidado e afeto, prestando atenção às suas necessidades particulares, nos ajuda a cultivar o bem-estar físico e emocional. No Yoga Co., compartilhamos diversas práticas para inspirar você a ter momentos de amor próprio, e ver a vida como uma realidade a ser experimentada. Quando paramos de nos apressar pela vida, percebemos quanto tempo temos para viver aquilo que nos faz bem. '}>
        </CardSection>

        <CardSection
          img={Up}
          imgAlt={'Ícone de Mudança'}
          title={'Mudança'} content={'Acreditamos que a prática de Yoga auxilia na criação de um mundo mais justo, livre, consciente e acolhedor. Nossa esperança é que todos os que passarem por nossa porta virtual sejam preenchidos com uma sensação de positividade, empoderamento e comunidade.'}>
        </CardSection>

        <Promote>
          <img src={Logo} alt="Logo Yoga Co."/>

          <Button style={{margin: '0 auto'}}>
            <Link to="/escolha-um-plano">Assine Já</Link>
          </Button>
        </Promote>
      </Content>
      <Footer />
    </Container>
  );
};

export default AboutUs;
