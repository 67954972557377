import React, { useRef } from 'react';

import Input from '../../../../components/Input';

import heart from '../../../../assets/home/heart.png';
import searchIcon from '../../../../assets/home/search.png';

import { Container } from './styles';

const Search = ({ setSearch }) => {
  const inputRef = useRef();

  const handleSearch = () => {
    const value = document.getElementById('search-my-lessons').value;
    setSearch(value);
  };

  return (
    <Container>
      <Input
        id="search-my-lessons"
        placeholder="Buscar"
        iconLeft={heart}
        iconRight={searchIcon}
        ref={inputRef}
        onClickIconRight={() => handleSearch()}
        onBlur={() => handleSearch()}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSearch();
          }
        }}
      />
    </Container>
  );
};

export default Search;
