import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Content from '../../components/Content';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import LoadModal from '../../components/LoadModal';
import MessageModal from '../../components/MessageModal';
import NoAccessModal from '../../components/NoAccessModal';
import api from '../../services/api';
import FilteredContent from './components/FilteredContent';
import FilterSelect from './components/FilterSelect';
import Logo from './components/Logo';
import Search from './components/Search';
import { Container } from './styles';

const Mylessons = (resume = false) => {
  const user = useSelector((state) => state.User);
  const [historic, setHistoric] = useState({
    series: [],
    podcastSeries: [],
    meditationSeries: [],
    lessons: [],
    podcasts: [],
    meditations: [],
  });
  const [filterOption, setFilterOption] = useState('');
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState('');
  const [showNoAcessModal, setShowNoAccessModal] = useState(false);
  const [error, setError] = useState('');
  const [showMessageModal, setShowMessageModal] = useState(false);

  const fetchHistoric = async () => {
    setLoading(true);
    await api
      .get('/history')
      .then((response) => {
        setHistoric(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  };

  const noDataFound = () => {
    const noRegisteredData =
      !historic.lessons.length &&
      !historic.lessons.length &&
      !historic.lessons.length &&
      !historic.lessons.length &&
      !historic.lessons.length &&
      !historic.lessons.length;
    return noRegisteredData;
  };

  const handleUserAccess = () => {
    if (!user.id.trim() || !user.accessToken.trim()) {
      setShowNoAccessModal(true);
      return;
    }

    fetchHistoric();
  };

  const fetchIfUserHasPlanActive = async () => {
    const userHasPlanActive = await api
      .get(`/users/plans`)
      .then((response) => {
        if (
          response.data.status === 'pending' ||
          response.data.status === 'suspended'
        ) {
          return false;
        }
        return true;
      })
      .catch((error) => {
        return false;
      });

    return userHasPlanActive;
  };

  const handleError = async (error) => {
    let message;
    if (error.response.data?.message) {
      message = error.response.data.message;
    } else {
      message = error.toString();
    }

    const userHasPlanActive = await fetchIfUserHasPlanActive();

    if (userHasPlanActive) {
      setError(message);
      setShowMessageModal(true);
    } else {
      setShowNoAccessModal(true);
    }
  };

  useEffect(() => {
    handleUserAccess();
  }, []);

  const renderLoadModal = () => {
    if (showLoadModal) {
      return <LoadModal show={showLoadModal} />;
    }
  };

  const renderNoAccessModal = () => {
    if (showNoAcessModal) {
      return <NoAccessModal show={showNoAcessModal} blockedPage={true} />;
    }
  };

  const renderMessageModal = () => {
    if (showMessageModal) {
      return (
        <MessageModal
          title="Ocorreu um erro!"
          description={error}
          setVisible={setShowMessageModal}
        />
      );
    }
  };

  return (
    <Container id="favorites">
      {resume === false && <Header />}
      {resume === false && <Logo />}

      {noDataFound() === false && (
        <Content>
          <Search setSearch={setSearch} />
          <FilterSelect setFilterOption={setFilterOption} />
          <FilteredContent
            historic={historic}
            filterOption={filterOption}
            setShowLoadModal={setShowLoadModal}
            search={search}
          />
        </Content>
      )}
      {noDataFound() === true && !loading && (
        <h2>Nenhum histórico encontrado</h2>
      )}
      {resume === false && <Footer />}
      {renderLoadModal()}
      {renderNoAccessModal()}
      {renderMessageModal()}
    </Container>
  );
};

export default Mylessons;
