import styled from 'styled-components';

export const Main = styled.main`
  display: flex;
  gap: 25px;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: var(--purpleD1);
  padding: 25px 25px 75px 25px;
  word-break: break-word;
`;
export const Description = styled.main`
  margin: auto 0;
  h2 {
    font-family: 'Aller-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    color: var(--dark03);
    margin: 25px 0 50px 0;
  }
  p {
    font-family: 'Aller-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 25px;
    color: var(--dark03);
  }

  button {
    background: #fbfbfb;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    color: #000;
    width: 152px;
    height: 48px;
    margin: 35px 0 0 45px;
    border: none;
    font-family: 'Aller-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 26px;
    color: var(--dark03);
  }

  @media (max-width: 720px) {
    h1 {
      font-size: 63px;
      line-height: 72px;
    }
    h2 {
      font-size: 22px;
      line-height: normal;
    }
    p {
      font-size: 16px;
      line-height: 8px;
    }
  }

  @media (max-width: 280px) {
    h1 {
      font-size: 42px;
      line-height: 42px;
    }
    h2 {
      font-size: 16px;
      line-height: normal;
    }
    p {
      font-size: 14px;
      line-height: 8px;
    }
  }
`;
