import { Container, Title, Description, Promotion } from './styles';

const Card = ({ plan }) => {
  function renderTitle() {
    if (plan.plan_type.name.toLowerCase().includes('anual')) {
      return <span>Anual</span>;
    } else {
      return <span>Mensal</span>;
    }
  }

  function renderPrice() {
    if (plan.plan_type.name.toLowerCase().includes('anual')) {
      return (
        <>
          <Description>
            <div>
              De <s>R$ 899,90 / Ano</s>
            </div>
          </Description>
          <Promotion>
            <div>Por apenas:</div>
            <div>R$ {plan.plan_type.price.replace('.', ',')} / Ano</div>
          </Promotion>
        </>
      );
    } else {
      return (
        <>
          <Description>
            <div>
              De <s>R$ 79,90 / Mês</s>
            </div>
          </Description>
          <Promotion>
            <div>Por apenas:</div>
            <div>R$ {plan.plan_type.price.replace('.', ',')} / Mês</div>
          </Promotion>
        </>
      );
    }
  }

  return (
    <Container>
      <Title>
        PLANO
        {renderTitle()}
      </Title>
      <Description>{renderPrice()}</Description>
    </Container>
  );
};

export default Card;
