import styled from 'styled-components';

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  /* height: 150px; */
  background: var(--dark03);
  border-radius: 20px 20px 0 0;
  font-family: 'Aller-Regular';
  padding: 25px;
  color: var(--purpleD1);
  text-transform: uppercase;
  white-space: nowrap;

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  cursor: pointer;

  img {
    width: 150px;
  }

  & div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    flex-wrap: wrap;
  }

  @media (max-width: 520px) {
    font-size: 14px;
    line-height: 16px;
    img {
      width: 100px;
    }
  }
`;
