import { useEffect, useState } from 'react';
import {
  BoxPlanSubscriptionData,
  BoxPlanSubscriptionDataContent,
  BoxPaymentMethod,
} from './styles';
import api from '../../../../services/api';
import { useSelector } from 'react-redux';
import { apiErrorMessage } from '../../../../utils/apiErrorMessage';
import { formatMoney } from '../../../../utils/formatMoney';
import { MPaymentStatus } from '../../../../mocks/MPaymentStatus';
import { MPlanStatus } from '../../../../mocks/MPlanStatus';
import { resolverStatusOrClass } from '../../../../utils/resolverStatusOrClass';
import { Popconfirm, Tooltip } from 'antd';
import Title from 'antd/lib/skeleton/Title';
import { calculateDifferenceOfDays } from '../../../../utils/calculateDifferenceOfDays';
import MyCards from '../MyCards';
import { CONTACT_EMAIL } from '../../../../settings';

const PlanSubscriptionData = ({
  title = 'Dados do Plano',
  subscription = null,
}) => {
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [canRequestCancellationOfThePlan, setCanRequestCancellationOfThePlan] =
    useState(false);
  const userLogged = useSelector((state) => state.User);

  useEffect(() => {
    if (!subscription) getUserDetails();
    else {
      const partialUserData = {
        ...userLogged,
        plan: subscription,
        invoices: subscription?.invoices || [],
      };
      setUserDetails(partialUserData);
      validatePlanCancellation(partialUserData);
    }
  }, []);

  // return locale data
  const getLocaleDate = (date) => {
    if (!date) return;
    return new Date(date).toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  const unsubscribe = async () => {
    try {
      setLoading(true);
      await api.patch(`plans/${userDetails.plan.subscription_id}/cancel`);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      apiErrorMessage(error, true);
    }
  };

  const validatePlanCancellation = (data) => {
    if (data?.plan?.status !== 'authorized') return;

    const recordMoreThanSevenDays =
      calculateDifferenceOfDays(data.plan.created_at) > 7 ? true : false;

    if (data.plan.plan_type.type === 'mensal')
      setCanRequestCancellationOfThePlan(true);
    else if (!recordMoreThanSevenDays) setCanRequestCancellationOfThePlan(true);
  };

  const getUserDetails = async () => {
    try {
      const { data } = await api.get(`users/${userLogged.id}?relations=true`);
      setUserDetails(data);
      validatePlanCancellation(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      apiErrorMessage(error, true);
    }
  };

  const RenderCancellationOfThePlan = () => {
    if (userDetails?.plan?.status !== 'authorized') return null;
    if (canRequestCancellationOfThePlan)
      return (
        <div>
          {userDetails.plan.plan_type.type === 'anual' && (
            <>
              <div>
                <h5>Você está nos seus primeiros 7 dias de teste!</h5>
                <p>
                  Neste período, você pode pedir cancelamento e reembolso do
                  plano. Após este período, o cancelamento não será mais
                  possível.
                </p>
              </div>
              <div>
                Entre em contato com o suporte através do e-mail abaixo e
                solicite o reembolso.
              </div>
              <span>
                <b>{CONTACT_EMAIL}</b>
              </span>
              {/* <Tooltip title="Cancelar o plano">
                <Popconfirm
                  title="Deseja de fato cancelar o plano?"
                  placement="top"
                  cancelText="Não"
                  okText="Sim, quero cancelar"
                  onConfirm={() => unsubscribe()}
                >
                  <span className="cursor">Quero cancelar o plano</span>
                </Popconfirm>
              </Tooltip> */}
            </>
          )}
        </div>
      );
    return null;
  };

  const RenderBoxPlanSubscriptionDataContent = () => {
    if (!userDetails?.plan?.plan_type) return null;
    return (
      <BoxPlanSubscriptionDataContent>
        <h1>{title}</h1>

        <div>
          <div className="informations">
            <div>
              <span className="bold">Status</span>
              <span
                className={resolverStatusOrClass(
                  MPlanStatus,
                  userDetails.plan.status,
                  true,
                )}
              >
                {resolverStatusOrClass(MPlanStatus, userDetails.plan.status)}
              </span>
            </div>
            <div>
              <span className="bold">Tipo</span>
              {userDetails.plan.plan_type.type}
            </div>
            <div>
              <span className="bold">Data da compra</span>
              {getLocaleDate(userDetails?.plan?.created_at)}
            </div>
            <div>
              <span className="bold">Data de expiração</span>
              {getLocaleDate(userDetails?.plan?.expiration_date)}
            </div>
            <div>
              <span className="bold">Valor</span>
              {formatMoney(userDetails?.plan?.plan_type?.price)}
            </div>
          </div>
        </div>
        <div>
          {userDetails.invoices.length > 0 ? (
            <>
              <h5>Histórico</h5>
              <table>
                <thead>
                  <tr>
                    <th className="bold">Data</th>
                    <th className="bold">Valor</th>
                    <th className="bold">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {userDetails.invoices.map((item, key) => {
                    return (
                      <tr key={key}>
                        <td>{getLocaleDate(item.created_at)}</td>
                        <td>{formatMoney(userDetails.plan.plan_type.price)}</td>

                        <td>
                          <span
                            className={resolverStatusOrClass(
                              MPaymentStatus,
                              item.status,
                              true,
                            )}
                          >
                            {resolverStatusOrClass(MPaymentStatus, item.status)}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          ) : (
            <>
              <p>Você não possui histórico de pagamento</p>
            </>
          )}
        </div>

        <RenderCancellationOfThePlan />
      </BoxPlanSubscriptionDataContent>
    );
  };

  const RederBoxPaymentMethod = () => {
    if (!userDetails?.plan) return null;
    return (
      <BoxPaymentMethod>
        <h1>Pagamento</h1>
        <MyCards />
      </BoxPaymentMethod>
    );
  };

  return (
    <BoxPlanSubscriptionData>
      <RenderBoxPlanSubscriptionDataContent />
      <RederBoxPaymentMethod />
    </BoxPlanSubscriptionData>
  );
};

export default PlanSubscriptionData;
