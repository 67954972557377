import styled from 'styled-components';

export const Container = styled.div`
  > div:nth-of-type(1) {
    font-family: 'Aller-Regular';
    font-size: 2rem;
    color: #2fb72f;
  }

  > div:nth-of-type(3) {
    font-family: 'Aller-Regular';
    font-size: 2rem;
    color: var(--dark03);
    cursor: pointer;

    > a > span {
      padding: 10px 0px;
      border-style: solid;
      border-top-width: 0px;
      border-right-width: 0px;
      border-bottom-width: 2px;
      border-left-width: 0px;
      border-color: #b3b1b1;
    }
  }

  @media (max-width: 1024px) {
    > div:nth-of-type(3) {
      text-align: center;
    }
  }
`;
