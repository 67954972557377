import { message } from 'antd';

const messages = new Map();

messages.set(
  '"user.cpf" is not allowed to be empty',
  'Por favor informe o CPF, verifique se o mesmo está cadastrado na tela:  Dados pessoais',
);

messages.set(
  '"user.birthday" must be a valid date',
  'Por favor informe a data de nascimento, verifique se a mesma está cadastrada na tela:  Dados pessoais',
);

messages.set('service unavailable', 'IUGU - Serviço Indisponível');

messages.set('subscription not found', 'IUGU - Plano não encontrado');

const mapMessage = (
  errorMessage = 'correu um erro inesperado, entre em contato com o suporte.',
) => {
  const lowerCaseErrorMessage = errorMessage.toLowerCase();
  const foundMessage = messages.get(lowerCaseErrorMessage);
  return foundMessage || errorMessage;
};

export function apiErrorMessage(error, showAlert = false) {
  const errorMessage = mapMessage(
    error &&
      error.response &&
      error.response.data &&
      error.response.data.message,
  );
  console.log('error: ', error);
  return showAlert ? message.error(errorMessage) : errorMessage;
}
