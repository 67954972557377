import checkedIcon from '../../assets/signup/checked.png';

import { Container } from './styles';

const Checkbox = ({ checked, setChecked = () => {} }) => {
  return (
    <Container checked={checked} onClick={() => setChecked(!checked)}>
      <img src={checkedIcon} alt="Ícone de checkbox assinalado" />
    </Container>
  );
};

export default Checkbox;
