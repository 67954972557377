import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import api from '../../services/api';
import cpfMask from '../../utils/cpfMask';
import removeFormatCpf from '../../utils/removeFormatCpf';

import Header from '../../components/Header';
import Content from '../../components/Content';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Footer from '../../components/Footer';
import LoadModal from '../../components/LoadModal';
import MessageModal from '../../components/MessageModal';

import backIcon from '../../assets/choosePlan/backIcon.png';

import {
  Container,
  GradientBackground,
  FreeTrialMessage,
  Row,
  Tooltip,
} from './styles';

const ChangePassword = () => {
  const location = useLocation();
  const { plan, operation, userPlan } = location.state;
  const id = useSelector((state) => state.User.id);
  const dispatch = useDispatch();
  const history = useHistory();

  const [formData, setFormData] = useState({
    cpf: '',
    cep: '',
    street: '',
    number: '',
    neighborhood: '',
    uf: '',
    city: '',
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [userHasCpf, setUserHasCpf] = useState(false);

  function renderTooltip() {
    if (errorMessage !== '') {
      return <Tooltip>{errorMessage}</Tooltip>;
    }
  }

  function validCPF(strCPF) {
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF == '00000000000') return false;

    for (let i = 1; i <= 9; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!userHasCpf && !validCPF(formData.cpf)) {
      setErrorMessage('Preencha o CPF corretamente');
      return;
    }

    if (
      !formData.cep.trim() ||
      !formData.street.trim() ||
      !formData.number.trim() ||
      !formData.neighborhood.trim() ||
      !formData.uf.trim() ||
      !formData.city.trim()
    ) {
      setErrorMessage('Preencha os campos corretamente');
      return;
    }

    setErrorMessage('');
    setShowLoadModal(true);

    if (!userHasCpf) {
      try {
        const response = await api.patch('/users/cpf', {
          cpf: formData.cpf,
        });
      } catch (error) {
        handleError(error);
        return;
      }
    }

    try {
      const response = await api.post('/addresses', {
        cep: formData.cep,
        street: formData.street,
        number: formData.number,
        neighborhood: formData.neighborhood,
        complement: formData.complement,
        uf: formData.uf,
        city: formData.city,
      });
    } catch (error) {
      handleError(error);
      return;
    }

    if (operation === 'signPlan') {
      signPlan();
    } else {
      changePaymentMethodToBankSlip();
    }
  };

  const changePaymentMethodToBankSlip = async () => {
    try {
      const response = await api.patch(`/plans/${userPlan.id}/payment-method`, {
        payable_with: 'bank_slip',
      });
      setShowLoadModal(false);

      setShowMessageModal(true);

      setTimeout(function () {
        setShowMessageModal(false);
        history.push('/');
      }, 8000);
    } catch (error) {
      handleError(error);
    }
  };

  const signPlan = async () => {
    try {
      setShowLoadModal(true);
      const response = await api.post('/plans', {
        plan_type_id: plan.id,
        payable_with: 'bank_slip',
      });
      setShowLoadModal(false);

      setShowMessageModal(true);

      setTimeout(function () {
        setShowMessageModal(false);
        history.push('/parabens');
      }, 8000);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = async (error) => {
    let message;
    if (error.response.data?.message) {
      message = error.response.data.message;
    } else {
      message = error.toString();
    }

    setShowLoadModal(false);
    setErrorMessage(message);
  };

  function renderMessageModal() {
    if (showMessageModal) {
      return (
        <MessageModal
          title="Dados confirmados com sucesso"
          description="Caso seja sua primeira assinatura, você receberá sua fatura em seu e-mail 2 dias antes dos 7 dias gratuitos. Se essa não for sua primeira assinatura, você receberá o boleto no seu e-mail em instantes." //Você receberá a sua fatura no seu e-mail.
          type="success"
          iconColor="green"
          setVisible={setShowMessageModal}
        />
      );
    }
  }

  async function checkIfUserHasCpf() {
    try {
      const response = await api.get(`/users/${id}`);
      if (response.data.cpf === null) {
        setUserHasCpf(false);
        return;
      }
      setUserHasCpf(true);
    } catch (error) {
      handleError(error);
    }
  }

  function renderCpfInput() {
    if (userHasCpf === false) {
      return (
        <Input
          placeholder="CPF (apenas números)"
          required
          value={cpfMask(formData.cpf)}
          onInput={(event) =>
            setFormData({
              ...formData,
              cpf: removeFormatCpf(event.target.value),
            })
          }
        />
      );
    }
  }

  useEffect(() => {
    checkIfUserHasCpf();
  }, []);

  return (
    <Container>
      <Header />
      <GradientBackground />
      <Content>
        <h1>Confirmar endereço</h1>
        <form onSubmit={(event) => handleSubmit(event)}>
          {renderCpfInput()}
          <Input
            type="number"
            placeholder="CEP (apenas números)"
            required
            value={formData.cep}
            onInput={(event) => {
              const cep = event.target.value;
              if (cep.toString().length <= 12) {
                setFormData({ ...formData, cep: event.target.value });
              }
            }}
          />
          <Input
            placeholder="Cidade"
            required
            onInput={(event) =>
              setFormData({ ...formData, city: event.target.value })
            }
          />
          <Input
            placeholder="UF, por exemplo: SP, RJ, AC etc."
            required
            onInput={(event) =>
              setFormData({ ...formData, uf: event.target.value })
            }
          />
          <Input
            placeholder="Bairro"
            required
            onInput={(event) =>
              setFormData({ ...formData, neighborhood: event.target.value })
            }
          />
          <Input
            placeholder="Rua"
            required
            onInput={(event) =>
              setFormData({ ...formData, street: event.target.value })
            }
          />
          <Input
            type="number"
            placeholder="Número da residência"
            required
            onInput={(event) =>
              setFormData({ ...formData, number: event.target.value })
            }
          />

          {renderTooltip()}
          <Button>Confirmar</Button>
        </form>
        <img
          onClick={() => history.goBack()}
          src={backIcon}
          alt="Ícone de voltar para a página anterior"
        />
      </Content>
      <Footer />
      <LoadModal show={showLoadModal} />
      {renderMessageModal()}
    </Container>
  );
};

export default ChangePassword;
