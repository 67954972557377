import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  animation: rendering 1s;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 50%;
  margin-top: 60px;

  > label {
    font-family: 'Aller-Regular';
    font-size: 1.5rem;
    color: #b3b1b1;
    margin-left: -310px;
    margin-bottom: 10px;
  }

  > div:nth-of-type(2) {
    margin-top: 4px;
  }

  span {
    font-family: 'Aller-Regular';
    font-size: 1.2rem;
    color: #b3b1b1;
  }

  span:nth-of-type(1) {
    cursor: pointer;
  }

  button {
    margin: 40px 0px;
    background-color: #a16ccd;
    outline: 0;
  }

  > div > span:nth-of-type(2) {
    color: #19b441;
    margin-left: 4px;
  }

  @media (max-width: 1080px) {
    width: 100%;

    > label {
      margin-left: 0px;
    }
  }

  @media (max-width: 560px) {
    > div {
      width: 100%;
    }

    > button {
      width: 100%;
    }
  }
`;

export const Logo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding: 20px;

  @media (max-width: 1080px) {
    display: none;
  }
`;

export const GradientBackground = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100px;
  background: rgb(97, 225, 113);
  background: linear-gradient(
    180deg,
    rgba(97, 225, 113, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  z-index: -1;
`;

export const Tooltip = styled.div`
  font-family: 'Aller-Regular';
  font-size: 1.2rem;
  margin-top: 20px;
  color: #f00;
  text-align: center;
`;

export const SunflowerStudent = styled.div`
  margin-bottom: 30px;
  span:nth-of-type(2) {
    font-family: 'Aller-Regular';
    cursor: pointer;
    color: #19b441;
    margin-left: 4px;
    font-size: 1.2rem;
  }
`;
