import styled from 'styled-components';

export const Container = styled.ul`
  position: absolute;
  left: -240px;
  top: 40px;
  width: 280px;
  z-index: 10;
  list-style: none;
  background-color: #fff;
  border-radius: 17px;
  box-shadow: 0px 0px 6px 0px lightgrey;
  padding: 0px 8px;
  transition: all 0.5s;
  opacity: ${(props) => (props.componentIsFocused ? 1 : 0)};
  pointer-events: ${(props) => (props.componentIsFocused ? 'auto' : 'none')};

  li:nth-child(4n + 1) {
    background-color: rgba(161, 108, 205, 0.1);
    svg {
      color: #a16ccd;
    }
  }

  li:nth-child(4n + 2) {
    background-color: rgba(47, 183, 47, 0.1);
    svg {
      color: #007d00;
    }
  }

  li:nth-child(4n + 3) {
    background-color: rgba(255, 252, 227, 1);
    svg {
      color: #d3b909;
    }
  }

  li:nth-child(4n + 4) {
    background-color: rgba(254, 233, 233, 1);
    svg {
      color: #ff9999;
    }
  }
`;

export const Notification = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: rgba(161, 108, 205, 0.1);
  border-radius: 10px;
  padding: 14px 8px;
  margin: 8px 0px;
  cursor: pointer;
`;

export const UserPhotoContainer = styled.div`
  position: relative;

  > img {
    width: 38px;
    height: 38px;
    border-radius: 50%;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  position: absolute;
  background-color: #fff;
  padding: 4px;
  width: fit-content;
  border-radius: 50%;
  bottom: -2px;
  right: -6px;
  box-shadow: 0px 0px 6px 0px lightgrey;
`;

export const Informations = styled.div`
  font-family: 'Aller-Regular';
  font-size: 1rem;
  color: #707070;
  margin-left: 8px;
  overflow-wrap: anywhere;

  > span {
    font-family: 'Aller-Bold';
  }
`;

export const Time = styled.div`
  font-family: 'Aller-Regular';
  color: #b3b1b1;
  font-size: 0.6rem;
  position: absolute;
  bottom: 4px;
  right: 20px;
`;
