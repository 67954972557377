import styled from 'styled-components';

export const Box = styled.div`
  padding: 10px;
  height: 100vh;
  display: grid;
  place-content: center;
  font-family: 'Aller-Regular';
  form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    h2 {
      font-size: 18px;
      font-weight: 500;
    }
    label {
      cursor: pointer;
      font-weight: 500;
    }
    input {
      width: 100%;
      background: var(--greyF5);
      box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.07);
      border-radius: 8px;
      outline: none;
      border: none;
      padding: 16px;
      ::placeholder {
        color: var(--grey92);
      }
    }
  }
`;
