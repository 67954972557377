const cepFormat = (postal) => {
  postal = postal.replace(/\D/g, '');
  if (postal.length > 8) {
    postal = postal.slice(0, -1);
  }
  postal = postal.replace(/^([0-9]{5})([0-9]{3})$/, '$1-$2');

  return postal.toString();
};

export default cepFormat;
