import styled from 'styled-components';

export const Container = styled.div`
  animation: rendering 1s;
  margin-top: 30px;

  @media (max-width: 1040px) {
    margin-left: -10px;
  }

  @media (max-width: 375px) {
    margin-left: -20px;
  }
`;

export const ShowMore = styled.p`
  font-family: 'Aller-Regular';
  font-size: 1.4rem;
  color: var(--dark03);
  margin-top: 20px;
  cursor: pointer;
`;
