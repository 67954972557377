import { useState } from 'react';

import { MAddressForm } from '../../mocks/MAddressForm';
import { consultZipCode } from '../../utils/consultZipCode';
import InputMask from 'react-input-mask';

const MegaAddressForm = ({ address = MAddressForm, setAddress = null }) => {
  const [zipCodeConsulted, setZipCodeConsulted] = useState(false);

  const onSetAddress = ({ target }) => {
    if (target.name === 'cep' && target.value.length === 9) {
      consultZipCode(target.value).then((response) => {
        setAddress({
          ...address,
          ...response,
        });
        setZipCodeConsulted(true);
      });
    } else {
      setAddress({ ...address, [target.name]: target.value });
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <label htmlFor="cep">CEP</label>
          <InputMask
            id="cep"
            required
            mask="99999-999"
            name="cep"
            placeholder="CEP"
            type="text"
            value={address.cep}
            maskChar=""
            onChange={onSetAddress}
          />
        </div>

        <div className="col-md-3">
          <label htmlFor="uf">UF</label>
          <input
            id="uf"
            required
            value={address.uf}
            name="uf"
            placeholder="Estado"
            type="text"
            onInput={onSetAddress}
            disabled={zipCodeConsulted}
          />
        </div>
        <div className="col-md-3">
          <label htmlFor="city">Cidade</label>
          <input
            id="city"
            required
            value={address.city}
            name="city"
            placeholder="Cidade"
            type="text"
            onInput={onSetAddress}
            disabled={zipCodeConsulted}
          />
        </div>

        <div className="col-md-5">
          <label htmlFor="street">Rua</label>
          <input
            id="street"
            required
            value={address.street}
            name="street"
            placeholder="Rua"
            type="text"
            onInput={onSetAddress}
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="neighborhood">Bairro</label>
          <input
            id="neighborhood"
            required
            value={address.neighborhood}
            name="neighborhood"
            placeholder="Bairro"
            type="text"
            onInput={onSetAddress}
          />
        </div>

        <div className="col-md-3">
          <label htmlFor="number">Número</label>
          <input
            id="number"
            required
            value={address.number}
            name="number"
            placeholder="Número"
            type="number"
            onInput={onSetAddress}
          />
        </div>

        <div className="col-md-12">
          <label htmlFor="complement">Complemento</label>
          <input
            id="complement"
            value={address.complement}
            name="complement"
            placeholder="Complemento"
            type="text"
            onInput={onSetAddress}
          />
        </div>
      </div>
    </>
  );
};

export default MegaAddressForm;
