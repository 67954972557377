import React, { useEffect } from 'react';
import { Container, Title, Description, Promotion } from './styles';

const Card = ({ data, setSelectedPlan = () => {}, selectedPlan = '' }) => {
  function renderTitle() {
    if (data.name === 'Plano Anual') {
      return 'Anual';
    }
    // return 'Mensal';
    return data.name;
  }

  function renderDescription() {
    if (data.name === 'Plano Anual') {
      return (
        <>
          {/* <Description>
            <div>
              De <s>R$ 899,90 / Ano</s>
            </div>
          </Description> */}
          <Promotion>
            {/* <div>Por apenas:</div> */}
            <div>R$ {data.price.toString().replace('.', ',')} / Ano</div>
          </Promotion>
        </>
      );
    }

    return (
      <>
        {/* <Description>
          <div>
            De <s>R$ 79,90 / Mês</s>
          </div>
        </Description> */}
        <Promotion>
          {/* <div>Por apenas:</div> */}
          <div>R$ {data.price.toString().replace('.', ',')} / Mês</div>
        </Promotion>
      </>
    );
  }

  const handleSelectedPlan = (id) => {
    localStorage.setItem('YogaCO@ChoosedPlan', id);
    setSelectedPlan(id);
  };

  return (
    <Container
      onClick={() => handleSelectedPlan(data.id)}
      selectedPlan={selectedPlan}
      id={data.id}
    >
      <Title type={data.name}>
        PLANO
        <span>{renderTitle()}</span>
      </Title>
      {renderDescription()}
    </Container>
  );
};

export default Card;
