import FormLogin from '../../components/FormLogin';
import { BoxLogin, BoxMain } from './styles';

const MegaLogin = () => {
  return (
    <BoxMain>
      <BoxLogin>
        <FormLogin />
      </BoxLogin>
    </BoxMain>
  );
};

export default MegaLogin;
