import styled from 'styled-components';

export const BoxInputOutline = styled.div`
  /* body {
  width: 500px;
  margin: auto;
  margin-top: 50px;
} */
  font-family: 'Aller-Regular';
  width: 100%;
  position: relative;
  /* margin-bottom: 45px; */

  /* .material {
    width: 200px;
    position: relative;
    margin-bottom: 45px;
  } */

  input {
    font-size: 18px;
    padding: 14px 20px 14px;
    display: block;
    width: calc(100% - 24px);
    border: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }

  input:focus {
    outline: none;
  }

  input:focus ~ label,
  input:valid ~ label {
    top: -7px;
    font-size: 12px;
    color: #000000;
    background: #fff;
    padding: 0px 5px;
  }

  input:focus ~ .borda {
    border: 2px solid #000000;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    top: 0px;
    pointer-events: none;
    -moz-transition: all 0.1s ease;
    -ms-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
    -webkit-transition: all 0.1s ease;
  }

  label {
    color: #000000;
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 32px;
    top: 12px;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;

    top: -7px;
    font-size: 12px;
    color: #000000;
    background: #fff;
    padding: 0px 5px;
  }

  .borda {
    border: 1px solid #cecece;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    pointer-events: none;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
  }

  .material-textarea {
    position: relative;
  }
  .material-textarea textarea {
    font-size: 18px;
    padding: 12px 12px 7px;
    display: block;
    width: calc(100% - 24px);
    border: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    overflow: auto;
    resize: none;
  }
  .material-textarea textarea:focus ~ label,
  .material-textarea textarea:valid ~ label {
    top: -7px;
    font-size: 13px;
    color: #5264ae;
    background: #fff;
    padding: 0px 5px;
  }
  .material-textarea textarea:hover ~ .borda {
    border: 1px solid;
  }
  .material-textarea textarea:focus ~ .borda,
  .material-textarea textarea:active ~ .borda,
  .material-textarea textarea:valid ~ .borda {
    border: 2px solid #5264ae;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    top: 0px;
    pointer-events: none;
    transition: 0.3s all ease;
  }
  .material-textarea textarea:focus {
    outline: none;
  }
  .material-textarea label {
    color: #999;
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 12px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  .material-textarea .borda {
    border: 1px solid #cecece;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    pointer-events: none;
    transition: 0.3s all ease;
  }
`;
