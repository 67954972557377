import { message } from 'antd';
import { useState } from 'react';

import ButtonLoading from '../../components/ButtonLoading';
import api from '../../services/api';
import { apiErrorMessage } from '../../utils/apiErrorMessage';
import { Box } from './styles';

const RecoverPassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState('');
  const onSetEmail = ({ target }) => {
    setEmail(target.value);
  };

  const requestNewPassword = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await api.post('/password/forgot', { email });
      setLoading(false);
      message.success(
        'Solicitação enviada com sucesso para o e-mail informado!',
      );
    } catch (error) {
      setLoading(false);
      apiErrorMessage(error, true);
      // message.error('Ocorreu um erro ao solicitar a recuperação de senha.');
    }
    setLoading(false);
  };

  return (
    <Box>
      <form onSubmit={requestNewPassword}>
        <h2>Solicitar e-mail de recuperação</h2>
        <div>
          <label htmlFor="email-registered">E-mail utilizado no cadastro</label>
          <input
            id="email-registered"
            value={email}
            onInput={onSetEmail}
            type="email"
            required
          />
        </div>
        <ButtonLoading
          loading={loading}
          type="submit"
          disabled={loading}
          label="Solicitar nova senha"
          width="211.86px"
        />
      </form>
    </Box>
  );
};

export default RecoverPassword;
