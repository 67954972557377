import styled from 'styled-components';

export const BoxCardTransactionFailure = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  .informations {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 26px;
  }
  h1,
  p {
    color: #030303;
    text-align: center;
    font-family: 'Aller-Regular';
    font-style: normal;
  }
  h1 {
    font-size: 30px;
    font-weight: 700;
    line-height: normal;
  }
  p {
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
  }

  button {
    padding: 16px;
    border-radius: 8px;
    background: #000;
    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.07);
    color: #fff;
  }
`;
